import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Col, Row, Button } from "reactstrap";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { FilterSection } from "./FilterSection";
import { MasterSheetTable } from "./MasterSheetTable";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { PropertySelector } from "./PropertySelector";
import axios from "../../api/axios";
import { getMastersheet } from "./TenantForm/Helpers/api";

const MasterSheet = () => {
    const { userData } = JSON.parse(window.localStorage.getItem("authUser") || '{"userData": {}}');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [masterSheetData, setMasterSheetData] = useState({items:[]});
    const [filterData, setFilterData] = useState({
        tenant: "",
        tenantEmail: "",
        suite: "",
        leaseStart: "",
        leaseEnd: "",
        minRent: "",
        maxRent: "",
        property_name: "",
        property_id: "",
        building_name: ""
    });

    const getMasterSheet = async () => {
        if (!filterData.property_id) return;
        const {data} = await getMastersheet(filterData)
        setMasterSheetData(data);
        Swal.fire("Success", `Master Sheet for ${filterData?.property_name} fetched successfully!`, "success");
    };

    useEffect(() => {
        getMasterSheet();
    }, [filterData]);

    const handleMasterSheetAction = async () => {
        try {
            const result = await Swal.fire({
                title: "Choose an Action",
                text: `Do you want to import a file to the ${filterData.propertyName} Master Sheet or download the Master Sheet?`,
                icon: "question",
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: "Download Master Sheet",
                denyButtonText: "Import File",
                cancelButtonText: "Cancel",
            });

            if (result.isConfirmed) {
                const downloadConfirm = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to download the Master Sheet Excel file?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, download!",
                    cancelButtonText: "No, cancel",
                });

                if (downloadConfirm.isConfirmed) {
                    const response = await axios.post(
                        "/master-sheet/generate-excel",
                        filterData,
                        { responseType: "blob" }
                    );

                    const file = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    saveAs(file, `MasterSheet.xlsx`);
                    Swal.fire("Success!", "The Excel file has been downloaded.", "success");
                }
            } else if (result.isDenied) {
                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.accept = ".xlsx, .xls";
                fileInput.style.display = "none";

                fileInput.addEventListener("change", async (event) => {
                    const file = event.target.files[0];
                    if (file) {
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("property_id", filterData.property_id || "");
                        formData.append("building_name", filterData.building_name || "");

                        try {
                            await axios.post("/master-sheet/import-excel", formData, {
                                headers: { "Content-Type": "multipart/form-data" },
                            });
                            Swal.fire("Success!", "The file has been imported successfully.", "success");
                            getMasterSheet();
                        } catch (error) {
                            Swal.fire("Error", "An error occurred while importing the file.", "error");
                        }
                    }
                });

                document.body.appendChild(fileInput);
                fileInput.click();
                document.body.removeChild(fileInput);
            }
        } catch (error) {
            Swal.fire("Error", "An unexpected error occurred.", "error");
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Home" breadcrumbItem="Master Sheet" />
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <PropertySelector
                                    isFilterOpen={isFilterOpen}
                                    setIsFilterOpen={setIsFilterOpen}
                                    action={handleMasterSheetAction}
                                    userData={userData}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                />
                                <FilterSection
                                    isFilterOpen={isFilterOpen}
                                    filterData={filterData}
                                    handleFilterChange={handleFilterChange}
                                />
                            </CardHeader>
                            <CardBody>
                                <MasterSheetTable config={masterSheetData || {items:[]}} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export { MasterSheet };
