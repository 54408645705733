import axios from "../../../api/axios";
import { api } from "./constants";

const { application_submit_url: APPLICANT_SUBMIT_URL, upload_documenmts_url: UPLOAD_DOCUMENTS_URL } = api.endpoints;

const createApplication = async (values) => {
    const body = {
        main: {
            city: values.main.city,
            property: values.main.property,
            propertyID: values.main.propertyID,
            date: values.main.date,
            layout: values.main.layout,
            layoutID: values.main.layoutID,
            source: values.main.source,
        },
        applicants: values.applicants.map(applicant => ({
            ...applicant,
            previousAddressInformation: {
                previousAddress: applicant.previousAddressInformation.previousAddress,
                howLongAtPreviousAddress: applicant.previousAddressInformation.howLongAtPreviousAddress,
                previousLandloardName: applicant.previousAddressInformation.previousLandlordName,
                previousLandloardPhone: applicant.previousAddressInformation.previousLandlordPhone,
            },
            currentLandloard: applicant.currentLandlord,
            documents: [],
        })),
        residePersons: values.residePersons.map(resident => ({
            ...resident,
            name: resident.firstname + resident.firstname,
        })),
        emergencyContacts: values.emergencyContacts,
        signatures: values.signatures,
        companyDomain: values.companyDomain,
    };

    if (values.showPets) {
        body.petsInformation = values.petsInformation;
    }
    if (values.showPets) {
        body.petsInformation = {
            numberOfPets: values.petsInformation.length,
            petsInformation: values.petsInformation.map(pet => ({
                petType: pet.pets,
                breed: pet.breed,
                weight: pet.weight,
                age: pet.age,
            }))
        };
    }
    try {
        const { data } = await axios.post(APPLICANT_SUBMIT_URL, body);
        return data.id;
    } catch (error) {
        console.error("Error creating application:", error);
        throw error;
    }
};

const uploadDocuments = async (ticketID, applicantIndex, documents) => {
    if (!documents || documents.length === 0) {
        console.warn(`No documents to upload for applicant ${applicantIndex + 1}`);
        return;
    }

    const formData = new FormData();
    
    // Append files to FormData
    documents.forEach((doc) => {
        const fileToUpload = doc.file || doc; // Handle different structures
        formData.append("file", fileToUpload);
    });

    const headers = {
        "content-type": "multipart/form-data",
        id: ticketID,
    };

    if (applicantIndex === 0) headers.applicant1 = true;
    if (applicantIndex === 1) headers.applicant2 = true;

    try {
        const response = await axios.post(UPLOAD_DOCUMENTS_URL, formData, { headers });
        console.log(`Documents uploaded for applicant ${applicantIndex + 1}:`, response.data);
        return response.data;
    } catch (error) {
        console.error(`Error uploading documents for applicant ${applicantIndex + 1}:`, error);
        throw error;
    }
};

const uploadImages = async (applicants, ticketID) => {
    if (!applicants || applicants.length === 0) {
        console.warn("No applicants found.");
        return;
    }
    const uploadPromises = applicants.map((applicant, index) =>
        uploadDocuments(ticketID, index, applicant.documents || [])
    );

    return Promise.all(uploadPromises);
};


const onSubmit = async (values) => {
    try {
        const ticket_id = await createApplication(values);
        if (!ticket_id) throw new Error("Failed to create application");

        const response = await uploadImages(values.applicants, ticket_id);
        return response;
    } catch (error) {
        console.error("Error in onSubmit:", error);
        return { success: false, message: error.message || "Something went wrong" };
    }
};

export { onSubmit };
