import React, { useEffect } from "react";
import { Form, Row, Col, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import { getPropertyList } from "../Helpers/api";

const PropertySelector = ({
  action,
  userData,
  filterData,
  setFilterData,
  setIsCreatingNew
}) => {
  useEffect(() => {
    if (userData?.id) {
      getPropertyList(userData).then((filteredData) => {
        if (filteredData) {
          setFilterData((prev) => ({ ...prev, ...filteredData }));
        }
      });
    }
  }, [userData?.id]);

  const formatProperty = (item) => ({
    id: item._id,
    value: item.title,
    label: `${item.title}, ${item.location}`,
    building: item.building || [],
  });
  const formatProperties = () => (filterData.propertyList || []).map(formatProperty);
  const formatBuildings = () => filterData?.building_list?.map((b) => ({ value: b.buildingName, label: b.buildingName })) || [];

  const handlePropertySelectionChange = (property) => {
    setFilterData((prev) => ({
      ...prev,
      property_id: property?.id || "",
      property_name: property?.value || "",
      building_list: property?.building || [],
      building_name: ""
    }));
    setIsCreatingNew(false);
  };

  const handleBuildingSelectionChange = (building) => {
    setFilterData((prev) => ({
      ...prev,
      building_name: building?.value || ""
    }));
  };

  return (
    <Form>
      <Row>
        <Col md={5}>
          <Label>Property Name</Label>
          <Select
            value={filterData.property_id ? { value: filterData.property_name, label: filterData.property_name } : null}
            options={formatProperties()}
            placeholder="Search By Name"
            onChange={handlePropertySelectionChange}
            className="property-select"
          />
        </Col>
        {filterData.building_list?.length > 0 && (
          <Col md={4}>
            <FormGroup>
              <Label>Building Name</Label>
              <Select
                value={filterData.building_name ? { value: filterData.building_name, label: filterData.building_name } : null}
                options={formatBuildings()}
                placeholder="Search By Building Name"
                onChange={handleBuildingSelectionChange}
                className="building-select"
              />
            </FormGroup>
          </Col>
        )}
        <Col md={3} className="d-flex align-items-end">
          <Button
            color={
              !filterData.property_id ||
                (filterData.building_list.length > 0 && !filterData.building_name)
                ? "secondary"
                : "primary"
            }
            className="ms-2"
            onClick={action}
            disabled={
              !filterData.property_id ||
              (filterData.building_list.length > 0 && !filterData.building_name)
            }
          >
            Download Excel Setup Template
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export { PropertySelector };
