import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MetaTags from "react-meta-tags";
import jwt_decode from 'jwt-decode';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Label,
    Form,
    Input,
    Button,
} from "reactstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from '../api/axios';
import { isLoading } from '../../store/actions';
import { SlotAllocationForm } from './Components/ModalForm/SlotAllocation';
import { MacrosModal } from './Components/ModalForm/MacrosTable';
import { saveAs } from "file-saver";
import Swal from 'sweetalert2';

const AssignParking = () => {
    const [selectedProperty, setSelectedProperty] = useState({ _id: "", title: "" });
    const [filterData, setFilterData] = useState({ building: '', parking_type: "", floor: "" });
    const [filters, setFilters] = useState({ buildings: [], parking_types: [], floors: [] });
    const [activeLayout, setActiveLayout] = useState();
    const [properties, setProperties] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [modalState, setModalState] = useState({ modal: false, slot_no: '' });
    const [macrosState, setMacrosState] = useState({ modal: false, macros: null });
    const [hoveredRow, setHoveredRow] = useState(null);

    const dispatch = useDispatch();

    const getUserFromToken = () => {
        const token = window.localStorage.getItem('accessToken');
        if (!token) return null;

        try {
            return jwt_decode(token);
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    const fetchLayouts = async () => {
        dispatch(isLoading(true));
        try {
            const user = getUserFromToken();
            if (!user) return;

            const { building, parking_type, floor } = filterData;
            const params = {
                building_name: building,
                parking_type,
                floor,
                role: user?.role,
                property: selectedProperty?.title,
                company_domain: user?.domain ? user?.domain : user?.managerOf,
                manager_id: user?.id
            };
            const response = await axios.get('/parking_new/parking-layout/layouts', { params });
            setProperties(response?.data?.property_titles || []);
            setFilters({
                buildings: response?.data?.filters?.building_names || [],
                parking_types: response?.data?.filters?.parking_types || [],
                floors: response?.data?.filters?.floors || [],
            });
            setActiveLayout(response?.data?.tableData)
            setMacrosState({ modal: false, macros: response?.data?.macros })
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(isLoading(false));
        }
    };

    const downloadExcel = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to download the Parking Slots Excel file?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, download!',
                cancelButtonText: 'No, cancel',
            });

            if (result.isConfirmed) {
                const response = await axios.post(
                    '/parking_new/parking-layout/generate-excel',
                    {
                        property_id: selectedProperty?._id,
                        building_name: filterData?.building ? filterData?.building : null,
                        parking_type: filterData.parking_type,
                        floor_no: filterData.floor,
                    },
                    { responseType: 'blob' }
                );

                const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(file, `ParkingSlots-${selectedProperty?.title}.xlsx`);
                Swal.fire('Success!', 'The Excel file has been downloaded.', 'success');
            } else {
                Swal.fire('Cancelled', 'The download was cancelled.', 'info');
            }
        } catch (error) {
            console.error('Error downloading Excel file', error);
            Swal.fire('Error', 'An error occurred while downloading the file.', 'error');
        }
    };

    useEffect(() => {
        fetchLayouts();
    }, [filterData, selectedProperty, modalState.modal]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        setFilterData((prev) => {
            const updatedFilters = { ...prev, [name]: value };
            if (name === "building") {
                updatedFilters.parking_type = "";
                updatedFilters.floor = "";
            } else if (name === "parking_type") {
                updatedFilters.floor = "";
            }
            return updatedFilters;
        });
    };

    const handlePropertyChange = (propertyId) => {
        const property = properties.find((prop) => prop._id === propertyId);
        if (property) {
            setSelectedProperty(property);
            setFilterData({ building: '', parking_type: "", floor: "" });
        }
    };

    const closeModal = () => {
        setModalState((prevState) => ({
            ...prevState,
            modal: false,
        }));
    };

    const toggleMacros = () => {
        setMacrosState((prevState) => ({
            ...prevState,
            modal: !macrosState.modal,
        }));
    };

    const FilterSection = () => (
        <Collapse isOpen={isFilterOpen}>
            <Card>
                <CardBody>
                    <Form>
                        <Row>
                            <Col md={2}>
                                <Label>Property</Label>
                                <Input
                                    type="select"
                                    value={selectedProperty?._id}
                                    onChange={(e) => handlePropertyChange(e.target.value)}
                                >
                                    <option value="">Select a Property</option>
                                    {properties?.length > 0 ? (
                                        properties.map((property) => (
                                            <option
                                                key={property?._id}
                                                value={property?._id}
                                            >
                                                {property?.title}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No Properties Available</option>
                                    )}
                                </Input>
                            </Col>
                            {filters.buildings.length > 0 && (
                                <Col md={2}>
                                    <Label>Building Name</Label>
                                    <Input
                                        type="select"
                                        name="building"
                                        value={filterData.building}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select a Building</option>
                                        {filters.buildings.map((building) => (
                                            <option key={building} value={building}>
                                                {building}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                            )}
                            <Col md={2}>
                                <Label>Parking Type</Label>
                                <Input
                                    type="select"
                                    name="parking_type"
                                    value={filterData.parking_type}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Select One</option>
                                    {filters.parking_types.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                            <Col md={2}>
                                <Label>Floor</Label>
                                <Input
                                    type="select"
                                    name="floor"
                                    value={filterData.floor}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Select One</option>
                                    {filters.floors.map((floor) => (
                                        <option key={floor} value={floor}>
                                            {floor}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                            <Col md={4}>
                                <Row className="mb-2 mt-4">
                                    <Col className="pe-1 col-auto">
                                        <button
                                            className={`btn btn-${isFilterOpen ? 'secondary' : 'primary'} w-100`}
                                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                                        >
                                            {isFilterOpen ? 'Collapse Filter' : 'Expand Filter'}
                                        </button>
                                    </Col>
                                    {activeLayout && <Col className="ps-1 col-auto">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            onClick={() => toggleMacros()}
                                        >
                                            Check Macros
                                        </Button>
                                    </Col>}
                                    {activeLayout && <Col className="ps-1 col-auto">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            onClick={() => downloadExcel()}
                                        >
                                            Download Excel
                                        </Button>
                                    </Col>}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </Collapse>
    );

    const ParkingTable = ({ config = { slots: [] } }) => {
        const {
            slots = [],
        } = config;

        const { parking_type, floor } = filterData

        if (slots.length <= 0) {
            return (
                <div className="table-responsive">
                    <Table className="table-striped table-bordered mb-0">
                        <tbody>
                            <tr>
                                <td colSpan="3" className="text-center">
                                    No parking data available - Please make sure to select a property layout.
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            );
        }

        const renderTooltip = (slot) => (
            <Tooltip id={`tooltip-${slot?.slot_no}`} style={{ zIndex: 9999 }}>
                {slot?.status === 'Occupied'
                    ? `Slot: ${slot.slot_no}, Status: ${slot.status}. Click to edit tenant details.`
                    : `Slot: ${slot.slot_no}, Status: ${slot.status}. Click to assign this slot a suite.`}
            </Tooltip>

        );

        const handleRowClick = (slot) => {
            setModalState({ modal: true, slot_no: slot?.slot_no });
        };

        return (
            <div className="table-responsive">
                <Table className="table-bordered mb-0">
                    <thead>
                        <tr>
                            <th className="item-1-th">
                                Parking #
                            </th>
                            <th className="item-2-th  suite-header">Suite #</th>
                            <th className="item-4-th">Plate #(s)</th>
                            <th
                                className={`item-3-th`}
                            >
                                Notes
                            </th>
                            <th className="item-5-th">Type</th>
                            <th className='item-6-th'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slots.length > 0 ? (
                            slots.map((slot, index) => (
                                <OverlayTrigger
                                    key={index}
                                    placement="left"
                                    overlay={renderTooltip(slot)}
                                >
                                    <tr
                                        className={`slot-row ${hoveredRow === index ? 'hovered-item' : ''}`}
                                        onClick={() => handleRowClick(slot)}
                                        onMouseEnter={() => setHoveredRow(index)} // Set hovered row on mouse enter
                                        onMouseLeave={() => setHoveredRow(null)} // Remove hovered row on mouse leave
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <td className={`item-1 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot?.slot_no}
                                        </td>
                                        <td className={`item-2 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot?.suite_no || 'N/A'}</td>
                                        <td className={`item-4 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot?.vehicle_no || 'N/A'}
                                        </td>
                                        <td className={`item-3 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot?.notes || 'N/A'}</td>
                                        <td className={`item-5 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot.type || 'N/A'}</td>
                                        <td className={`item-6 ${hoveredRow === index ? 'hovered-item' : ''} ${slot?.status === 'Occupied' ? 'slot-occupied' : 'slot-empty'}`}>
                                            {slot.status || 'N/A'}</td>
                                    </tr>
                                </OverlayTrigger>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No data available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div >
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{`Assign Parking | ${selectedProperty.title || "Select Property"}`}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Assign Parking" />
                    <Row>
                        {!isFilterOpen && <Row className="mb-2 mt-4">
                            <Col xs="2" className="pe-1">
                                <button
                                    className={`btn btn-primary`}
                                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                                >
                                    Expand Filter
                                </button>
                            </Col>
                        </Row>}
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <FilterSection />
                                </CardHeader>
                                <CardBody>
                                    <ParkingTable config={activeLayout} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <SlotAllocationForm isOpen={modalState?.modal} toggle={closeModal} property={selectedProperty?._id} layout_id={activeLayout?.activeLayout?._id} slot_no={modalState?.slot_no} />
            <MacrosModal isOpen={macrosState?.modal} macros={macrosState?.macros} toggle={toggleMacros} />
        </React.Fragment>
    );
};

export { AssignParking };



