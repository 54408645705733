import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import axios from "../../api/axios";
import {
  FormGroup,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import { LogoHandler } from "./Helpers/LogoHandler";
import { DocumentsHandler } from "./Helpers/DocumentsHandler";

const GET_LAYOUT_URL = "/layout/all";
const GET_PROPERTY_URL = "/property/get-property";
const EDIT_PROPERTY_URL = "/property/edit-property/";
const UPLOAD_FILES_URL = "/property/upload-files";

const EditProperty = ({ propertyId, onSuccess }) => {
  const [layouts, setLayouts] = useState([]);
  const token = localStorage.getItem("accessToken") || "";
  const decode = token ? jwt_decode(token) : null;

  useEffect(() => {
    const getLayout = async () => {
      if (decode?.role === "company") {
        try {
          const response = await axios.post(GET_LAYOUT_URL, {
            companyID: decode.id,
          });
          const layoutOptions = response.data.layouts.map((layout) => ({
            label: layout.layoutName,
            value: layout._id,
          }));
          setLayouts(layoutOptions);
        } catch (error) {
          console.error("Error fetching layouts:", error);
        }
      }
    };

    const fetchProperty = async () => {
      try {
        const response = await axios.get(`${GET_PROPERTY_URL}/${propertyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.data) {
          const propertyData = response.data.data;
          console.log(propertyData)
          formik.setValues({
            ...formik.values,
            _id: propertyData._id,
            primary_id: propertyData.primaryID,
            title: propertyData.title || "",
            landlord: propertyData.landlord || "",
            layouts: propertyData.layouts || [],
            partnership_label: propertyData.partnership_label || "",
            logo: propertyData.logo || "",
            province: propertyData.province || "",
            city: propertyData.city || "",
            postal_code: propertyData.postal_code || "",
            address: propertyData.address || "",
            status: propertyData.status,
            incentives: propertyData.incentives || ["Telus (Basic Cable & Internet 500)"],
            currency: propertyData.currency || "",
            buildings: propertyData.buildings.length
              ? propertyData.buildings
              : [
                {
                  name: "",
                  utilities_available: {
                    heat: "",
                    water: "",
                    electricity: "",
                  },
                },
              ],
            document: propertyData.document || [],
          });
        }
      } catch (error) {
        console.error("Error fetching property:", error);
      }
    };

    getLayout();
    if (propertyId) {
      fetchProperty();
    }
  }, [decode?.role, decode?.id, propertyId]);

  const formik = useFormik({
    initialValues: {
      _id: "",
      primary_id: "",
      company_id: decode?.id || "",
      title: "",
      landlord: "",
      layouts: [],
      partnership_label: "",
      city: "",
      province: "",
      postal_code: "",
      status: true,
      address: "",
      currency: "",
      incentives: ["Telus (Basic Cable & Internet 500)"],
      buildings: [
        {
          name: "",
          utilities_available: {
            heat: "",
            water: "",
            electricity: "",
          },
        },
      ],
      logo: "",
      document: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Property Name is required!"),
      landlord: Yup.string().required("Landlord is required!"),
      layouts: Yup.array().min(1, "Please select a Layout!"),
      city: Yup.string()
        .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed!")
        .required("City is required!"),
      province: Yup.string().required("Province is required!"),
      postal_code: Yup.string()
        .matches(/^[a-zA-Z0-9 ]+$/, "Only letters and numbers are allowed!")
        .required("Postal code is required!"),
      address: Yup.string().required("Address is required!"),
      currency: Yup.string().required("Currency is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();

        // Function to append nested objects as JSON strings
        const appendNested = (key, value) => {
          formData.append(key, JSON.stringify(value));
        };

        // Append normal form fields (excluding files)
        Object.keys(values).forEach((key) => {
          if (key !== "logo" && key !== "document") {
            if (typeof values[key] === "object" && values[key] !== null) {
              appendNested(key, values[key]); // Convert nested objects to JSON
            } else {
              formData.append(key, values[key]);
            }
          }
        });

        // If updating an existing property, send an update request first
        let propertyId = values._id;
        if (propertyId) {
          const updateResponse = await axios.post(EDIT_PROPERTY_URL, values, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (updateResponse.status !== 200) {
            throw new Error(updateResponse.data.message || "Failed to update property.");
          }
        } else {
          // Create a new property if no ID exists
          const createResponse = await axios.post(EDIT_PROPERTY_URL, values, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (createResponse.status !== 201) {
            throw new Error(createResponse.data.message || "Failed to create property.");
          }

          propertyId = createResponse.data.data._id; // Get newly created property ID
        }

        // Check if there are valid files before making the API call
        const hasValidFiles =
          (values.logo?.file instanceof File) ||
          (Array.isArray(values.document) && values.document.some(fileObj => fileObj.file instanceof File));

        if (hasValidFiles) {
          const fileFormData = new FormData();

          if (values.logo?.file instanceof File) {
            fileFormData.append("logo", values.logo.file);
          }

          if (Array.isArray(values.document)) {
            values.document.forEach((fileObj) => {
              if (fileObj.file instanceof File) {
                fileFormData.append("document", fileObj.file);
              }
            });
          }

          const uploadResponse = await axios.post(`${UPLOAD_FILES_URL}/${propertyId}`, fileFormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          if (uploadResponse.status !== 200) {
            throw new Error(uploadResponse.data.message || "File upload failed.");
          }
        }


        Swal.fire({
          icon: "success",
          title: "Property Updated!",
          text: "The property has been updated successfully.",
        }).then(() => {
          if (onSuccess) {
            onSuccess();
          }
          resetForm(); // Reset the form after successful update
        });
      } catch (error) {
        console.error("Error updating property:", error);

        Swal.fire({
          icon: "error",
          title: "Error!",
          text: error.response?.data?.message || "Something went wrong. Try again!",
        });
      }
    },

  });

  const addIncentive = () => {
    formik.setFieldValue("incentives", [...formik.values.incentives, ""]);
  };

  const removeIncentive = (index) => {
    const updatedIncentives = [...formik.values.incentives];
    updatedIncentives.splice(index, 1);
    formik.setFieldValue("incentives", updatedIncentives);
  };

  const handleBuildingChange = (index, field, value) => {
    const updatedBuildings = [...formik.values.buildings];
    updatedBuildings[index][field] = value;
    formik.setFieldValue("buildings", updatedBuildings);
  };

  const handleUtilityChange = (index, utility, value) => {
    const updatedBuildings = [...formik.values.buildings];
    updatedBuildings[index].utilities_available[utility] = value;
    formik.setFieldValue("buildings", updatedBuildings);
  };

  const addBuilding = () => {
    formik.setFieldValue("buildings", [
      ...formik.values.buildings,
      {
        name: "",
        utilities_available: {
          heat: false,
          water: false,
          electricity: false
        }
      },
    ]);
  };

  const removeBuilding = (index) => {
    const updatedBuildings = formik.values.buildings.filter((_, i) => i !== index);
    formik.setFieldValue("buildings", updatedBuildings);
  };

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-title-input">
              Property Name *
            </Label>
            <Input
              type="text"
              name="title"
              id="formrow-title-input"
              className="form-control"
              placeholder="Enter a Property Name"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.title && formik.errors.title ? true : false}
            />
            {formik.touched.title && formik.errors.title && (
              <Label style={{ color: "red" }}>{formik.errors.title}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-landlord-input">
              Landlord *
            </Label>
            <Input
              type="text"
              name="landlord"
              id="formrow-landlord-input"
              className="form-control"
              placeholder="Enter a Landlord"
              value={formik.values.landlord}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.landlord && formik.errors.landlord ? true : false}
            />
            {formik.touched.landlord && formik.errors.landlord && (
              <Label style={{ color: "red" }}>{formik.errors.landlord}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-layouts-input">
              Layouts *
            </Label>
            <Select
              isMulti
              name="layouts"
              id="formrow-layouts-input"
              options={layouts}
              value={formik.values.layouts.map(value => layouts.find(option => option.value === value))}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(option => option.value);
                formik.setFieldValue("layouts", selectedValues);
              }}
              onBlur={() => formik.setFieldTouched("layouts", true)}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {formik.touched.layouts && formik.errors.layouts && (
              <Label style={{ color: "red" }}>{formik.errors.layouts}</Label>
            )}
          </div>
        </div>


        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-partnership-input">
              Partnership Label
            </Label>
            <Input
              type="text"
              name="partnership_label"
              id="formrow-partnership-input"
              className="form-control"
              placeholder="Enter Partnership Label"
              value={formik.values.partnership_label}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-city-input">
              City *
            </Label>
            <Input
              type="text"
              name="city"
              id="formrow-city-input"
              className="form-control"
              placeholder="Enter a city name"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.city && formik.errors.city ? true : false}
            />
            {formik.touched.city && formik.errors.city && (
              <Label style={{ color: "red" }}>{formik.errors.city}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-province-input">
              Province *
            </Label>
            <Input
              type="text"
              name="province"
              id="formrow-province-input"
              className="form-control"
              placeholder="Enter province"
              value={formik.values.province}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.province && formik.errors.province ? true : false}
            />
            {formik.touched.province && formik.errors.province && (
              <Label style={{ color: "red" }}>{formik.errors.province}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-postal-input">
              Postal Code *
            </Label>
            <Input
              type="text"
              name="postal_code"
              id="formrow-postal-input"
              className="form-control"
              placeholder="Enter postal code"
              value={formik.values.postal_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.postal_code && formik.errors.postal_code ? true : false}
            />
            {formik.touched.postal_code && formik.errors.postal_code && (
              <Label style={{ color: "red" }}>{formik.errors.postal_code}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-status-input">
              Status *
            </Label>
            <Select
              name="status"
              id="formrow-status-input"
              value={statusOptions.find(option => option.value === formik.values.status) || null}
              onChange={(selectedOption) => {
                formik.setFieldValue("status", selectedOption ? selectedOption.value : "");
              }}
              options={statusOptions}
              isClearable
            />
            {formik.touched.status && formik.errors.status && (
              <Label style={{ color: "red" }}>{formik.errors.status}</Label>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-currency-input">
              Currency *
            </Label>
            <Input
              type="text"
              name="currency"
              id="formrow-currency-input"
              className="form-control"
              placeholder="Enter currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.currency && formik.errors.currency ? true : false}
            />
            {formik.touched.currency && formik.errors.currency && (
              <Label style={{ color: "red" }}>{formik.errors.currency}</Label>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            <Label className="form-label" htmlFor="formrow-address-input">
              Address *
            </Label>
            <Input
              type="textarea"
              name="address"
              id="formrow-address-input"
              className="form-control"
              placeholder="Enter your address"
              rows="3"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.address && formik.errors.address ? true : false}
            />
            {formik.touched.address && formik.errors.address && (
              <Label style={{ color: "red" }}>{formik.errors.address}</Label>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            {formik.values.buildings.map((building, index) => (
              <div key={index} className="row align-items-center mt-3 p-2 ">
                {/* Building Name */}
                <div className="col-md-4">
                  <Label className="fw-bold">Building Name</Label>
                  <Input
                    type="text"
                    value={building.name}
                    onChange={(e) => handleBuildingChange(index, "name", e.target.value)}
                    placeholder="Enter building name"
                  />
                </div>

                {/* Utilities (Checkboxes in one row) */}
                <div className="col-md-5">
                  <Label className="fw-bold me-3">Utilities:</Label>

                  <div className=" d-flex align-items-center">
                    <div className="form-check form-check-inline">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={building.utilities_available.heat}
                        onChange={(e) => handleUtilityChange(index, "heat", e.target.checked)}
                      />
                      <Label className="form-check-label">Heat</Label>
                    </div>

                    <div className="form-check form-check-inline">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={building.utilities_available.water}
                        onChange={(e) => handleUtilityChange(index, "water", e.target.checked)}
                      />
                      <Label className="form-check-label">Water</Label>
                    </div>

                    <div className="form-check form-check-inline">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={building.utilities_available.electricity}
                        onChange={(e) => handleUtilityChange(index, "electricity", e.target.checked)}
                      />
                      <Label className="form-check-label">Electricity</Label>
                    </div>
                  </div>
                </div>

                {/* Remove Button */}
                <div className="col-md-3 text-end">
                  <Button color="danger" onClick={() => removeBuilding(index)}>
                    Remove
                  </Button>
                </div>
              </div>
            ))}
            <Button
              type="button"
              color="light"
              onClick={addBuilding}
              style={{ marginTop: "10px" }}
            >
              <i className="bx bx-plus me-1"></i> Add Building
            </Button>
          </div>
        </div>

        <div className="col-md-12">
          <div className="mb-3">
            {formik.values.incentives.map((incentive, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                <Input
                  type="text"
                  name={`incentives[${index}]`}
                  className="form-control"
                  value={incentive}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter incentive"
                />
                <Button
                  type="button"
                  color="danger"
                  onClick={() => removeIncentive(index)}
                  style={{ marginLeft: "5px" }}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button
              type="button"
              color="light"
              onClick={addIncentive}
              style={{ marginTop: "10px" }}
            >
              <i className="bx bx-plus me-1"></i> Add Incentive Options
            </Button>
          </div>
        </div>

        <div className="row mt-4 mb-4">
          <div className="col-md-6">
            <DocumentsHandler
              key={`document`}
              name={`document`}
              label="Upload Property Documents *"
              formik={formik}
            />
          </div>
          <div className="col-md-6">
            <LogoHandler
              key={`logo`}
              name={`logo`}
              label="Upload Property Logo *"
              formik={formik}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end">
            <Button
              type="submit"
              color="success"
              className="btn-success save-user"
              disabled={Object.keys(formik.errors).length > 0}
            >
              Save
              <i id="nexticon" className="bx bx-chevron-right ms-1"></i>
              <div
                id="saveSpinner"
                style={{
                  display: "none",
                  height: "15px",
                  width: "15px",
                  marginLeft: "5px",
                }}
                className="spinner-border"
                role="status"
              ></div>
              <i
                id="failedSaveErrorIcon"
                style={{
                  display: "none",
                  marginLeft: "5px",
                }}
                className="fa fa-exclamation-triangle"
                aria-hidden="true"
              ></i>
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export { EditProperty };