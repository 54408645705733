import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Col, Row, Button, Alert } from "reactstrap";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { PropertySelector } from "./Components/PropertySelector";
import axios from "../../../api/axios";
import { getMasterSheetBasedOnPropertyId, getPropertyList } from "./Helpers/api";
import { FieldsForm } from "./Components/FieldsForm";

// Initial fields configuration extracted as a separate constant
const INITIAL_FIELDS = [
    {
        key: 'tenants',
        label: 'Tenants',
        type: 'array',
        required: true,
        editable: false,
        deletable: false,
        fields: [
            { key: 'firstName', label: 'First Name', type: 'text', required: true, editable: false, deletable: false },
            { key: 'lastName', label: 'Last Name', type: 'text', required: true, editable: false, deletable: false },
            { key: 'email', label: 'Email', type: 'email', required: true, editable: false, deletable: false },
            { key: 'phone', label: 'Country Code - Phone No.', type: 'text', required: true, editable: false, deletable: false },
        ],
    },
    {
        key: 'occupants',
        label: 'Occupants',
        type: 'array',
        required: false,
        editable: false,
        deletable: false,
        fields: [
            { key: 'firstName', label: 'First Name', type: 'text', required: false, editable: false, deletable: false },
            { key: 'lastName', label: 'Last Name', type: 'text', required: false, editable: false, deletable: false },
            { key: 'email', label: 'Email', type: 'email', required: false, editable: false, deletable: false },
            { key: 'phone', label: 'Country Code - Phone No.', type: 'text', required: false, editable: false, deletable: false },
        ],
    },
    { key: 'unitNumber', label: 'Unit No.', type: 'text', required: true, editable: false, deletable: false },
    { key: 'layout', label: 'Layout', type: 'select', required: true, editable: false, deletable: false },
    { key: 'totalArea', label: 'Total Area (Sqft)', type: 'number', required: true, editable: false, deletable: false },
    { key: 'targetRent', label: 'Target Rent', type: 'number', required: false, editable: false, deletable: false },
    { key: 'basicRent', label: 'Basic Rent', type: 'number', required: true, editable: false, deletable: false },
    {
        key: 'securityDeposit',
        label: 'Security Deposit',
        type: 'group',
        required: true,
        editable: false,
        deletable: false,
        fields: [
            { key: 'leaseDeposit', label: 'Lease Deposit', type: 'number', required: true, editable: false, deletable: false },
            { key: 'leaseRefundable', label: 'Lease Refundable', type: 'boolean', required: true, editable: false, deletable: false },
            { key: 'petDeposit', label: 'Pet Deposit', type: 'number', required: false, editable: false, deletable: false },
            { key: 'petRefundable', label: 'Pet Refundable', type: 'boolean', required: false, editable: false, deletable: false },
        ],
    },
    { key: 'leaseStart', label: 'Lease Start (D-M-Y)', type: 'date', required: true, editable: false, deletable: false },
    { key: 'leaseEnd', label: 'Lease End (D-M-Y)', type: 'date', required: true, editable: false, deletable: false },
    {
        key: 'insurance',
        label: 'Insurance',
        type: 'group',
        required: true,
        fields: [
            { key: 'policyNumber', label: 'Policy Number', type: 'text', required: true, editable: false, deletable: false },
            { key: 'company', label: 'Company', type: 'text', required: true, editable: false, deletable: false },
            { key: 'insuranceExpiry', label: 'Insurance Expiry (D-M-Y)', type: 'date', required: true, editable: false, deletable: false },
        ],
    },
    { key: 'petsAllowed', label: 'Pets Allowed', type: 'boolean', required: false },
    {
        key: 'pets',
        label: 'Pets',
        type: 'array',
        required: false,
        fields: [
            { key: 'category', label: 'Category', type: 'select', options: ['Dog', 'Cat', 'Other'] },
            { key: 'breed', label: 'Breed', type: 'text' },
            { key: 'weight', label: 'Weight (Kg)', type: 'number' },
            { key: 'age', label: 'Age (Months)', type: 'number' },
        ],
    },
    {
        key: 'parking',
        label: 'Parking',
        type: 'group',
        required: false,
        fields: [
            { key: 'category', label: 'Category', type: 'text' },
            { key: 'fee', label: 'Fee', type: 'number' },
        ],
    },
    {
        key: 'storage',
        label: 'Storage',
        type: 'group',
        required: false,
        fields: [
            { key: 'category', label: 'Category', type: 'text' },
            { key: 'fee', label: 'Fee', type: 'number' },
        ],
    },
    {
        key: 'accounts',
        label: 'Accounts',
        type: 'group',
        required: false,
        fields: [
            { key: 'heat', label: 'Heat Utility Account', type: 'text' },
            { key: 'water', label: 'Water Utility Account', type: 'text' },
            { key: 'electricity', label: 'Electricity Utility Account', type: 'text' },
        ],
    },
    { key: 'incentives', label: 'Incentives', type: 'text', placeholder: 'e.g., Telus (Basic Cable & Internet 500)' },
];

const MasterSheetFields = () => {
    // Get user data from localStorage
    const { userData = {} } = JSON.parse(localStorage.getItem("authUser") || '{"userData": {}}');

    // Component state
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [mastersheet, setMastersheet] = useState();
    const [filterData, setFilterData] = useState({
        property_id: "",
        property_name: "",
        building_name: "",
        building_list: []  // Added default empty array for building_list
    });

    // Computed property to validate selection
    const isValidSelection = !!filterData.property_id &&
        (filterData.building_list?.length === 0 || !!filterData.building_name);

    // Load property list when component mounts
    useEffect(() => {
        if (userData?.id) {
            getPropertyList(userData)
                .then((filteredData) => {
                    if (filteredData) {
                        setFilterData(prev => ({
                            ...prev,
                            ...filteredData,
                            property_id: ""
                        }));
                    }
                })
                .catch(error => console.error("Error fetching property list:", error));
        }
    }, [userData?.id]);

    // Load mastersheet when property or building changes
    useEffect(() => {
        if (filterData?.property_id) {
            getMasterSheetBasedOnPropertyId({
                property_id: filterData.property_id,
                building_name: filterData.building_name
            })
                .then(({ data }) => {
                    if (data) {
                        setMastersheet(data);
                        setIsCreatingNew(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching master sheet:", error);
                    setMastersheet(undefined);
                });
        }
    }, [filterData?.property_id, filterData?.building_name]);

    // Handle the master sheet import/export actions
    const handleMasterSheetAction = async () => {
        try {
            const result = await Swal.fire({
                title: "Download Master Sheet template",
                text: `Do you want to download the ${filterData.property_name} Master Sheet?`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Download",
                cancelButtonText: "Cancel",
            });

            if (result.isConfirmed) {
                await handleDownloadMasterSheet();
            }
        } catch (error) {
            Swal.fire("Error", "An unexpected error occurred.", "error");
            console.error("Master sheet download error:", error);
        }
    };

    const handleDownloadMasterSheet = async () => {
        try {
            const response = await axios.get("/v2/master_sheet/layout/template", {
                params: {
                    property_id: filterData?.property_id,
                    building_name: filterData?.building_name
                },
                responseType: "blob"
            });
            const file = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(file, `MasterSheet.xlsx`);
            Swal.fire("Success!", "The Excel file has been downloaded.", "success");
        } catch (error) {
            Swal.fire("Error", "An error occurred during download.", "error");
            console.error("Download error:", error);
        }
    }; 
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumb title="Home" breadcrumbItem="Master Sheet" />
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardHeader>
                                <PropertySelector
                                    action={handleMasterSheetAction}
                                    userData={userData}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    setIsCreatingNew={setIsCreatingNew}
                                />
                            </CardHeader>
                            <CardBody>
                                {!isValidSelection && (
                                    <Alert color="danger" className="mt-3 text-center">
                                        <strong>⚠️ Property Name and Building must be selected!</strong>
                                    </Alert>
                                )}

                                {isValidSelection && mastersheet === undefined && (
                                    <>
                                        {!isCreatingNew ? (
                                            <Alert color="warning" className="d-flex align-items-center justify-content-between p-3 border border-warning rounded">
                                                <div className="d-flex align-items-center">
                                                    <i className="bi bi-exclamation-triangle-fill text-warning me-2"></i>
                                                    <strong>⚠️ No Layout Available for this Property</strong>
                                                </div>
                                                <Button
                                                    color={isCreatingNew ? "secondary" : "primary"}
                                                    className="fw-bold px-4"
                                                    onClick={() => setIsCreatingNew(!isCreatingNew)}
                                                >
                                                    <i className="bi bi-plus-lg me-1"></i> Create New Layout
                                                </Button>
                                            </Alert>
                                        ) : (
                                            <FieldsForm
                                                property_id={filterData.property_id || ''}
                                                building_name={filterData.building_name || ''}
                                                initialFields={INITIAL_FIELDS}
                                                isCreatingNew={isCreatingNew}
                                            />
                                        )}
                                    </>
                                )}

                                {isValidSelection && mastersheet !== undefined && !isCreatingNew && (
                                    <FieldsForm
                                        property_id={filterData.property_id || ''}
                                        building_name={filterData.building_name || ''}
                                        initialFields={mastersheet.fields}
                                        isCreatingNew={isCreatingNew}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export { MasterSheetFields };