import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { addTenantInfo } from "./Helpers/api";
import { Formik, Form, Field, FieldArray } from 'formik';
import { 
  Box, 
  Button, 
  Card, 
  Divider, 
  Grid, 
  TextField, 
  Typography, 
  MenuItem,
  IconButton,
  Paper,
  Tab,
  Tabs,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PetsIcon from '@mui/icons-material/Pets';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';

const AddTenantForm = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addTenantInfo(formData);
    setFormData({ name: "", email: "", phone: "" });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Add Tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form onSubmit={handleClose}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={handleClose} className="me-2">
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Add Tenant
            </Button>
          </div>
        </Form> */}
        <TenantPropertyForm/>
      </Modal.Body>
    </Modal>
  );
};

export { AddTenantForm };

const TenantPropertyForm = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const petCategories = ["Dog", "Cat", "Bird", "Other"];
  const mailboxTypes = ["Module", "Compartment"];
  const styleOptions = ["A", "B", "C"];
  const viewOptions = ["W", "E"];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
          Tenant & Property Management
        </Typography>

        <Formik
          initialValues={{
            tenants: [{ first_name: "", last_name: "", email: "", phone_no: "" }],
            occupants: [{ first_name: "", last_name: "", email: "", phone_no: "" }],
            utility_accounts: { water: "", heat: "", electricity: "" },
            no_of_bedrooms: "",
            area_in_sqft: "",
            suite_no: "",
            security_deposit: "",
            target_rent: "",
            basic_rent: "",
            pets: [{ category: "", breed: "", weight: "", age: "" }],
            lease_start: null,
            lease_end: null,
            insurance_expiry_date: null,
            insurance_company_name: "",
            comments: "",
            documents: [],
            amenity_form: "",
            style: "",
            mailbox: "",
            ac: false,
            building_no: "",
            telus: "",
            cameras: "",
            golf_woods: "",
            operational_cost: "",
          }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Paper elevation={2} sx={{ mb: 4 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                  <Tab icon={<PersonIcon />} label="Tenants & Occupants" />
                  <Tab icon={<ApartmentIcon />} label="Property Details" />
                  <Tab icon={<ReceiptIcon />} label="Lease & Financial" />
                  <Tab icon={<PetsIcon />} label="Pets & Amenities" />
                  <Tab icon={<SettingsIcon />} label="Additional Settings" />
                </Tabs>

                {/* Tab 1: Tenants & Occupants */}
                {tabValue === 0 && (
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 3 }}>Primary Tenants</Typography>
                    <FieldArray name="tenants">
                      {({ push, remove }) => (
                        <>
                          {values.tenants.map((tenant, index) => (
                            <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="First Name"
                                    name={`tenants[${index}].first_name`}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Last Name"
                                    name={`tenants[${index}].last_name`}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Email"
                                    name={`tenants[${index}].email`}
                                    variant="outlined"
                                    type="email"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Phone Number"
                                    name={`tenants[${index}].phone_no`}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                              {values.tenants.length > 1 && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                  <IconButton 
                                    color="error" 
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </Card>
                          ))}
                          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                              startIcon={<AddIcon />}
                              variant="outlined"
                              onClick={() => push({ first_name: "", last_name: "", email: "", phone_no: "" })}
                            >
                              Add Tenant
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>

                    <Divider sx={{ my: 4 }} />

                    <Typography variant="h6" sx={{ mb: 3 }}>Additional Occupants</Typography>
                    <FieldArray name="occupants">
                      {({ push, remove }) => (
                        <>
                          {values.occupants.map((occupant, index) => (
                            <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="First Name"
                                    name={`occupants[${index}].first_name`}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Last Name"
                                    name={`occupants[${index}].last_name`}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Email"
                                    name={`occupants[${index}].email`}
                                    variant="outlined"
                                    type="email"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Phone Number"
                                    name={`occupants[${index}].phone_no`}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <IconButton 
                                  color="error" 
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Card>
                          ))}
                          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                              startIcon={<AddIcon />}
                              variant="outlined"
                              onClick={() => push({ first_name: "", last_name: "", email: "", phone_no: "" })}
                            >
                              Add Occupant
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                )}

                {/* Tab 2: Property Details */}
                {tabValue === 1 && (
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 3 }}>Property Information</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Unit Details</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Suite Number"
                                name="suite_no"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Building Number"
                                name="building_no"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Number of Bedrooms"
                                name="no_of_bedrooms"
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Area (sq ft)"
                                name="area_in_sqft"
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel>Style</InputLabel>
                                <Field
                                  as={Select}
                                  label="Style"
                                  name="style"
                                >
                                  {styleOptions.map(option => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.ac}
                                    onChange={(e) => setFieldValue('ac', e.target.checked)}
                                    name="ac"
                                    color="primary"
                                  />
                                }
                                label="Air Conditioning"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel>Mailbox Type</InputLabel>
                                <Field
                                  as={Select}
                                  label="Mailbox Type"
                                  name="mailbox"
                                >
                                  {mailboxTypes.map(type => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.end_unit === "true"}
                                    onChange={(e) => setFieldValue('end_unit', e.target.checked ? "true" : "false")}
                                    name="end_unit"
                                    color="primary"
                                  />
                                }
                                label="End Unit"
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Utility Accounts</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Water Account"
                                name="utility_accounts.water"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Heat Account"
                                name="utility_accounts.heat"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Electricity Account"
                                name="utility_accounts.electricity"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Additional Property Features</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Telus Package"
                                name="telus"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Cameras"
                                name="cameras"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Golf Woods"
                                name="golf_woods"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Operational Cost"
                                name="operational_cost"
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Amenity Form Information"
                                name="amenity_form"
                                variant="outlined"
                                multiline
                                rows={3}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Tab 3: Lease & Financial */}
                {tabValue === 2 && (
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 3 }}>Lease Information</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Lease Dates</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                label="Lease Start Date"
                                value={values.lease_start}
                                onChange={(date) => setFieldValue('lease_start', date)}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                label="Lease End Date"
                                value={values.lease_end}
                                onChange={(date) => setFieldValue('lease_end', date)}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Insurance Details</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Insurance Company"
                                name="insurance_company_name"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                label="Insurance Expiry Date"
                                value={values.insurance_expiry_date}
                                onChange={(date) => setFieldValue('insurance_expiry_date', date)}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Financial Information</Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Target Rent ($)"
                                name="target_rent"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  startAdornment: '$'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Basic Rent ($)"
                                name="basic_rent"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  startAdornment: '$'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Field
                                as={TextField}
                                fullWidth
                                label="Security Deposit ($)"
                                name="security_deposit"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  startAdornment: '$'
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      <Grid item xs={12}>
                        <Card variant="outlined" sx={{ p: 3 }}>
                          <Typography variant="subtitle1" gutterBottom>Documents</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <Button
                              variant="outlined"
                              component="label"
                              startIcon={<UploadFileIcon />}
                            >
                              Upload Document
                              <input
                                type="file"
                                hidden
                                onChange={(event) => {
                                  const newFiles = Array.from(event.currentTarget.files);
                                  setFieldValue('documents', [...values.documents, ...newFiles]);
                                }}
                                multiple
                              />
                            </Button>
                            <Typography variant="body2" sx={{ ml: 2 }}>
                              {values.documents.length} document(s) attached
                            </Typography>
                          </Box>
                          {values.documents.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                              {values.documents.map((doc, index) => (
                                <Box 
                                  key={index}
                                  sx={{
                                    display: 'flex', 
                                    justifyContent: 'space-between',
                                    p: 1,
                                    borderBottom: '1px solid #f0f0f0'
                                  }}
                                >
                                  <Typography variant="body2">
                                    {doc.name || `Document ${index + 1}`}
                                  </Typography>
                                  <IconButton 
                                    size="small"
                                    onClick={() => {
                                      const updatedDocs = [...values.documents];
                                      updatedDocs.splice(index, 1);
                                      setFieldValue('documents', updatedDocs);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* Tab 4: Pets & Amenities */}
                {tabValue === 3 && (
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 3 }}>Pet Information</Typography>
                    <FieldArray name="pets">
                      {({ push, remove }) => (
                        <>
                          {values.pets.map((pet, index) => (
                            <Card key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel>Pet Type</InputLabel>
                                    <Field
                                      as={Select}
                                      label="Pet Type"
                                      name={`pets[${index}].category`}
                                    >
                                      {petCategories.map(category => (
                                        <MenuItem key={category} value={category}>
                                          {category}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Breed"
                                    name={`pets[${index}].breed`}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Weight (lbs)"
                                    name={`pets[${index}].weight`}
                                    variant="outlined"
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    label="Age (years)"
                                    name={`pets[${index}].age`}
                                    variant="outlined"
                                    type="number"
                                  />
                                </Grid>
                              </Grid>
                              {values.pets.length > 1 && (
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                  <IconButton 
                                    color="error" 
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </Card>
                          ))}
                          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                              startIcon={<AddIcon />}
                              variant="outlined"
                              onClick={() => push({ category: "", breed: "", weight: "", age: "" })}
                            >
                              Add Pet
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                )}

                {/* Tab 5: Additional Settings */}
                {tabValue === 4 && (
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 3 }}>Additional Comments</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          label="Comments"
                          name="comments"
                          variant="outlined"
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Paper>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setTabValue(Math.max(0, tabValue - 1))}
                  disabled={tabValue === 0}
                >
                  Previous
                </Button>

                {tabValue < 4 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setTabValue(Math.min(4, tabValue + 1))}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </LocalizationProvider>
  );
};