import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Label, FormFeedback } from "reactstrap";
import axios from "../../../api/axios";

const slotSchema = Yup.object().shape({
    locker_no: Yup.string().required("Locker number is required"),
    suite_no: Yup.string().required("Suite number is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile_no: Yup.string().required("Mobile number is required"),
    amount: Yup.number()
        .positive("Amount must be positive")
        .required("Amount is required"),
    type: Yup.string()
        .oneOf(["Regular", "Visitor", "Handicap"], "Invalid type selected")
        .required("Type is required"),
    notes: Yup.string().required("Notes is required"),
});



const SlotAllocationForm = ({ isOpen, toggle, property, layout_id, locker_no }) => {
    const defaultValues = {
        locker_no,
        suite_no: "",
        name: "",
        email: "",
        mobile_no: "",
        amount: "",
        type: "",
        notes: "",
    };

    const [initialValues, setInitialValues] = useState(defaultValues);

    useEffect(() => {
        if (layout_id) {
            axios
                .get(`/storage_new/slots/slot-details`, { params: { layout_id, locker_no } }) // API call to fetch storage layouts
                .then((response) => {
                    const { data } = response;

                    if (data) {
                        setInitialValues(data);
                    } else {
                        setInitialValues(defaultValues);
                    }
                })

                .catch((error) => {
                    setInitialValues(defaultValues);
                });
        }
    }, [isOpen]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                layout_id,
                ...values,
            };
            await axios.post(`/storage_new/slots/add-or-update`, payload);
            toggle();
        } catch (error) {
            console.error("Error saving storage slot:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleRemoveSlot = async () => {
        try {
            const payload = {
                layout_id,
                locker_no,
            };
            await axios.delete(`/storage_new/slots/remove-slot`, { data: payload });
            toggle();  // Close the modal after deletion
        } catch (error) {
            console.error("Error removing storage slot:", error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
                {property?.title ? `Manage Storage Slot - ${property.title}` : "Manage Storage Slot"}
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={slotSchema}
                onSubmit={handleSubmit}

            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <ModalBody>
                            <div className="row">
                                {renderField("locker_no", "Locker Number", "text", errors, touched, [], 6, true)}
                                {renderField("suite_no", "Suite Number", "text", errors, touched, [], 6, false)}
                            </div>
                            <div className="row">
                                {renderField("name", "Name", "text", errors, touched, [], 6)}
                                {renderField("email", "Email", "email", errors, touched, [], 6)}
                            </div>
                            <div className="row">
                                {renderField("mobile_no", "Mobile Number", "text", errors, touched, [], 6)}
                                {renderField("amount", "Amount", "number", errors, touched, [], 6)}
                            </div>
                            <div className="row">
                                {renderField(
                                    "type",
                                    "Type",
                                    "select",
                                    errors,
                                    touched,
                                    [
                                        { value: "", label: "Select Type" },
                                        { value: "Regular", label: "Regular" },
                                        { value: "Visitor", label: "Visitor" },
                                        { value: "Handicap", label: "Handicap" },
                                    ],
                                    6
                                )}
                                {renderField("notes", "Notes", "textarea", errors, touched, [], 6)}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Saving..." : "Save Slot"}
                            </Button>
                            <Button color="danger" onClick={handleRemoveSlot} disabled={isSubmitting}>
                                {isSubmitting ? "Removing..." : "Delete Slot"}
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

const renderField = (name, label, type, errors, touched, options = [], colSize = 6, disabled = false) => {
    const error = name.split('.').reduce((obj, key) => obj?.[key], errors);
    const isTouched = name.split('.').reduce((obj, key) => obj?.[key], touched);

    return (
        <div className={`col-${colSize} mb-3`}>
            <Label htmlFor={name} className="form-label">
                {label}
            </Label>
            {type === "select" ? (
                <Field name={name}>
                    {({ field }) => (
                        <select
                            {...field}
                            className={`form-control ${error && isTouched ? 'is-invalid' : ''}`}
                            disabled={disabled}
                        >
                            {options.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}
                </Field>
            ) : (
                <Field name={name}>
                    {({ field }) => (
                        <input
                            {...field}
                            type={type}
                            className={`form-control ${error && isTouched ? 'is-invalid' : ''}`}
                            disabled={disabled}
                        />
                    )}
                </Field>
            )}
            {errors[name] && touched[name] && <FormFeedback className="d-block">{errors[name]}</FormFeedback>}
        </div>
    );
};

export { SlotAllocationForm };
