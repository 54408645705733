import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Label } from "reactstrap";
import { getIn } from "formik";

const AddressAutocomplete = ({ formik, index, label, field }) => {
  const fieldPath = `applicants.${index}.${field}`;
  const address = getIn(formik.values, fieldPath) || "";

  const handleChange = (value) => {
    formik.setFieldValue(fieldPath, value);
  };

  const handleSelect = (address) => {
    handleChange(address);
  };

  const error = getIn(formik.errors, fieldPath);
  const touched = getIn(formik.touched, fieldPath);

  const setPreviousLandlordFieldsAsNA = (index, isChecked) => {
    const value = isChecked ? "N/A" : "";
    const fields = ["previousAddress", "howLongAtPreviousAddress", "previousLandlordName", "previousLandlordPhone"];

    fields.forEach(field =>
      formik.setFieldValue(`applicants[${index}].previousAddressInformation.${field}`, value)
    );

    formik.setFieldValue(`applicants[${index}].previousAddressInformation.isNA`, isChecked);
  };

  return (
    <div className="col-lg-3">
      <Label className="form-label d-flex align-items-center justify-content-between w-100">
        <span>{label}</span>

        {label === "Previous address  *" && (
          <div className="d-flex align-items-center">
            <Label className="form-label mb-0 me-2">Not Applicable?</Label>
            <Input
              type="checkbox"
              id={`na-checkbox-${index}`}
              className="form-check-input"
              onChange={(e) => setPreviousLandlordFieldsAsNA(index, e.target.checked)}
            />
          </div>
        )}
      </Label>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{ componentRestrictions: { country: ["US", "CA"] } }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                className: "form-control",
                placeholder: "Search address...",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
};

export { AddressAutocomplete };
