import { useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { Col, Row } from "reactstrap";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { convertTimeRangeToUTC, initialValues, submitForm, validationSchema } from "./Helpers";
import favicon from "../../assets/favicon.ico";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const Calendar = () => {
  const { id } = useParams();
  const property_name = new URLSearchParams(useLocation().search).get("propertyName");

  const [managerInfo, setManagerInfo] = useState({
    logo: null,
    companyName: '',
    managerId: null,
    contact: '',
    role: '',
    companyDomain: ''
  });

  const [properties, setProperties] = useState([]);
  const [reasonType, setReasonType] = useState([]);
  const [daysAvailability, setDayAvailability] = useState([]);


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const [utcStartTime, utcEndTime] = convertTimeRangeToUTC(values?.slot, values?.appointment_date);
      console.log({ values });

      const payload = {
        // Manager info
        ...(managerInfo?.managerId ? { manager_id: managerInfo.managerId } : {}),
        ...(managerInfo?.role ? { role: managerInfo.role } : {}),
        ...(managerInfo?.companyDomain ? { companyDomain: managerInfo.companyDomain } : {}),

        // User info
        ...(values?.name ? { name: values.name } : {}),
        ...(values?.phone ? { phone: values.phone } : {}),
        ...(values?.other_phone ? { phone1: values.other_phone } : {}),
        ...(values?.email ? { email: values.email } : {}),

        // Appointment details
        ...(values?.day ? { day: values.day } : {}),
        ...(values?.appointment_date
          ? { date: values.appointment_date, Sdate: values.appointment_date }
          : {}),
        ...(utcStartTime?.utcString ? { StartTime: utcStartTime.utcString } : {}),
        ...(utcEndTime?.utcString ? { endTime: utcEndTime.utcString } : {}),
        ...(values?.reason ? { reasonId: values.reason } : {}),
        ...(values?.property ? { propertyId: values.property } : {}),
        ...(values?.comment ? { description: values.comment } : {}),
        ...(values?.layout ? { layoutId: values.layout } : {}),
        ...(values?.slot ? { timeSelected: values.slot } : {}),

        // Client timezone
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      console.log("Payload: ", payload);


      // Call submitForm with the payload, company domain and formik helpers
      await submitForm(
        payload,
        managerInfo.companyDomain,
        formikHelpers
      );
    },
  });

  const fetchManagerDetailsAndReasons = async (manager_id) => {
    try {
      // Validate input
      if (!manager_id) {
        setManagerInfo({
          logo: null,
          companyName: '',
          managerId: null,
          contact: '',
          role: '',
          companyDomain: ''
        });
        setProperties([]);
        setDayAvailability([]);
        setReasonType([]);
        return;
      }

      // Fetch manager details
      const managerResponse = await axios.get(`/user_appointment/get-id/?id=${manager_id}`);
      const managerData = managerResponse.data;

      // Update manager information
      setManagerInfo({
        logo: managerData?.manager?.company?.logo,
        companyName: managerData?.manager?.company?.name,
        managerId: managerData?.manager?._id,
        contact: managerData?.manager?.phone,
        role: managerData?.manager?.role,
        companyDomain: managerData?.manager?.companyAssigned
      });

      // Set properties
      setProperties(managerData?.manager.proprtyList);

      // Fetch reason types
      const reasonResponse = await axios.get(`/calender/reason/get?companyID=${managerData?.manager?.companyID}`);
      const reasonData = reasonResponse.data;

      // Set reason types if available
      if (reasonData.results.reasons) {
        setReasonType(reasonData.results.reasons);
      }

    } catch (error) {
      console.error('Error fetching manager details, availability, and reasons:', error);

      // Reset all states in case of error
      setManagerInfo({
        logo: null,
        companyName: '',
        managerId: null,
        contact: '',
        role: '',
        companyDomain: ''
      });
      setProperties([]);
      setDayAvailability([]);
      setReasonType([]);
    }
  };

  const fetchDateAndPropertyBasedAvailabilities = async (date) => {
    if (!filtered_property?._id || !managerInfo?.managerId) return; // Ensure required fields exist

    try {
      const { data } = await axios.post(`/calender/available-slots`, {
        date,
        propertyId: filtered_property?._id,
        managerId: managerInfo?.managerId,
      });
      console.log(data);
      setDayAvailability(data);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const filtered_property = properties.find(({ title }) => title === property_name) || null;
  const layout_list = properties.find(({ _id }) => _id === formik.values.property)?.categoryList || [];

  useEffect(() => {
    if (id) {
      fetchManagerDetailsAndReasons(id);
    }
  }, [id]);

  useEffect(() => {
    fetchDateAndPropertyBasedAvailabilities(formik.values?.appointment_date);
  }, [formik.values?.appointment_date, filtered_property?._id, managerInfo?.managerId]);

  useEffect(() => {
    if (filtered_property) {
      formik.setFieldValue("property", filtered_property._id);
    }
  }, [filtered_property]);

  const handleDateChange = (e) => {
    if (!e.target.value) return; // Prevent execution if value is empty

    const selectedDate = new Date(e.target.value);
    if (isNaN(selectedDate.getTime())) return; // Prevent execution if date is invalid

    const selectedDayIndex = selectedDate.getDay(); // 0 (Sunday) - 6 (Saturday)
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun",];
    const selectedDayName = dayNames[selectedDayIndex]; // Use the correct index

    const availableDays = daysAvailability?.available_days.map(day => day - 1) || [];

    if (!availableDays.includes(selectedDayIndex)) {
      const availableDayNames = availableDays.map(day => dayNames[day]).join(", ");
      Swal.fire({
        icon: "warning",
        title: "Selected Date is Unavailable",
        html: `<strong>Please choose a valid date.</strong><br><br>
               <b>Available Days:</b> ${availableDayNames || "None"}`,
        confirmButtonText: "OK",
      });
      formik.setFieldValue("appointment_date", "");
      formik.setFieldValue("day", "");
      return;
    }

    formik.setFieldValue("day", selectedDayName);
    formik.handleChange(e);
  };

  return (
    <>
      <MetaTags>
        <title> Rentdigicare | Book Appointment</title>
        <link rel="icon" href={favicon} />
      </MetaTags>
      <div className="appointmentForm-card">
        <div className="row" style={{ minHeight: "80vh", minWidth: "8vh" }}>
          <div className="col-md-6 mx-auto">
            <div
              id="bookAppointmentForm"
              className="calendarForm-form px-4 pt-3 pb-4 opacity-transparent bg-white"
            >
              <div className="logo-container">
                <div className="rdcarelogo-container">
                  <img
                    className="parteneredwithrdcare-logo"
                    alt="Partnered with RD Care"
                    src="/images/rdcarelogo.png"
                  />
                </div>
              </div>
              <div className="header text-center">
                <a className="logo logo-dark">
                  <span className="">
                    {managerInfo.logo && (
                      <img src={managerInfo.logo} alt="" height="100" />
                    )}
                  </span>
                </a>
              </div>

              <div className="text-center mb-3">
                <h2>Book Appointment</h2>
              </div>

              <form className="w-100" onSubmit={formik.handleSubmit}>
                <Row className="d-flex flex-wrap">
                  <Col className="col-12 mb-2">
                    <label>Name *</label>
                    <input
                      name="name"
                      placeholder="Enter name"
                      type="text"
                      className="form-control"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-danger">{formik.errors.name}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-2">
                    <label>Email *</label>
                    <input
                      name="email"
                      placeholder="Enter email"
                      type="email"
                      className="form-control"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger">{formik.errors.email}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-2">
                    <label>Reason Type *</label>
                    <select
                      name="reason"
                      className="form-control"
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Reason</option>
                      {reasonType.length > 0 &&
                        reasonType.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.reasonType}
                          </option>
                        ))}
                    </select>
                    {formik.touched.reason && formik.errors.reason && (
                      <div className="text-danger">{formik.errors.reason}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-2">
                    <label>Phone *</label>
                    <input
                      name="phone"
                      placeholder="Enter phone"
                      type="text"
                      className="form-control"
                      value={formik.values.phone}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[0-9+]*$/.test(value)) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="text-danger">{formik.errors.phone}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-2">
                    <label>Other Phone</label>
                    <input
                      name="other_phone"
                      placeholder="Enter other phone"
                      type="text"
                      className="form-control"
                      value={formik.values.other_phone}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[0-9+]*$/.test(value)) {
                          formik.handleChange(e);
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.other_phone && formik.errors.other_phone && (
                      <div className="text-danger">{formik.errors.other_phone}</div>
                    )}
                  </Col>

                  <Col className="col-12 mb-2">
                    <label>Comments</label>
                    <textarea
                      name="comment"
                      placeholder="Enter comments"
                      className="form-control"
                      rows={3} // Adjust row height as needed
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.comment && formik.errors.comment && (
                      <div className="text-danger">{formik.errors.comment}</div>
                    )}
                  </Col>
                  <Col className="col-6 mb-3">
                    <label>Choose Property *</label>
                    <input
                      type="text"
                      name="property"
                      className="form-control"
                      value={filtered_property?.title || ""}
                      readOnly
                    />
                    {formik.touched.property && formik.errors.property && (
                      <div className="text-danger">{formik.errors.property}</div>
                    )}
                  </Col>
                  <Col className="col-6 mb-3">
                    <label>Choose Layout</label>
                    <select
                      name="layout"
                      className="form-control"
                      value={formik.values.layout}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Layout</option>
                      {layout_list?.length > 0 ? (
                        layout_list.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.layoutName}
                          </option>
                        ))
                      ) : (
                        <option value="">Not available</option>
                      )}
                    </select>
                    {formik.touched.layout && formik.errors.layout && (
                      <div className="text-danger">{formik.errors.layout}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-2">
                    <label>Appointment Date *</label>
                    <input
                      name="appointment_date"
                      type="date"
                      className="form-control"
                      min={new Date().toISOString().split("T")[0]}
                      value={formik.values.appointment_date}
                      onChange={handleDateChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.appointment_date && formik.errors.appointment_date && (
                      <div className="text-danger">{formik.errors.appointment_date}</div>
                    )}
                  </Col>

                  <Col className="col-6 mb-3">
                    <label>Choose Slot *</label>
                    <select
                      name="slot"
                      className="form-control"
                      value={formik.values.slot}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Slot</option>
                      {daysAvailability?.available_slots?.length > 0 ? (
                        daysAvailability.available_slots.map((slot, index) => (
                          <option key={index} value={slot}>
                            {slot}
                          </option>
                        ))
                      ) : (
                        <option value="">No Slots Available</option>
                      )}
                    </select>
                    {formik.touched.slot && formik.errors.slot && (
                      <div className="text-danger">{formik.errors.slot}</div>
                    )}
                  </Col>

                  <Col className="col-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Calendar;
