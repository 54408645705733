import React, { useState, useEffect, useRef } from 'react';
import { getIn, useFormik } from 'formik';
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  Container,
  CardBody,
  Progress,
  Label,
  Input,
  CardTitle,
  Button,
} from "reactstrap";
import SignatureCanvas from 'react-signature-canvas';
import axios from '../../api/axios';
import {
  getDomainFromUrl,
  api,
  ThankYou,
  residentApplicationInitialValues,
  residentApplicationFormSchema,
  FileUploadField,
  getDate,
  getRequiredFields,
  AddressAutocomplete,
  onSubmit
} from './Helpers';
import { getCompanyDomain } from './Helpers/helpers';

const { get_property_url } = api.endpoints;

const ResidentApplicationForm = () => {
  const sigPads = useRef([]);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyLayouts, setPropertyLayouts] = useState([]);
  const [redirectSeconds, setRedirectSeconds] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const messagesEndRef = useRef();
  const formik = useFormik({
    initialValues: residentApplicationInitialValues,
    validationSchema: residentApplicationFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const confirmResult = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to submit the application?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit it!",
        cancelButtonText: "Cancel",
      });

      if (!confirmResult.isConfirmed) {
        setSubmitting(false);
        return; // Stop submission if canceled
      }

      setSubmitting(true);
      setIsSubmitting(true);

      try {
        const response = await onSubmit(values);
        Swal.fire("Success!", "Application submitted successfully.", "success");
        if (response[0].status === 201) {
          handleRedirect();
        }
      } catch (error) {
        console.error("Submission failed:", error);
        Swal.fire("Error!", "Something went wrong. Please try again.", "error");
      } finally {
        setSubmitting(false);
        setIsSubmitting(false);
      }
    },
  });

  const requiredFields = React.useMemo(() => getRequiredFields(residentApplicationFormSchema), []);
  const total = requiredFields.size;

  const isFilled = (field) => {
    if (field.includes("[]")) {
      // Handle array fields
      const [arrayPath, fieldName] = field.split("[].");
      const arrayValue = getIn(formik.values, arrayPath);

      if (!Array.isArray(arrayValue) || arrayValue.length === 0) return false;

      // Check if at least one item has the field filled
      return arrayValue.some((item) => {
        const itemValue = getIn(item, fieldName);
        return itemValue !== "" && itemValue !== null && itemValue !== undefined;
      });
    }

    // Normal field handling
    const value = getIn(formik.values, field);

    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === "object" && value !== null) return Object.keys(value).length > 0;
    if (typeof value === "boolean") return value === true;
    if (typeof value === "number") return true;

    return value !== "" && value !== null && value !== undefined;
  };

  const filledFields = [...requiredFields].filter(isFilled);
  const progress = total > 0 ? Math.round((filledFields.length / total) * 100) : 0;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.post(get_property_url, {
          url: window.location.href,
        });

        const properties = response.data.properties || [];
        setPropertyList(properties);

        if (properties.length > 0) {
          const property = properties[0];

          setPropertyLayouts(property.layouts || []);
          formik.setFieldValue('main.city', property.location || '');
          formik.setFieldValue('main.property', property.title || '');
          formik.setFieldValue('main.propertyID', property._id || '');
          formik.setFieldValue('companyDomain', getCompanyDomain() || '');
        }
      } catch (error) {
        console.error('Failed to fetch properties:', error);
      }
    };

    fetchProperties();
  }, []);

  const { values, errors, touched, setFieldValue } = formik;

  const handleRedirect = () => {
    setRedirectSeconds(2);
    const interval = setInterval(() => {
      setRedirectSeconds(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          window.location.replace(getDomainFromUrl());
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const togglePets = (hasAnimals) => {
    setFieldValue('showPets', hasAnimals ? true : false);
    if (hasAnimals) {
      setFieldValue('petsInformation', [{
        pets: '',
        breed: '',
        weight: '',
        age: '',
      }]);
    } else {
      setFieldValue('petsInformation', []);
    }
  };

  const addPet = () => {
    formik.setFieldValue("petsInformation", [
      ...formik.values.petsInformation,
      { pets: "", breed: "", weight: "", age: "" },
    ]);
  };

  const removePet = (index) => {
    const updatedPets = formik.values.petsInformation.filter((_, i) => i !== index);
    formik.setFieldValue("petsInformation", updatedPets);
  };

  const addResidePerson = () => {
    formik.setFieldValue("residePersons", [
      ...formik.values.residePersons,
      { firstname: "", lastname: "", relation: "", age: "" },
    ]);
  };

  const removeResidePerson = (index) => {
    const updatedPersons = [...formik.values.residePersons];
    updatedPersons.splice(index, 1);
    formik.setFieldValue("residePersons", updatedPersons);
  };

  const setFieldsAsNA = (index) => {
    const updatedPersons = [...formik.values.residePersons];
    updatedPersons[index] = {
      firstname: "N/A",
      lastname: "N/A",
      relation: "N/A",
      age: 0,
    };
    formik.setFieldValue("residePersons", updatedPersons);
  };



  const saveSignature = (index) => {
    const imageData = sigPads.current[index]?.getTrimmedCanvas()?.toDataURL("image/png");

    if (imageData) {
      const currentSignatures = Array.isArray(formik.values.signatures)
        ? formik.values.signatures
        : [];
      const updatedSignatures = [...currentSignatures];
      updatedSignatures[index] = {
        signature: imageData,
        date: new Date().toISOString()
      };
      formik.setFieldValue("signatures", updatedSignatures);
    }
  };

  const clearSignature = (index) => {
    sigPads.current[index]?.clear();

    const currentSignatures = Array.isArray(formik.values.signatures)
      ? formik.values.signatures
      : [];
    const updatedSignatures = [...currentSignatures];
    updatedSignatures[index] = {
      signature: "",
      date: new Date().toISOString()
    };
    formik.setFieldValue("signatures", updatedSignatures);
  };

  const undoLastStroke = (index) => {
    if (sigPads.current[index]) {
      let data = sigPads.current[index].toData();
      if (data.length > 0) {
        data.pop(); // Remove last stroke
        sigPads.current[index].fromData(data);

        const updatedImage = sigPads.current[index].toDataURL("image/png");
        const currentSignatures = formik.values.signatures || [];
        const updatedSignatures = [...currentSignatures];
        updatedSignatures[index] = {
          signature: updatedImage,
          date: new Date().toISOString()
        };
        formik.setFieldValue("signatures", updatedSignatures);
      }
    }
  };

  return (
    <div ref={messagesEndRef}>
      <div className="applicantStepForm-card-disabled  min-vh-100 py-5 opacity-transparent py-4">
        <Container>
          <Row>
            <Col lg={12}>
              <Card id="applicantStepForm">
                <CardBody className="tickt-bdy">
                  <div className="logo-container">
                    <div className="rdcarelogo-container">
                      <img
                        className="parteneredwithrdcare-logo"
                        alt="Partnered with RD Care"
                        src="/images/rdcarelogo.png"
                      />
                    </div>
                  </div>
                  <Row className="tickt-frm-hd justify-content-center mt-3">
                    <div className="col-xl-8 col-lg-10">
                      <div className="logo-top text-center">
                        {propertyList?.[0]?.companies?.logo ? (
                          <img
                            src={propertyList[0]?.companies?.logo}
                            alt="Company Logo"
                            width={150}
                            className="h-[150px] mx-auto object-contain"
                          />
                        ) : (
                          <h2>{propertyList?.[0]?.company}</h2>
                        )}
                      </div>
                      <div className="hd-frm text-center">
                        <h2>Resident Application Form</h2>
                      </div>
                    </div>
                  </Row>
                  <Row className="justify-content-center mt-3">
                    <div className="col-xl-12 col-lg-12">
                      <div
                        id="progrss-wizard"
                        className="twitter-bs-wizard"
                      >
                        <div id="bar" className="mt-4">
                          <Progress
                            color="success"
                            striped
                            animated
                            value={progress}
                          />
                        </div>
                        <form onSubmit={formik.handleSubmit} className="mt-5">
                          <div className="row">
                            <div className="col-lg-4 d-none">
                              <div className="mb-3">
                                <label htmlFor="main.city" className="form-label">City *</label>
                                <select
                                  name="main.city"
                                  id="main.city"
                                  className={`form-select ${formik.errors.main?.city && formik.touched.main?.city ? 'is-invalid' : ''}`}
                                  value={formik.values.main?.city}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select City</option>
                                  {(propertyList || []).map((property) => (
                                    <option key={`${property._id} city` || `${property.location} city`} value={property.location}>
                                      {property.location}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.main?.city && formik.errors.main?.city && (
                                  <div className="invalid-feedback">{formik.errors.main?.city}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4 d-none">
                              <div className="mb-3">
                                <label htmlFor="main.property" className="form-label">Property *</label>
                                <select
                                  name="main.property"
                                  id="main.property"
                                  className={`form-select ${formik.errors.main?.property && formik.touched.main?.property ? 'is-invalid' : ''}`}
                                  value={formik.values.main?.property}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select Property</option>
                                  {(propertyList || []).map((property) => (
                                    <option key={`${property._id} property` || `${property.title} property`} value={property.title}>
                                      {property.title}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.main?.property && formik.errors.main?.property && (
                                  <div className="invalid-feedback">{formik.errors.main?.property}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label htmlFor="main.date" className="form-label">Move-in Date *</label>
                                <input
                                  type="main.date"
                                  name="main.date"
                                  id="main.date"
                                  className={`form-control ${formik.errors.main?.date && formik.touched.main?.date ? 'is-invalid' : ''}`}
                                  value={formik.values.main?.date}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.main?.date && formik.errors.main?.date && (
                                  <div className="invalid-feedback">{formik.errors.main?.date}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label htmlFor="main.layout" className="form-label">Select Layout *</label>
                                <select
                                  name="main.layout"
                                  id="main.layout"
                                  className={`form-select ${formik.errors.main?.layout && formik.touched.main?.layout ? 'is-invalid' : ''}`}
                                  value={formik.values.main?.layoutID || ""}
                                  onChange={(e) => {
                                    const layoutID = e.target.value;
                                    const layoutName = e.target.options[e.target.selectedIndex].dataset.order;

                                    formik.setFieldValue("main.layoutID", layoutID);
                                    formik.setFieldValue("main.layout", layoutName);
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select Layout</option>
                                  {propertyLayouts
                                    .sort((a, b) => a.layoutName.localeCompare(b.layoutName))
                                    .map((layout) => (
                                      <option
                                        key={layout._id || layout.title}
                                        value={layout._id}
                                        data-order={layout.layoutName}
                                      >
                                        {layout.layoutName}
                                      </option>
                                    ))}
                                </select>

                                {formik.touched.main?.layout && formik.errors.main?.layout && (
                                  <div className="invalid-feedback">{formik.errors.main?.layout}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label htmlFor="main.source" className="form-label">How Did You Hear About Us? *</label>
                                <select
                                  name="main.source"
                                  id="main.source"
                                  className={`form-select ${formik.errors.main?.source && formik.touched.main?.source ? 'is-invalid' : ''}`}
                                  value={formik.values.main?.source}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select Source</option>
                                  {[
                                    'Facebook', 'Google', 'Website',
                                    'Other', 'Referral', 'Rent Board', 'Rent Faster'
                                  ].map(source => (
                                    <option
                                      key={`${source} source`}
                                      value={source}>
                                      {source}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.main?.source && formik.errors.main?.source && (
                                  <div className="invalid-feedback">{formik.errors.main?.source}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          {formik.values.applicants.map((_, index) => (
                            <div
                              key={`${index} applicant`}
                              className="applicant-section"
                            >
                              <h6 className='text-center text-decoration-underline fw-bold'>APPLICANT # <strong>{index + 1}</strong></h6>
                              <div className="row g-2">
                                <div className="col-lg-3">
                                  <Label className="form-label">First Name *</Label>
                                  <Input
                                    name={`applicants[${index}].firstname`}
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].firstname}
                                  />
                                  {formik.touched.applicants?.[index]?.firstname &&
                                    formik.errors.applicants?.[index]?.firstname && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].firstname}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Last Name *</Label>
                                  <Input
                                    name={`applicants[${index}].lastname`}
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].lastname}
                                  />
                                  {formik.touched.applicants?.[index]?.lastname &&
                                    formik.errors.applicants?.[index]?.lastname && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].lastname}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Phone *</Label>
                                  <Input
                                    name={`applicants[${index}].phone`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 10) {
                                        formik.setFieldValue(`applicants[${index}].phone`, value);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].phone}
                                  />
                                  {formik.touched.applicants?.[index]?.phone &&
                                    formik.errors.applicants?.[index]?.phone && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].phone}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Email *</Label>
                                  <Input
                                    name={`applicants[${index}].email`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/[^a-zA-Z0-9@._-]/g, ''); // Allows only valid email characters
                                      formik.setFieldValue(`applicants[${index}].email`, value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].email}
                                  />
                                  {formik.touched.applicants?.[index]?.email &&
                                    formik.errors.applicants?.[index]?.email && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].email}
                                      </div>
                                    )}
                                </div>

                                <AddressAutocomplete
                                  key={`${index} currentAddress`}
                                  formik={formik} index={index} label={'Current Address *'} field="currentAddress"
                                />

                                <div className="col-lg-3">
                                  <Label className="form-label">
                                    How long at current address? (Years) *
                                  </Label>
                                  <Input
                                    name={`applicants[${index}].howLong`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      let value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 2) {
                                        formik.setFieldValue(`applicants[${index}].howLong`, value);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].howLong}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  {formik.touched.applicants?.[index]?.howLong &&
                                    formik.errors.applicants?.[index]?.howLong && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].howLong}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Current Landlord's Name *</Label>
                                  <Input
                                    name={`applicants[${index}].currentLandlord`}
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].currentLandlord}
                                  />
                                  {formik.touched.applicants?.[index]?.currentLandlord &&
                                    formik.errors.applicants?.[index]?.currentLandlord && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].currentLandlord}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Landlord's Phone *</Label>
                                  <Input
                                    name={`applicants[${index}].currentLandlordPhone`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 10) {
                                        formik.setFieldValue(`applicants[${index}].currentLandlordPhone`, value);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index].currentLandlordPhone}
                                  />
                                  {formik.touched.applicants?.[index]?.currentLandlordPhone &&
                                    formik.errors.applicants?.[index]?.currentLandlordPhone && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index].currentLandlordPhone}
                                      </div>
                                    )}
                                </div>

                                <AddressAutocomplete
                                  key={`${index} previousAddress`}
                                  formik={formik}
                                  index={index}
                                  label={'Previous address  *'}
                                  field={`previousAddressInformation.previousAddress`}
                                />

                                <div className="col-lg-3">
                                  <Label className="form-label">How long at previous address? (Years) *</Label>
                                  <Input
                                    name={`applicants[${index}].previousAddressInformation.howLongAtPreviousAddress`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      let value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 2) {
                                        formik.setFieldValue(`applicants[${index}].previousAddressInformation.howLongAtPreviousAddress`, value);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index]?.previousAddressInformation?.howLongAtPreviousAddress}
                                  />
                                  {formik.touched.applicants?.[index]?.previousAddressInformation?.howLongAtPreviousAddress &&
                                    formik.errors.applicants?.[index]?.previousAddressInformation?.howLongAtPreviousAddress && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index]?.previousAddressInformation?.howLongAtPreviousAddress}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Previous landlord's name *</Label>
                                  <Input
                                    name={`applicants[${index}].previousAddressInformation.previousLandlordName`}
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index]?.previousAddressInformation?.previousLandlordName}
                                  />
                                  {formik.touched.applicants?.[index]?.previousAddressInformation?.previousLandlordName &&
                                    formik.errors.applicants?.[index]?.previousAddressInformation?.previousLandlordName && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index]?.previousAddressInformation?.previousLandlordName}
                                      </div>
                                    )}
                                </div>

                                <div className="col-lg-3">
                                  <Label className="form-label">Previous landlord's phone *  </Label>
                                  <Input
                                    name={`applicants[${index}].previousAddressInformation.previousLandlordPhone`}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 10) {
                                        formik.setFieldValue(`applicants[${index}].previousAddressInformation.previousLandlordPhone`, value);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.applicants[index]?.previousAddressInformation?.previousLandlordPhone}
                                  />
                                  {formik.touched.applicants?.[index]?.previousAddressInformation?.previousLandlordPhone &&
                                    formik.errors.applicants?.[index]?.previousAddressInformation?.previousLandlordPhone && (
                                      <div className="text-danger">
                                        {formik.errors.applicants[index]?.previousAddressInformation?.previousLandlordPhone}
                                      </div>
                                    )}
                                </div>

                                <div className="row g-2 align-items-start">
                                  <div className="col-lg-6">
                                    <div className="row g-3">
                                      <div className="col-6">
                                        <Label className="form-label">Current Employer *</Label>
                                        <Input
                                          name={`applicants[${index}].currentEmployer`}
                                          type="text"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].currentEmployer}
                                        />
                                        {formik.touched.applicants?.[index]?.currentEmployer &&
                                          formik.errors.applicants?.[index]?.currentEmployer && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].currentEmployer}
                                            </div>
                                          )}
                                      </div>

                                      <div className="col-6">
                                        <Label className="form-label">How long at current employer? (Years) *</Label>
                                        <Input
                                          name={`applicants[${index}].currentEmployerFor`}
                                          type="text"
                                          className="form-control"
                                          onChange={(e) => {
                                            let value = e.target.value.replace(/\D/g, '');
                                            if (value.length <= 2) {
                                              formik.setFieldValue(`applicants[${index}].currentEmployerFor`, value);
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].currentEmployerFor}
                                        />
                                        {formik.touched.applicants?.[index]?.currentEmployerFor &&
                                          formik.errors.applicants?.[index]?.currentEmployerFor && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].currentEmployerFor}
                                            </div>
                                          )}
                                      </div>

                                      <div className="col-6">
                                        <Label className="form-label">Occupation *</Label>
                                        <Input
                                          name={`applicants[${index}].occupation`}
                                          type="text"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].occupation}
                                        />
                                        {formik.touched.applicants?.[index]?.occupation &&
                                          formik.errors.applicants?.[index]?.occupation && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].occupation}
                                            </div>
                                          )}
                                      </div>

                                      <div className="col-6">
                                        <Label className="form-label">Annual income *</Label>
                                        <Input
                                          name={`applicants[${index}].annualIncome`}
                                          type="number"
                                          className="form-control"
                                          inputMode="numeric"
                                          pattern="[0-9]*"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].annualIncome}
                                          onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "+" || e.key === "-") {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                        {formik.touched.applicants?.[index]?.annualIncome &&
                                          formik.errors.applicants?.[index]?.annualIncome && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].annualIncome}
                                            </div>
                                          )}
                                      </div>

                                      <div className="col-6">
                                        <Label className="form-label">Supervisor/Manager *</Label>
                                        <Input
                                          name={`applicants[${index}].manager`}
                                          type="text"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].manager}
                                        />
                                        {formik.touched.applicants?.[index]?.manager &&
                                          formik.errors.applicants?.[index]?.manager && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].manager}
                                            </div>
                                          )}
                                      </div>

                                      <div className="col-6">
                                        <Label className="form-label">Employer Phone *</Label>
                                        <Input
                                          name={`applicants[${index}].currentEmployerPhone`}
                                          type="text"
                                          className="form-control"
                                          onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, '');
                                            if (value.length <= 10) {
                                              formik.setFieldValue(`applicants[${index}].currentEmployerPhone`, value);
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.applicants[index].currentEmployerPhone}
                                        />
                                        {formik.touched.applicants?.[index]?.currentEmployerPhone &&
                                          formik.errors.applicants?.[index]?.currentEmployerPhone && (
                                            <div className="text-danger">
                                              {formik.errors.applicants[index].currentEmployerPhone}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <hr className="d-lg-none mt-5" />
                                  {/* Right side - File upload taking half screen */}
                                  <div className="col-lg-6">
                                    <FileUploadField
                                      key={`${index} documents`}
                                      name={`applicants[${index}].documents`}
                                      label="Attach 2 pieces of Govt. Identification Documents *"
                                      formik={formik}
                                    />
                                  </div>
                                  <hr className="d-lg-none mb-5" />
                                </div>

                              </div>

                              <div className="d-flex justify-content-between mt-2">
                                {index > 0 && (
                                  <Button
                                    color="danger"
                                    type="button"
                                    onClick={() => {
                                      const newApplicants = [...formik.values.applicants];
                                      newApplicants.splice(index, 1);
                                      formik.setFieldValue("applicants", newApplicants);
                                    }}
                                    disabled={formik.values.applicants.length === 1}
                                  >
                                    Remove Applicant {index + 1}
                                  </Button>
                                )}

                                {index === formik.values.applicants.length - 1 && formik.values.applicants.length < 2 && (
                                  <Button
                                    color="primary"
                                    type="button"
                                    onClick={() =>
                                      formik.setFieldValue("applicants", [
                                        ...formik.values.applicants,
                                        {
                                          firstname: '',
                                          lastname: '',
                                          phone: '',
                                          email: '',
                                          currentAddress: '',
                                          howLong: '',
                                          currentLandlord: '',
                                          currentLandlordPhone: '',
                                          previousAddressInformation: {
                                            previousAddress: '',
                                            howLongAtPreviousAddress: '',
                                            previousLandlordName: '',
                                            previousLandlordPhone: '',
                                          },
                                          currentEmployer: '',
                                          currentEmployerFor: '',
                                          occupation: '',
                                          annualIncome: '',
                                          manager: '',
                                          currentEmployerPhone: '',
                                          documents: [],
                                        },
                                      ])
                                    }
                                  >
                                    Add Applicant {index + 2}
                                  </Button>
                                )}
                              </div>
                              <hr />
                            </div>
                          ))}
                          <div className="row">
                            <div className="col">
                              <div className="frm-hd my-3">
                                <h4>Other information</h4>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <h6 className="d-inline-block me-3 mb-4">
                              Do you have pets ?
                            </h6>
                            <Button
                              type="button"
                              color={values.showPets ? "primary" : "secondary"}
                              onClick={() => togglePets(true)}
                              className="w-20 mr-5"
                            >
                              YES
                            </Button>
                            <Button
                              type="button"
                              color={!values.showPets ? "primary" : "secondary"}
                              onClick={() => togglePets(false)}
                              className="w-20"
                            >
                              NO
                            </Button>
                            {values.showPets && values.petsInformation.length > 0 ? (
                              values.petsInformation.map((pet, index) => (
                                <div className="row mb-2" key={`${index} Pets`}>
                                  <div className="col-lg">
                                    <Label className="form-label">Pets *</Label>
                                    <select
                                      className="form-select"
                                      name={`petsInformation[${index}].pets`}
                                      onChange={formik.handleChange}
                                      value={pet.pets}
                                      disabled={!values.showPets}
                                    >
                                      <option value="">Select One</option>
                                      <option value="Cat">Cat</option>
                                      <option value="Dog">Dog</option>
                                      <option value="Other">Other</option>
                                    </select>
                                    {formik.touched.petsInformation?.[index]?.pets &&
                                      formik.errors.petsInformation?.[index]?.pets && (
                                        <div className="text-danger">{formik.errors.petsInformation[index].pets}</div>
                                      )}
                                  </div>
                                  <div className="col-lg">
                                    <Label className="form-label">Breed *</Label>
                                    <Input
                                      type="text"
                                      name={`petsInformation[${index}].breed`}
                                      onChange={formik.handleChange}
                                      value={pet.breed}
                                      placeholder="Breed"
                                      disabled={!values.showPets}
                                    />
                                    {formik.touched.petsInformation?.[index]?.breed &&
                                      formik.errors.petsInformation?.[index]?.breed && (
                                        <div className="text-danger">{formik.errors.petsInformation[index].breed}</div>
                                      )}
                                  </div>
                                  <div className="col-lg">
                                    <Label className="form-label">Weight *</Label>
                                    <Input
                                      type="number"
                                      name={`petsInformation[${index}].weight`}
                                      onChange={formik.handleChange}
                                      value={pet.weight}
                                      placeholder="Weight"
                                      disabled={!values.showPets}
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      onKeyDown={(e) => {
                                        if (e.key === "e" || e.key === "+" || e.key === "-") {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {formik.touched.petsInformation?.[index]?.weight &&
                                      formik.errors.petsInformation?.[index]?.weight && (
                                        <div className="text-danger">{formik.errors.petsInformation[index].weight}</div>
                                      )}
                                  </div>

                                  <div className="col-lg">
                                    <Label className="form-label">Age *</Label>
                                    <Input
                                      type="number"
                                      name={`petsInformation[${index}].age`}
                                      onChange={formik.handleChange}
                                      value={pet.age}
                                      placeholder="Age"
                                      disabled={!values.showPets}
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      onKeyDown={(e) => {
                                        if (e.key === "e" || e.key === "+" || e.key === "-") {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {formik.touched.petsInformation?.[index]?.age &&
                                      formik.errors.petsInformation?.[index]?.age && (
                                        <div className="text-danger">{formik.errors.petsInformation[index].age}</div>
                                      )}
                                  </div>

                                  <div className="col-lg-auto d-flex align-items-end">
                                    {index === 0 ? (
                                      <Button color="primary" onClick={addPet} disabled={!values.showPets}>
                                        <i className="bx bx-plus"></i>
                                      </Button>
                                    ) : (
                                      <Button color="secondary" onClick={() => removePet(index)} disabled={!values.showPets}>
                                        <i className="bx bx-minus"></i>
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : null}
                            {formik.values.residePersons.map((person, index) => (
                              <div className="row mb-2" key={`${index} residePersons`}>
                                <div className="col-lg">
                                  <Label
                                    className="form-label"
                                    htmlFor={`residePersons[${index}]`}
                                  >
                                    Names of other persons who
                                    will reside in the unit
                                  </Label>

                                  <div className="row">
                                    {/* First Name */}
                                    <div className="col-lg-6">
                                      <Input
                                        name={`residePersons[${index}].firstname`}
                                        value={person.firstname}
                                        onChange={formik.handleChange}
                                        placeholder="Enter first name"
                                      />
                                      {formik.touched.residePersons?.[index]?.firstname &&
                                        formik.errors.residePersons?.[index]?.firstname && (
                                          <div className="text-danger">{formik.errors.residePersons[index].firstname}</div>
                                        )}
                                    </div>

                                    {/* Last Name */}
                                    <div className="col-lg-6">
                                      <Input
                                        name={`residePersons[${index}].lastname`}
                                        value={person.lastname}
                                        onChange={formik.handleChange}
                                        placeholder="Enter last name"
                                      />
                                      {formik.touched.residePersons?.[index]?.lastname &&
                                        formik.errors.residePersons?.[index]?.lastname && (
                                          <div className="text-danger">{formik.errors.residePersons[index].lastname}</div>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                {/* Relation */}
                                <div className="col-lg-3">
                                  <Label className="form-label">Relation</Label>
                                  <Input
                                    name={`residePersons[${index}].relation`}
                                    value={person.relation}
                                    onChange={formik.handleChange}
                                    placeholder="Enter relation"
                                  />
                                  {formik.touched.residePersons?.[index]?.relation &&
                                    formik.errors.residePersons?.[index]?.relation && (
                                      <div className="text-danger">{formik.errors.residePersons[index].relation}</div>
                                    )}
                                </div>

                                {/* Age */}
                                <div className="col-lg-2">
                                  <Label className="form-label">Age</Label>
                                  <Input
                                    name={`residePersons[${index}].age`}
                                    value={person.age}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*$/.test(value) && Number(value) <= 200) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    placeholder="Enter age"
                                    type="number"
                                    min="0"
                                    max="200"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    onKeyDown={(e) => {
                                      if (e.key === "e" || e.key === "+" || e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {formik.touched.residePersons?.[index]?.age &&
                                    formik.errors.residePersons?.[index]?.age && (
                                      <div className="text-danger">{formik.errors.residePersons[index].age}</div>
                                    )}
                                </div>

                                {/* Buttons */}
                                <div className="col-lg-auto d-flex align-items-end">
                                  {index === 0 ? (
                                    <Button color="primary" onClick={addResidePerson} type="button">
                                      <i className="bx bx-plus"></i>
                                    </Button>
                                  ) : (
                                    <Button
                                      color="secondary"
                                      onClick={() => removeResidePerson(index)}
                                      type="button"
                                    >
                                      <i className="bx bx-minus"></i>
                                    </Button>
                                  )}
                                  <Button
                                    className='ms-2'
                                    color="secondary"
                                    onClick={() => setFieldsAsNA(index)}
                                    type="button"
                                  >
                                    NA
                                  </Button>
                                </div>
                              </div>
                            ))}

                            {formik.values.emergencyContacts.map((contact, index) => (
                              <div className="row mb-2" key={`${index} emergencyContacts`}>
                                <div className="col-lg">
                                  <Label className="form-label" htmlFor={`emergencyContacts[${index}]`}>
                                    {index + 1}- Person to notify in case of emergency *
                                  </Label>

                                  <div className="row">
                                    {/* First Name */}
                                    <div className="col-lg-6">
                                      <Input
                                        name={`emergencyContacts[${index}].firstname`}
                                        value={contact.firstname}
                                        onChange={formik.handleChange}
                                        placeholder="Enter first name"
                                      />
                                      {formik.touched.emergencyContacts?.[index]?.firstname &&
                                        formik.errors.emergencyContacts?.[index]?.firstname && (
                                          <div className="text-danger">{formik.errors.emergencyContacts[index].firstname}</div>
                                        )}
                                    </div>

                                    {/* Last Name */}
                                    <div className="col-lg-6">
                                      <Input
                                        name={`emergencyContacts[${index}].lastname`}
                                        value={contact.lastname}
                                        onChange={formik.handleChange}
                                        placeholder="Enter last name"
                                      />
                                      {formik.touched.emergencyContacts?.[index]?.lastname &&
                                        formik.errors.emergencyContacts?.[index]?.lastname && (
                                          <div className="text-danger">{formik.errors.emergencyContacts[index].lastname}</div>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                {/* Relation */}
                                <div className="col-lg-3">
                                  <Label className="form-label">Relation</Label>
                                  <Input
                                    name={`emergencyContacts[${index}].relation`}
                                    value={contact.relation}
                                    onChange={formik.handleChange}
                                    placeholder="Enter relation"
                                  />
                                  {formik.touched.emergencyContacts?.[index]?.relation &&
                                    formik.errors.emergencyContacts?.[index]?.relation && (
                                      <div className="text-danger">{formik.errors.emergencyContacts[index].relation}</div>
                                    )}
                                </div>

                                {/* Phone */}
                                <div className="col-lg-3">
                                  <Label className="form-label">Phone</Label>
                                  <Input
                                    name={`emergencyContacts[${index}].phone`}
                                    value={contact.phone}
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/\D/g, '');
                                      if (value.length <= 10) {
                                        formik.setFieldValue(`emergencyContacts[${index}].phone`, value);
                                      }
                                    }}
                                    placeholder="Enter phone number"
                                    type="tel"
                                  />
                                  {formik.touched.emergencyContacts?.[index]?.phone &&
                                    formik.errors.emergencyContacts?.[index]?.phone && (
                                      <div className="text-danger">{formik.errors.emergencyContacts[index].phone}</div>
                                    )}
                                </div>
                              </div>
                            ))}

                            <div className="row">
                              <div className="col-lg-12">
                                <CardTitle className="h3">
                                  Terms and Conditions *
                                </CardTitle>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agreement"
                                    name="agreement"
                                    onChange={(e) => formik.setFieldValue("agreement", e.target.checked)}
                                    checked={formik.values.agreement}
                                  />
                                  <label className="form-check-label" htmlFor="agreement">
                                    I warrant, to the best of my
                                    knowledge, all of the
                                    information provided in this
                                    application is true, accurate,
                                    complete and correct as of the
                                    date of this application. If
                                    any information provided by me
                                    is determined to be false,
                                    such false statement will be
                                    grounds for disapproval of my
                                    application.
                                  </label>

                                </div>
                                {formik.touched.agreement && formik.errors.agreement && <div className="text-danger">{formik.errors.agreement}</div>}
                              </div>
                              <div className="mt-3">
                                <CardTitle className="h3 ps-3">
                                  I understand and agree:
                                </CardTitle>
                                <ol className="lower-roman-list mb-0">
                                  <li>
                                    This is an application to rent only and does not guarantee that I will be offered the property.
                                  </li>
                                  <li>
                                    Landlord may accept more than one application for the property and, using their sole discretion, will select the best qualified applicant.
                                  </li>
                                  <li>
                                    I hereby authorize the Landlord or Manager or Agent to verify the information provided and obtain a credit report on me.
                                  </li>
                                </ol>
                              </div>
                            </div>

                            <div className="row mt-4">
                              {formik.values.applicants.map((_, index) => (
                                <div className="col-lg-6" key={`${index} Signature`}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <Label>Applicant {index + 1} Signature *</Label>
                                    <Label>Date: {getDate()}</Label>
                                  </div>
                                  <div className="signature-container">
                                    <SignatureCanvas
                                      penColor="black"
                                      ref={(el) => (sigPads.current[index] = el)}
                                      onEnd={() => saveSignature(index)}
                                      canvasProps={{ width: 640, height: 200, background: '#f6eee3', className: "sigCanvas" }}
                                    />
                                  </div>

                                  <div className="d-flex mt-2">
                                    <button
                                      type="button"
                                      className="btn btn-secondary me-2"
                                      onClick={() => clearSignature(index)}
                                    >
                                      Clear
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-warning me-2"
                                      onClick={() => undoLastStroke(index)}
                                    >
                                      Undo Last Stroke
                                    </button>
                                  </div>
                                  {formik.touched.signatures?.[index] && formik.errors.signatures?.[index] && (
                                    <div className="text-danger">{formik.errors.signatures}</div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          <Button type="submit" color="primary" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                          </Button>
                        </form>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <div id="thanks" className="text-center thanks white-bg">
                <img src={ThankYou} />
                <div className={`thankyou-title`}>
                  <span>Thank You !</span>
                </div>
                <p>
                  Your application has been successfully entered with us.
                </p>
                <p>
                  Redirecting back to website in {redirectSeconds} seconds...
                </p>

                <a
                  onClick={handleRedirect}
                  className="btn btn-primary"
                >
                  Go back
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </div >
  )
}

export { ResidentApplicationForm }
