import React, { useState } from "react";
import { Table, Container, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import './mastersheet.css';
import { formatDate } from "./TenantForm/Helpers/helpers";

const MasterSheetTable = ({ config }) => {
    const { items } = config
    const [hoveredRow, setHoveredRow] = useState(null);

    const renderTooltip = (row) => (
        <Tooltip id={`tooltip-${row.suite_no}`} style={{ zIndex: 9999, padding: "10px", maxWidth: "250px" }}>
            {row?.tenants?.length > 0 ? (
                <>
                    <div>
                        Tenant: {row.tenants.map(t => t.first_name).join(", ") || "N/A"} <br />
                        Base Rent: ${row.basic_rent || "N/A"}
                    </div>
                    <div style={{ marginTop: "8px", display: "flex", gap: "5px" }}>
                        <Button size="sm" variant="success" onClick={() => editTenant(row)}>Renew</Button>
                        <Button size="sm" variant="primary" onClick={() => editTenant(row)}>Edit</Button>
                        <Button size="sm" variant="danger" onClick={() => removeTenant(row)}>Archive</Button>
                    </div>
                </>
            ) : (
                <>
                    <div>No tenants assigned. Click to assign this suite.</div>
                    <div style={{ marginTop: "8px", display: "flex", gap: "5px" }}>
                        <Button size="sm" variant="success" onClick={() => sendLease(row)}>Lease</Button>
                        <Button size="sm" variant="danger" onClick={() => removeSuite(row)}>Remove</Button>
                    </div>
                </>
            )}
        </Tooltip>
    );

    const editTenant = (row) => console.log("Editing tenant:", row.suite_no);
    const removeTenant = (row) => console.log("Archiving tenant:", row.suite_no);
    const sendLease = (row) => console.log("Sending lease for:", row.suite_no);
    const removeSuite = (row) => console.log("Removing suite:", row.suite_no);

    return (
        <Container className="mt-4">
            <Table bordered hover responsive className="mastersheet-table">
                <thead>
                    <tr>
                        <th>SUITE NO.</th>
                        <th>LAYOUT</th>
                        <th>STYLE</th>
                        <th>AIR CONDITIONING</th>
                        <th>TOTAL AREA (SQ FT)</th>
                        <th>COVERED AREA (SQ FT)</th>
                        <th>BUILDING NO.</th>
                        <th>TENANTS</th>
                        <th>OCCUPANTS</th>
                        <th>SECURITY DEPOSIT</th>
                        <th>TARGET RENT</th>
                        <th>BASE RENT</th>
                        <th>UTILITY ACCOUNTS (H/W/E)</th>
                        <th>PETS</th>
                        <th>TOTAL RENT</th>
                        <th>LEASE START</th>
                        <th>LEASE END</th>
                        <th>INSURANCE EXPIRY</th>
                        <th>INSURANCE COMPANY</th>
                        <th>MAILBOX</th>
                        <th>FOB</th>
                        <th>TELUS</th>
                        <th>COMMENTS</th>
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 ? (
                        items.map((row, index) => (
                            <OverlayTrigger
                                key={index}
                                placement="left"
                                trigger="click"
                                overlay={renderTooltip(row)}
                            >
                                <tr
                                    className={`table-row ${hoveredRow === index ? "hovered-row" : ""}`}
                                    onMouseEnter={() => setHoveredRow(index)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                >
                                    {[
                                        { key: "suite_no", value: row.suite_no || "N/A", className: row.suite_no ? "table-success" : "table-danger" },
                                        { key: "layout", value: row.layout || "N/A" },
                                        { key: "style", value: row.style || "N/A" },
                                        { key: "air_conditioning", value: row.air_conditioning || "N/A" },
                                        { key: "total_area_in_sqft", value: row.total_area_in_sqft || "N/A" },
                                        { key: "covered_area_in_sqft", value: row.covered_area_in_sqft || "N/A" },
                                        { key: "building_no", value: row.building_no || "N/A" },
                                        { key: "tenant", value: row.tenants.length > 0 ? `${row.tenants.map(t => ` ${t.first_name} ${t.last_name}`).join(", ")} (${row.tenants.length})` : "N/A" },
                                        { key: "occupants", value: row.occupants.length > 0 ? `${row.occupants.map(o => ` ${o.first_name} ${o.last_name}`).join(", ")} (${row.occupants.length})` : "N/A" },
                                        { key: "security_deposit", value: `$${row.security_deposit || "N/A"}` },
                                        { key: "target_rent", value: `$${row.target_rent || "N/A"}` },
                                        { key: "basic_rent", value: `$${row.basic_rent || "N/A"}` },
                                        { key: "utility_accounts", value: row.utility_accounts ? `${row.utility_accounts.heat || "N/A"}, ${row.utility_accounts.water || "N/A"}, ${row.utility_accounts.electricity || "N/A"}` : "N/A" },
                                        {
                                            key: "pets",
                                            value: row.pets.length > 0
                                                ? row.pets
                                                    .map(pet => {
                                                        const details = [];
                                                        if (pet.breed) details.push(pet.breed);
                                                        if (pet.weight) details.push(`${pet.weight}kg`);
                                                        if (pet.age) details.push(`${pet.age} months`);

                                                        return details.length > 0
                                                            ? `${pet.category} (${details.join(", ")}) - Fee: $${pet.fee}`
                                                            : `N/A`;
                                                    })
                                                    .join("; ")
                                                : "N/A"
                                        },
                                        { key: "total_rent", value: `$${row.basic_rent + (row.pets.length > 0 ? row.pets.reduce((sum, pet) => sum + (pet.fee || 0), 0) : 0)}` },
                                        { key: "lease_start", value: formatDate(row.lease_start) || "N/A" },
                                        { key: "lease_end", value: formatDate(row.lease_end) || "N/A" },
                                        { key: "insurance_expiry_date", value: formatDate(row.insurance_expiry_date) || "N/A" },
                                        { key: "insurance_company_name", value: row.insurance_company_name || "N/A" },
                                        { key: "mailbox", value: row.mailbox || "N/A" },
                                        { key: "fob", value: row.fob || "N/A" },
                                        { key: "telus", value: `${row.telus}` || "N/A" },
                                        { key: "comments", value: row.comments || "N/A" }
                                    ].map(({ key, value, className }) => (
                                        <td key={key} className={`${className || ""} ${hoveredRow === index ? "hovered-row" : ""}`}>
                                            {value}
                                        </td>
                                    ))}
                                </tr>
                            </OverlayTrigger>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="16" className="text-center">
                                No data available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
};

export { MasterSheetTable };
