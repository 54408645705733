import * as Yup from 'yup';

const residentApplicationFormSchema = Yup.object().shape({
    main: Yup.object().shape({
        city: Yup.string().required('City is required'),
        date: Yup.date().required('Date is required'),
        property: Yup.string().required('Property is required'),
        propertyID: Yup.string().required('Property ID is required'),
        layout: Yup.string().required('Layout is required'),
        layoutID: Yup.string().required('Layout ID is required'),
        source: Yup.string().required('Source is required'),
    }),
    applicants: Yup.array()
        .of(
            Yup.object().shape({
                firstname: Yup.string().required('First name is required'),
                lastname: Yup.string().required('Last name is required'),
                phone: Yup.string()
                    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
                    .required('Phone is required'),
                email: Yup.string().email('Invalid email').required('Email is required'),
                currentAddress: Yup.string().required('Current address is required'),
                howLong: Yup.string().required('Duration at current address is required'),
                currentLandlord: Yup.string().required('Current landlord name is required'),
                currentLandlordPhone: Yup.string()
                    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
                    .required('Current landlord phone is required'),
                previousAddressInformation: Yup.object().shape({
                    previousAddress: Yup.string().required('Previous address is required'),
                    howLongAtPreviousAddress: Yup.string().required('Duration at previous address is required'),
                    previousLandlordName: Yup.string().required('Previous landlord name is required'),
                    previousLandlordPhone: Yup.string()
                        .test(
                            'is-valid-phone',
                            'Phone number must be exactly 10 digits or "N/A"',
                            (value) => value === 'N/A' || /^\d{10}$/.test(value || '')
                        )
                        .required('Previous landlord phone is required'),

                }),
                currentEmployer: Yup.string().required('Current employer is required'),
                currentEmployerFor: Yup.string().required('Employment duration is required'),
                occupation: Yup.string().required('Occupation is required'),
                annualIncome: Yup.string().required('Annual income is required'),
                manager: Yup.string().required('Manager name is required'),
                currentEmployerPhone: Yup.string()
                    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
                    .required('Employer phone is required'),
                documents: Yup.array()
                    .of(Yup.mixed())
                    .min(2, "At least two documents are required"),
            })
        )
        .min(1, 'At least one applicant is required'),
    showPets: Yup.boolean(),
    petsInformation: Yup.array().of(
        Yup.object().shape({
            pets: Yup.string().required('Pet type is required'),
            breed: Yup.string().required('Breed is required'),
            weight: Yup.number().required('Weight is required').positive(),
            age: Yup.number().required('Age is required').positive().integer(),
        })
    ),
    residePersons: Yup.array().of(
        Yup.object().shape({
            firstname: Yup.string().required('First name is required'),
            lastname: Yup.string().required('Last name is required'),
            relation: Yup.string().required('Relation is required'),
            age: Yup.number()
                .required('Age is required')
                .min(0, 'Age cannot be negative') // Allows 0 and positive numbers
                .integer('Age must be a whole number'),

        })
    ).min(1, 'At least one Resident is required'),
    emergencyContacts: Yup.array()
        .of(
            Yup.object().shape({
                firstname: Yup.string().required('First name is required'),
                lastname: Yup.string().required('Last name is required'),
                relation: Yup.string().required('Relation is required'),
                phone: Yup.string()
                    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
                    .required('Phone is required'),
            })
        ).min(2, 'At least two emergency contacts are required'),
    signatures: Yup.array()
        .of(
            Yup.object().shape({
                signature: Yup.string().required("Signature is required"),
                date: Yup.date().required("Date is required"),
            })
        )
        .min(1, "At least one signature is required")
        .required("Signatures are required"),
    agreement: Yup.boolean()
        .oneOf([true], 'You must accept the terms and conditions')
        .required('Agreement is required'),
    companyDomain: Yup.string().nullable(),

});

export { residentApplicationFormSchema }