import PropTypes from "prop-types";
import React from "react";
import { LoadScript } from "@react-google-maps/api";

import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Loading from 'react-fullscreen-loading';

// Import Routes all
import { userRoutes, authRoutes, PublicRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss

import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import "./index.scss";



// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.css';


// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {
  const { isLoading } = useSelector(state => ({
    isLoading: state.Layout.isLoading
  }));

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <ToastContainer />
      <LoadScript googleMapsApiKey="AIzaSyDGhG7GfTlq2aXI1XvViSSWdm4X8szOoQA" libraries={["places"]}>
      {isLoading && <Loading loading background="rgb(26 22 22 / 81%)" loaderColor="#3498db" />}
        <Router>
          <Switch>
            <Redirect exact from="/" to="/home" />{" "}
            {/* Add this line for default redirect */}
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            {PublicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout} // Change the layout if needed
                component={route.component}
                key={idx}
                isAuthProtected={false} // Set it to `true` if authentication is required for maintenance route
                exact
              />
            ))}
          </Switch>
        </Router>
      </LoadScript>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
