export const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  let day = "" + d.getUTCDate();
  let month = "" + (d.getUTCMonth() + 1);
  const year = d.getUTCFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [day, month, year].join("-");
};

export const generateRentOptions = (min, max) => {
  const options = [];
  for (let i = min; i <= max; i += 50) {
    options.push({ value: i, label: `$${i}` });
  }
  return options;
};