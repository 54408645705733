import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Collapse, Card, CardBody, Form, Row, Col, Label, Input } from "reactstrap";
import { getPropertyFilters } from "./TenantForm/Helpers/api";
import { generateRentOptions } from "./TenantForm/Helpers/helpers";

const FilterSection = ({ isFilterOpen, filterData, handleFilterChange }) => {
    const [filtersAutofills, setFiltersAutofills] = useState({
        tenants: [],
        emails: [],
        suites: [],
        leaseStart: "",
        leaseEnd: "",
        minRent: "",
        maxRent: "",
    });

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const { data } = await getPropertyFilters({
                    property_id: filterData.property_id,
                    building_name: filterData.building_name
                });
                setFiltersAutofills({
                    tenants: data.tenants?.map((tenant) => ({ value: tenant, label: tenant })) || [],
                    emails: data.emails?.map((email) => ({ value: email, label: email })) || [],
                    suites: data.suites || [],
                    leaseStart: data.leaseStart || "",
                    leaseEnd: data.leaseEnd || "",
                    minRent: data.minRent || "",
                    maxRent: data.maxRent || "",
                });
            } catch (error) {
                console.error("Error fetching filters:", error);
            }
        };

        fetchFilters();
    }, [filterData.property_id, filterData.building_name]);

    // Min Rent Options
    const minRentOptions = generateRentOptions(filtersAutofills.minRent, filterData.maxRent || filtersAutofills.maxRent);

    // Max Rent Options
    const maxRentOptions = generateRentOptions(filterData.minRent || filtersAutofills.minRent, filtersAutofills.maxRent);

    return (
        <Collapse isOpen={isFilterOpen}>
            <Card>
                <CardBody>
                    <Form>
                        <Row>
                            {/* Tenant Name */}
                            <Col md={2}>
                                <Label>Tenant Name</Label>
                                <Select
                                    name="tenant"
                                    value={filtersAutofills.tenants.find((t) => t.value === filterData.tenant) || null}
                                    options={filtersAutofills.tenants}
                                    onChange={(selected) =>
                                        handleFilterChange({ target: { name: "tenant", value: selected?.value || "" } })
                                    }
                                    isClearable
                                    placeholder="Search Tenant"
                                />
                            </Col>

                            {/* Tenant Email */}
                            <Col md={2}>
                                <Label>Tenant Email</Label>
                                <Select
                                    name="email"
                                    value={filtersAutofills.emails.find((e) => e.value === filterData.email) || null}
                                    options={filtersAutofills.emails}
                                    onChange={(selected) =>
                                        handleFilterChange({ target: { name: "email", value: selected?.value || "" } })
                                    }
                                    isClearable
                                    placeholder="Search Email"
                                />
                            </Col>

                            {/* Suite Number */}
                            <Col md={1}>
                                <Label>Suite No.</Label>
                                <Input
                                    type="select"
                                    name="suite"
                                    value={filterData.suite || ""}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Select Suite</option>
                                    {filtersAutofills.suites.map((suite) => (
                                        <option key={suite} value={suite}>
                                            {suite}
                                        </option>
                                    ))}
                                </Input>
                            </Col>

                            {/* Lease Start & End Date */}
                            <Col md={3}>
                                <Label>Lease Start & End</Label>
                                <Row>
                                    <Col md={6}>
                                        <Input
                                            type="date"
                                            name="leaseStart"
                                            value={filterData.leaseStart || ""}
                                            onChange={handleFilterChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input
                                            type="date"
                                            name="leaseEnd"
                                            value={filterData.leaseEnd || ""}
                                            onChange={handleFilterChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            {/* Min Rent */}
                            <Col md={2}>
                                <Label>Min Rent</Label>
                                <Select
                                    name="minRent"
                                    options={minRentOptions}
                                    value={minRentOptions.find(opt => opt.value === filterData.minRent) || null}
                                    onChange={selected => handleFilterChange({ target: { name: "minRent", value: selected?.value } })}
                                    placeholder="Select Min Rent"
                                />
                            </Col>

                            {/* Max Rent */}
                            <Col md={2}>
                                <Label>Max Rent</Label>
                                <Select
                                    name="maxRent"
                                    options={maxRentOptions}
                                    value={maxRentOptions.find(opt => opt.value === filterData.maxRent) || null}
                                    onChange={selected => handleFilterChange({ target: { name: "maxRent", value: selected?.value } })}
                                    placeholder="Select Max Rent"
                                />
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </Collapse>
    );
};

export { FilterSection };
