import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Label } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from '../../../api/axios';

const storageschema = Yup.object().shape({
    layouts: Yup.array().of(
        Yup.object().shape({
            storage_type: Yup.string().required('Storage Type is required'),
            floor_no: Yup.number()
                .integer('Must be a whole number')
                .required('Floor Number is required'),
            slot_start: Yup.number()
                .integer('Must be a whole number')
                .required('Slot Start is required'),
            slot_end: Yup.number()
                .integer('Must be a whole number')
                .required('Slot End is required'),
        })
    ),
});

const defaultValues = {
    layouts: [
        {
            storage_type: '',
            floor_no: '',
            start_slot_alphabet: '',
            slot_start: '',
            end_slot_alphabet: '',
            slot_end: '',
        },
    ],
};

const StorageForm = ({ isOpen, toggle, property, onSubmit }) => {
    const [initialValues, setInitialValues] = useState(defaultValues);
    useEffect(() => {
        if (property?._id) {
            axios
                .get(`/storage_new/storage-layout/find-by-property-id`, { params: { id: property._id, building_name: property?.building_name } })
                .then((response) => {
                    const { data } = response.data;
                
                    if (data && data.layouts) {
                        setInitialValues({ layouts: data.layouts });
                    } else {
                        setInitialValues(defaultValues);
                    }
                })
                
                .catch((error) => {
                    console.error("Error fetching storage layouts:", error);
                    setInitialValues(defaultValues);
                });
        }
    }, [property]);

    const handleSubmit = async (values, { setSubmitting }) => {
        const floorNumbers = values.layouts.map((layout) => layout.floor_no);
        const uniqueFloorNumbers = new Set(floorNumbers);
        
        if (uniqueFloorNumbers.size !== floorNumbers.length) {
            Swal.fire({
                title: 'Error!',
                text: 'Floor numbers must be unique.',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
    
            setSubmitting(false);
            return;
        }
        await onSubmit(values, property?._id, property?.building_name);
        setSubmitting(false);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Setup Storage Layout - {property?.title}</ModalHeader>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={storageschema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <ModalBody>
                            <StorageLayoutForm
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Saving...' : 'Save Layout'}
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

const StorageLayoutForm = ({ values, errors, touched }) => (
    <FieldArray name="layouts">
        {({ push, remove }) => (
            <div>
                {values.layouts.map((_layout, index) => (
                    <div key={index} className="border rounded p-3 mb-3">
                        <div className="row">
                            {renderField(
                                `layouts.${index}.storage_type`,
                                'Storage Type',
                                'select',
                                errors,
                                touched,
                                [
                                    { value: '', label: 'Select Type' },
                                    { value: 'Underground Storage', label: 'Underground Storage' },
                                    { value: 'Outside Storage', label: 'Outside Storage' },
                                ]
                            )}

                            {renderField(
                                `layouts.${index}.floor_no`,
                                'Floor Number',
                                'number',
                                errors,
                                touched
                            )}

                            {renderField(
                                `layouts.${index}.start_slot_alphabet`,
                                'Start Slot Alphabet (Optional)',
                                'text',
                                errors,
                                touched
                            )}

                            {renderField(
                                `layouts.${index}.slot_start`,
                                'Slot Start',
                                'number',
                                errors,
                                touched
                            )}

                            {renderField(
                                `layouts.${index}.end_slot_alphabet`,
                                'End Slot Alphabet (Optional)',
                                'text',
                                errors,
                                touched
                            )}

                            {renderField(
                                `layouts.${index}.slot_end`,
                                'Slot End',
                                'number',
                                errors,
                                touched
                            )}
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button color="danger" onClick={() => remove(index)}>
                                Remove
                            </Button>
                        </div>
                    </div>
                ))}
                <Button
                    color="primary"
                    onClick={() =>
                        push({
                            storage_type: '',
                            floor_no: '',
                            start_slot_alphabet: '',
                            slot_start: '',
                            slot_end: '',
                            end_slot_alphabet: '',
                        })
                    }
                >
                    Add Storage Layout
                </Button>
            </div>
        )}
    </FieldArray>
);

const renderField = (name, label, type, errors, touched, options = []) => {
    const error = name.split('.').reduce((obj, key) => obj?.[key], errors);
    const isTouched = name.split('.').reduce((obj, key) => obj?.[key], touched);

    return (
        <div className={`col-md-6 mb-3`}>
            <Label htmlFor={name} className="form-label">
                {label}
            </Label>
            {type === 'select' ? (
                <Field
                    as="select"
                    name={name}
                    className={`form-control ${error && isTouched ? 'is-invalid' : ''}`}
                >
                    {options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Field>
            ) : (
                <Field
                    type={type}
                    name={name}
                    className={`form-control ${error && isTouched ? 'is-invalid' : ''}`}
                />
            )}
            {error && isTouched && (
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            )}
        </div>
    );
};

export { StorageForm };

