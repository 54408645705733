import React, { useState } from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import {
    Box, Button, Chip, Divider, FormControl, FormControlLabel,
    IconButton, InputLabel, MenuItem, Paper, Select, Switch,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Tooltip, Typography
} from '@mui/material';

import {
    Add as AddIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon,
    Delete as DeleteIcon, VisibilityOffRounded as VisibilityOffRoundedIcon,
    VisibilityRounded as VisibilityRoundedIcon, EditOff as EditOffIcon
} from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from '../../../../../store/actions';
import axios from '../../../../api/axios';

// Modified field types to match your schema
const fieldTypes = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'date', label: 'Date' },
    { value: 'boolean', label: 'Boolean' },
    { value: 'select', label: 'Select' },
    { value: 'email', label: 'Email' },
    { value: 'array', label: 'Array' },
    { value: 'group', label: 'Group' }, // Changed from 'object' to 'group'
];

// Modified validation schema to match your format
const buildValidationSchema = () => {
    const fieldSchema = Yup.object().shape({
        key: Yup.string().required('Field key is required'), // Changed from 'name' to 'key'
        label: Yup.string().required('Field label is required'), // Added label validation
        type: Yup.string().required('Field type is required'),
        required: Yup.boolean(),
        fields: Yup.array().when('type', {
            is: (type) => type === 'group' || type === 'array',
            then: (schema) => schema.of(Yup.lazy(() => fieldSchema)),
            otherwise: (schema) => schema.nullable()
        }),
        options: Yup.array().when('type', {
            is: 'select',
            then: (schema) => schema.of(Yup.string()),
            otherwise: (schema) => schema.nullable()
        }),
        editable: Yup.boolean(),
        deletable: Yup.boolean()
    });

    return Yup.object().shape({
        fields: Yup.array().of(fieldSchema)
    });
};

// Modified to create empty fields in your format
const getEmptyFieldByType = (type, editable = true, deletable = true) => {
    const baseField = {
        key: '',
        label: '',
        type,
        editable,
        deletable,
        required: false
    };

    switch (type) {
        case 'group': return { ...baseField, fields: [] };
        case 'array': return { ...baseField, fields: [] };
        case 'select': return { ...baseField, options: [''] };
        case 'boolean': return baseField;
        default: return baseField;
    }
};

// Key handler to prevent React key issues
const getFieldKey = (field, index) => {
    return `field-${index}-${field.key || 'unnamed'}-${field.type}`;
};

// Modified FieldRenderer to work with your field structure
const FieldRenderer = ({ prefix, field, index, arrayHelpers, level = 0, onTypeChange }) => {
    const fieldPath = `${prefix}.${index}`;

    // Check if field is editable and deletable
    const isEditable = field.editable !== false; // Default to true if not specified
    const isDeletable = field.deletable !== false; // Default to true if not specified

    // Handle field type changes
    const handleTypeChange = (e) => {
        if (!isEditable) return; // Prevent type changes if not editable

        const newType = e.target.value;
        const newField = getEmptyFieldByType(newType, isEditable, isDeletable);

        // Preserve the field key and label and required status
        newField.key = field.key;
        newField.label = field.label;
        newField.required = field.required;

        // Replace the entire field with a new one of the correct type
        arrayHelpers.replace(index, newField);

        if (onTypeChange) {
            onTypeChange(newType);
        }
    };

    const handleMove = (direction) => {
        if (direction === 'up' && index > 0) {
            arrayHelpers.swap(index, index - 1);
        } else if (direction === 'down') {
            arrayHelpers.swap(index, index + 1);
        }
    };

    return (
        <Paper
            elevation={level === 0 ? 2 : 1}
            sx={{
                p: 2,
                mb: 2,
                borderLeft: level > 0 ? `${level * 3}px solid rgba(0, 0, 0, 0.1)` : 'none',
                backgroundColor: level % 2 === 0 ? 'white' : '#f9f9f9',
                borderLeft: !isEditable ? '4px solid #f44336' : (level > 0 ? `${level * 3}px solid rgba(0, 0, 0, 0.1)` : 'none'),
                opacity: !isEditable ? 0.9 : 1
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', flex: 1 }}>
                    Field {index + 1}
                    {field.key && ` (Label - ${field.label}, Key - ${field.key})`}
                    {field.required && (
                        <Tooltip title="This field is required">
                            <Chip
                                size="small"
                                label="Required"
                                color="primary"
                                sx={{ ml: 1, height: 20, fontSize: '0.7rem' }}
                            />
                        </Tooltip>
                    )}
                    {!isEditable && (
                        <Tooltip title="This field is not editable">
                            <EditOffIcon
                                fontSize="small"
                                color="error"
                                sx={{ ml: 1, verticalAlign: 'middle' }}
                            />
                        </Tooltip>
                    )}
                </Typography>

                <Tooltip title="Move Up">
                    <span>
                        <IconButton
                            size="small"
                            onClick={() => handleMove('up')}
                            disabled={index === 0 || !isEditable}
                        >
                            <ArrowUpwardIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title="Move Down">
                    <span>
                        <IconButton
                            size="small"
                            onClick={() => handleMove('down')}
                            disabled={!isEditable}
                        >
                            <ArrowDownwardIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={isDeletable ? "Remove Field" : "This field cannot be deleted"}>
                    <span>
                        <IconButton
                            size="small"
                            color="error"
                            onClick={() => isDeletable && arrayHelpers.remove(index)}
                            disabled={!isDeletable}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'flex-start' }}>
                <Field
                    as={TextField}
                    name={`${fieldPath}.key`}
                    label="Field Key"
                    fullWidth
                    size="small"
                    sx={{ flexBasis: '200px', flexGrow: 1 }}
                    disabled={!isEditable}
                />

                <Field
                    as={TextField}
                    name={`${fieldPath}.label`}
                    label="Field Label"
                    fullWidth
                    size="small"
                    sx={{ flexBasis: '200px', flexGrow: 1 }}
                    disabled={!isEditable}
                />

                <FormControl sx={{ flexBasis: '200px', flexGrow: 1 }}>
                    <InputLabel size="small">Field Type</InputLabel>
                    <Field
                        as={Select}
                        name={`${fieldPath}.type`}
                        label="Field Type"
                        size="small"
                        onChange={handleTypeChange}
                        disabled={!isEditable}
                    >
                        {fieldTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Field>
                </FormControl>

                {field.type === 'boolean' && (
                    <FormControlLabel
                        control={
                            <Field
                                as={Switch}
                                name={`${fieldPath}.defaultValue`}
                                color="primary"
                                disabled={!isEditable}
                            />
                        }
                        label="Default Value"
                    />
                )}

                {/* Add Required Toggle */}
                <FormControlLabel
                    control={
                        <Field
                            as={Switch}
                            name={`${fieldPath}.required`}
                            color="primary"
                            disabled={!isEditable}
                        />
                    }
                    label="Required"
                />

                {/* Only show editable and deletable controls if the field is editable */}
                {isEditable && (
                    <>
                        <FormControlLabel
                            control={
                                <Field
                                    as={Switch}
                                    name={`${fieldPath}.deletable`}
                                    color="primary"
                                    checked={field.deletable !== false}
                                />
                            }
                            label="Deletable"
                        />
                    </>
                )}
            </Box>

            {/* Add placeholder field if available */}
            {field.placeholder !== undefined && (
                <Box sx={{ mt: 2, width: '100%' }}>
                    <Field
                        as={TextField}
                        name={`${fieldPath}.placeholder`}
                        label="Placeholder"
                        fullWidth
                        size="small"
                        disabled={!isEditable}
                    />
                </Box>
            )}

            {/* Render options for select type */}
            {field.type === 'select' && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>Options</Typography>
                    <FieldArray name={`${fieldPath}.options`}>
                        {({ remove: removeOption, push: pushOption }) => (
                            <>
                                {field.options ? (
                                    field.options.map((option, optionIndex) => (
                                        <Box key={`${fieldPath}-option-${optionIndex}`} sx={{ display: 'flex', mt: 1 }}>
                                            <Field
                                                as={TextField}
                                                name={`${fieldPath}.options.${optionIndex}`}
                                                label={`Option ${optionIndex + 1}`}
                                                size="small"
                                                sx={{ mr: 1, flex: 1 }}
                                                disabled={!isEditable}
                                            />
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => isEditable && removeOption(optionIndex)}
                                                disabled={!isEditable || (field.options || []).length <= 1}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No options defined
                                    </Typography>
                                )}
                                {isEditable && (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            if (!field.options) {
                                                arrayHelpers.form.setFieldValue(`${fieldPath}.options`, ['']);
                                            } else {
                                                pushOption('');
                                            }
                                        }}
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        Add Option
                                    </Button>
                                )}
                            </>
                        )}
                    </FieldArray>
                </Box>
            )}

            {/* Render nested fields for array type */}
            {field.type === 'array' && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Array Fields
                        <Chip
                            label={field.fields ? field.fields.length : 0}
                            size="small"
                            sx={{ ml: 1 }}
                            color={(field.fields && field.fields.length > 0) ? "primary" : "default"}
                        />
                    </Typography>

                    <FieldArray name={`${fieldPath}.fields`}>
                        {(nestedArrayHelpers) => (
                            <>
                                {field.fields && field.fields.map((nestedField, nestedIndex) => (
                                    <FieldRenderer
                                        key={getFieldKey(nestedField, nestedIndex)}
                                        prefix={`${fieldPath}.fields`}
                                        field={nestedField}
                                        index={nestedIndex}
                                        arrayHelpers={nestedArrayHelpers}
                                        level={level + 1}
                                    />
                                ))}

                                {isEditable && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            // Ensure we have a fields array before adding to it
                                            if (!field.fields) {
                                                arrayHelpers.form.setFieldValue(`${fieldPath}.fields`, []);
                                                // Use setTimeout to ensure the fields array is created before pushing
                                                setTimeout(() => {
                                                    nestedArrayHelpers.push(getEmptyFieldByType('text', isEditable, isDeletable));
                                                }, 0);
                                            } else {
                                                nestedArrayHelpers.push(getEmptyFieldByType('text', isEditable, isDeletable));
                                            }
                                        }}
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        Add Array Field
                                    </Button>
                                )}
                            </>
                        )}
                    </FieldArray>
                </Box>
            )}

            {/* Render nested fields for group type */}
            {field.type === 'group' && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Group Fields
                        <Chip
                            label={field.fields ? field.fields.length : 0}
                            size="small"
                            sx={{ ml: 1 }}
                            color={(field.fields && field.fields.length > 0) ? "primary" : "default"}
                        />
                    </Typography>

                    <FieldArray name={`${fieldPath}.fields`}>
                        {(nestedArrayHelpers) => (
                            <>
                                {field.fields && field.fields.map((nestedField, nestedIndex) => (
                                    <FieldRenderer
                                        key={getFieldKey(nestedField, nestedIndex)}
                                        prefix={`${fieldPath}.fields`}
                                        field={nestedField}
                                        index={nestedIndex}
                                        arrayHelpers={nestedArrayHelpers}
                                        level={level + 1}
                                    />
                                ))}

                                {isEditable && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            // Ensure we have a fields array before adding to it
                                            if (!field.fields) {
                                                arrayHelpers.form.setFieldValue(`${fieldPath}.fields`, []);
                                                // Use setTimeout to ensure the fields array is created before pushing
                                                setTimeout(() => {
                                                    nestedArrayHelpers.push(getEmptyFieldByType('text', isEditable, isDeletable));
                                                }, 0);
                                            } else {
                                                nestedArrayHelpers.push(getEmptyFieldByType('text', isEditable, isDeletable));
                                            }
                                        }}
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        Add Group Field
                                    </Button>
                                )}
                            </>
                        )}
                    </FieldArray>
                </Box>
            )}
        </Paper>
    );
};

// Form Preview Component modified to match your structure
const FormPreview = ({ schema }) => {
    const [viewMode, setViewMode] = useState("excel");

    const handleToggle = () => {
        setViewMode(viewMode === "excel" ? "json" : "excel");
    };

    return (
        <Box sx={{
            p: 2,
            backgroundColor: "#f9f9f9",
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0,0,0,0.1)'
        }}>
            {/* Header with Toggle Switch */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="primary.main">Preview</Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={viewMode === "excel"}
                            onChange={handleToggle}
                            color="primary"
                            size="small"
                        />
                    }
                    label={viewMode === "excel" ? "Excel View" : "JSON View"}
                    labelPlacement="start"
                />
            </Box>
            {viewMode === "json" ? (
                <Box sx={{ overflow: "auto", maxHeight: "300px", backgroundColor: "#fff", p: 2, borderRadius: 1, boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.05)' }}>
                    <pre style={{ whiteSpace: "pre-wrap", fontFamily: 'monospace', fontSize: '0.875rem' }}>{JSON.stringify(schema, null, 2)}</pre>
                </Box>
            ) : (
                <TableContainer component={Paper} elevation={3} sx={{ maxHeight: "300px", overflowY: "auto" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Key</strong></TableCell>
                                <TableCell><strong>Label</strong></TableCell>
                                <TableCell><strong>Type</strong></TableCell>
                                <TableCell><strong>Required</strong></TableCell>
                                <TableCell><strong>Editable</strong></TableCell>
                                <TableCell><strong>Deletable</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {schema?.fields?.map((field, index) => (
                                <Tooltip title={`${field.key}: ${field.type}`} placement="top" key={index}>
                                    <TableRow sx={{
                                        backgroundColor: !field.editable ? 'rgba(244, 67, 54, 0.05)' : 'inherit'
                                    }}>
                                        <TableCell>{field.key}</TableCell>
                                        <TableCell>{field.label}</TableCell>
                                        <TableCell>{field.type}</TableCell>
                                        <TableCell>
                                            {field.required ? (
                                                <Chip size="small" label="Yes" color="primary" />
                                            ) : (
                                                <Chip size="small" label="No" color="default" />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {field.editable !== false ? (
                                                <Chip size="small" label="Yes" color="success" />
                                            ) : (
                                                <Chip size="small" label="No" color="error" />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {field.deletable !== false ? (
                                                <Chip size="small" label="Yes" color="success" />
                                            ) : (
                                                <Chip size="small" label="No" color="error" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Tooltip>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

const FieldsForm = ({
    property_id,
    building_name = '',
    initialFields = [getEmptyFieldByType('text')],
    onSuccess = () => { },
    isCreatingNew = false,
}) => {
    const [showPreview, setShowPreview] = useState(false);
    const dispatch = useDispatch();
    const isLoadingState = useSelector((state) => state.Account.loading);

    const initialValues = {
        fields: initialFields
    };

    const handleSubmit = async (values) => {
        try {
            dispatch(isLoading(true));
            Swal.fire({
                title: 'Creating MasterSheet...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const layoutResponse = await axios.post(
                `/v2/master_sheet/`,
                {
                    property_id, building_name, fields: values.fields
                }
            );

            if (!layoutResponse.data.success) {
                throw new Error(layoutResponse.data.message || 'Failed to create layout');
            }

            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'MasterSheet Layout has been setup successfully',
                confirmButtonColor: '#3085d6'
            });

            onSuccess(layoutResponse.data.data);

        } catch (error) {
            console.error('Error creating MasterSheet:', error);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.message || error.message || 'An unexpected error occurred',
                confirmButtonColor: '#3085d6'
            });
        } finally {
            dispatch(isLoading(false));
        }
    };

    const validationSchema = buildValidationSchema();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ values, resetForm }) => (
                <Form>
                    <Box sx={{ maxWidth: 1200, mx: 'auto', my: 4 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, alignItems: 'center' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    color: "primary.main",
                                    letterSpacing: 1
                                }}
                            >
                                Rental Property Schema Builder
                            </Typography>
                        
                            <Box>
                                <Tooltip title="Toggle JSON Preview">
                                    <IconButton
                                        color={showPreview ? "primary" : "default"}
                                        onClick={() => setShowPreview(!showPreview)}
                                    >
                                        {showPreview ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                                    </IconButton>
                                </Tooltip>
                                {isCreatingNew && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => resetForm()}
                                    startIcon={<i className="bi bi-arrow-counterclockwise"></i>}
                                    sx={{ textTransform: "none", fontWeight: "bold" }}
                                >
                                    Reset Fields to default values
                                </Button>
                            )}
                            </Box>
                        </Box>

                        {showPreview && (
                            <Box sx={{ mb: 3 }}>
                                <FormPreview schema={values} />
                            </Box>
                        )}

                        <Typography variant="subtitle1" gutterBottom>
                            Configure your property form fields
                        </Typography>

                        <FieldArray name="fields">
                            {(arrayHelpers) => (
                                <Box>
                                    {values.fields.map((field, index) => (
                                        <FieldRenderer
                                            key={getFieldKey(field, index)}
                                            prefix="fields"
                                            field={field}
                                            index={index}
                                            arrayHelpers={arrayHelpers}
                                        />
                                    ))}

                                    <Box sx={{ mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                                        {fieldTypes.map(type => (
                                            <Button
                                                key={type.value}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => arrayHelpers.push(getEmptyFieldByType(type.value))}
                                                startIcon={<AddIcon />}
                                            >
                                                {type.label}
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </FieldArray>

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={() => resetForm()}
                                variant="outlined"
                                color="secondary"
                                size="large"
                            >
                                Reset
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isLoadingState}
                                size="large"
                            >
                                Save Schema
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export { FieldsForm };