
import * as Yup from "yup";
import moment from "moment";
import momenttz from "moment-timezone";
import Swal from "sweetalert2";
import axios from "../api/axios";

export const submitForm = async (values, companyDomain, { setSubmitting, resetForm }) => {
    try {
      const { data } = await axios.post(`/user_appointment/add_slot`, values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
  
      if (data.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Your appointment is booked!',
          icon: 'success',
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            window.location.href = companyDomain;
          }
        });
        
        resetForm();
      } else {
        Swal.fire({
          title: 'Error',
          text: data.message || 'Something went wrong',
          icon: 'error',
          confirmButtonText: 'Try Again'
        });
      }
    } catch (error) {
      console.error("Error booking appointment:", error);
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || "An error occurred. Please try again.",
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  };

export const convertToLocalTime = (utcTime) => {
    const utcDateTime = momenttz.utc(
        `1970/01/01 ${utcTime}`,
        "YYYY/MM/DD HH:mm:ss"
    );
    const localDateTime = utcDateTime.clone().tz(moment.tz.guess());
    return localDateTime.format("h:mm A").toUpperCase().trim();
};

export const convertToValidDate = (dateString) => {
    if (!dateString) return null;

    const [datePart, timePart] = dateString.split(" ");
    if (!timePart) return null;

    const [time, modifier] = timePart.split(" "); // Split time and AM/PM
    let [hours, minutes] = time.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes)) return null;

    // Adjust hours for AM/PM format
    if (modifier === "PM" && hours < 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;

    // Construct ISO Date String
    const formattedDate = `${datePart}T${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00`;

    const dateTimeObject = new Date(formattedDate);
    return isNaN(dateTimeObject.getTime()) ? null : dateTimeObject;
};

export const convertTimeRangeToUTC = (timeRange, sdate) => {
    if (!timeRange || !sdate) return { utcStartTime: undefined, utcEndTime: undefined };

    const [startTime, endTime] = timeRange.split(" - ");
    return [startTime, endTime].map((time) => {
        const dateTimeObject = convertToValidDate(`${sdate} ${time}`);
        return dateTimeObject
            ? { utcString: dateTimeObject.toISOString(), timeInMilliseconds: dateTimeObject.getTime() }
            : undefined;
    });
};

export const initialValues = {
    name: '',
    email: '',
    phone: '',
    other_phone: '',
    reason: '',
    comment: '',
    property: '',
    layout: '',
    day: '',
    appointment_date: '',
    slot: '',
}

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please Enter the name'),
    email: Yup.string().email('Invalid email format').required('Please Enter the email'),
    phone: Yup.string()
        .matches(/^[0-9+]+$/, 'Phone number can only contain numbers and +')
        .min(10, 'Phone number must be at least 10 digits')
        .required('Please enter the phone number'),
    other_phone: Yup.string()
        .matches(/^[0-9+]*$/, 'Phone number can only contain numbers and +')
        .min(10, 'Phone number must be at least 10 digits')
        .optional(),
    reason: Yup.string().required('Please select reason type'),
    comment: Yup.string().optional(),
    property: Yup.string().required('Please select a property'),
    layout: Yup.string().required('Layout is required'),
    appointment_date: Yup.string().required('Please select a appointment date'),
    day: Yup.string().required('Please select a day property'),
    slot: Yup.string().required('Please select event time slot'),
});