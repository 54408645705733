import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Table } from "reactstrap";

const MacrosModal = ({ macros, isOpen, toggle }) => {
    const [selectedLayoutIndex, setSelectedLayoutIndex] = useState(0);

    console.log({ macros, isOpen, toggle })

    if (!macros)
        return null;

    const layouts = macros.layouts_based_on_property_id;
    const selectedLayout = layouts[selectedLayoutIndex];

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Storage Layouts</ModalHeader>
            <ModalBody>
                {/* Layout Selector */}
                <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                    {layouts.map((layout, index) => (
                        <Button
                            key={index}
                            onClick={() => setSelectedLayoutIndex(index)}
                            color={selectedLayoutIndex === index ? "primary" : "secondary"}
                            outline={selectedLayoutIndex !== index}
                        >
                            {layout.layout}
                        </Button>
                    ))}
                </div>

                {/* Selected Layout Details */}
                <h4>{selectedLayout.layout}</h4>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Storage Info</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total Storage</td>
                            <td>{selectedLayout.total_slots}</td>
                        </tr>
                        <tr>
                            <td>Assigned Storage</td>
                            <td>{selectedLayout.allotted_slots}</td>
                        </tr>
                        <tr>
                            <td>Vacant Storage</td>
                            <td>{selectedLayout.available_slots}</td>
                        </tr>
                        <tr>
                            <td>Occupied Storage</td>
                            <td>
                                {Object.entries(selectedLayout.occupied_slots).length > 0 ? (
                                    Object.entries(selectedLayout.occupied_slots).map(([type, slots], idx) => (
                                        <div key={idx}>
                                            <strong>{type}:</strong> {slots.join(", ")}
                                        </div>
                                    ))
                                ) : (
                                    "None"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Vacant Storage #</td>
                            <td>
                                {Object.entries(selectedLayout.vacant_slots).length > 0 ? (
                                    Object.entries(selectedLayout.vacant_slots).map(([type, slots], idx) => (
                                        <div key={idx}>
                                            {slots.join(", ")}
                                        </div>
                                    ))
                                ) : (
                                    "None"
                                )}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    );
};

export { MacrosModal };
