import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import jwt_decode from "jwt-decode";
import { SERVER_URL } from "../ServerLink";
import { useDispatch } from "react-redux";
import moment from "moment";
import FeatherIcon from "feather-icons-react";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Label,
  Form,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  ModalFooter,
  ModalBody,
  Button,
  ModalHeader,
  InputGroup,
  CardTitle,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Switch from "react-switch";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Importing Components
import { toast } from "react-toastify";
import UploadLogo from "../../assets/images/imgSymboleLogo.jpg";
import { isLoading } from "../../store/actions";
import { EditProperty } from "../V2/Property/EditProperty";

const SUITE_TYPES = [
  {
    id: 1,
    title: "Studio Suite",
    value: "Studio Suite",
  },
  {
    id: 2,
    title: "1-Bedroom Suite",
    value: "1-Bedroom Suite",
  },
  {
    id: 3,
    title: "2-Bedroom Suite",
    value: "2-Bedroom Suite",
  },
  {
    id: 4,
    title: "Penthouse Suite",
    value: "Penthouse Suite",
  },
  {
    id: 5,
    title: "Loft Suite",
    value: "Loft Suite",
  },
  {
    id: 6,
    title: "Office Suite",
    value: "Office Suite",
  },
  {
    id: 7,
    title: "Retail Suite",
    value: "Retail Suite",
  },
  {
    id: 8,
    title: "Executive Suite",
    value: "Executive Suite",
  },
  {
    id: 9,
    title: "Medical Suite",
    value: "Medical Suite",
  },
  {
    id: 10,
    title: "Conference Suite",
    value: "Conference Suite",
  },
  {
    id: 11,
    title: "Residential-Commercial Suite",
    value: "Residential-Commercial Suite",
  },
  {
    id: 12,
    title: "Hotel Suite",
    value: "Hotel Suite",
  },
];

const PARKING_TYPES = [
  {
    id: 1,
    title: "UG Parking",
    value: "UG Parking",
  },
  {
    id: 2,
    title: "SS Parking",
    value: "SS Parking",
  },
];
const FLOOR_LIMIT_FROM = 1;
const FLOOR_LIMIT_TO = 100;
const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const STORAGE_TYPES = [
  {
    id: 1,
    title: "UG Storage",
    value: "UG Storage",
  },
  {
    id: 2,
    title: "SS Storage",
    value: "SS Storage",
  },
];

const AddProperty = (props) => {
  const dispatch = useDispatch();
  // USE ROLE
  const decode = jwt_decode(window.localStorage.accessToken);
  const decode2 = jwt_decode(window.localStorage.getItem("accessToken"));
  //ENDPOINTS
  const GET_COMPANIES_URL = "/company";
  const GET_LAYOUT_URL = "/layout/all";
  const ADD_PROPERTY_URL = "/property/add";
  const POST_PROPERTY_LOGO = "/property/upload-logo/";
  const POST_IMAGE_URL = "/property1/img";
  const FILTER_URL = `/property/filter?role=${decode2.role}&domain=${decode2.role == "manager" ? decode2.id : decode2.domain
    }`;
  const GET_SUITE_DETAILS = "/suite/suite-details";
  const GET_PARKING_DETAILS = "/parking/parking-details";
  const GET_STORAGE_DETAILS = "/storage/storage-details";
  const [selectedFiles, setselectedFiles] = useState([]);

  // for populating dropdowns
  const [companyList, setCompanyList] = useState([]);
  const [editData, seteditData] = useState(false);
  //  database
  const [title, setTitle] = useState("");
  const [landload, setLandload] = useState("");
  const [rent, setRent] = useState("");
  const [postal, setPostal] = useState("");
  const [address, setAddress] = useState("");
  const [category, setCategory] = useState([]);
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [totalcompanies, settotalcompanies] = useState(0);
  const [active, setActive] = useState(0);
  const [totalproperties, settotalproperties] = useState([]);
  // TOOD working today
  const [layout, setLayout] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pages, setPages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manager, setManager] = useState([]);
  const [locationn, setlocationn] = useState([]);
  const [company, setCompany] = useState([]);
  const [propertyname, setPropertyname] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [check_title, setcheck_title] = useState(true);
  const [check_landload, setcheck_landload] = useState(true);
  const [suite, setSuite] = useState(false);
  const [parking, setParking] = useState(false);
  const [storage, setStorage] = useState(false);
  const [check_rent, setcheck_rent] = useState(true);
  const [check_postal, setcheck_postal] = useState(true);
  const [check_address, setcheck_address] = useState(true);
  const [check_layout, setcheck_layout] = useState(true);
  const [check_city, setcheck_city] = useState(true);
  const [check_status, setcheck_status] = useState(true);
  const [check_document, setcheck_document] = useState(true);
  const [filterData, setFilterData] = useState(null);
  const [msg_error, setmsg_error] = useState("");
  const [msg_error_landload, setmsg_error_landload] = useState("");
  const [msg_error1, setmsg_error1] = useState("");
  const [msg_error2, setmsg_error2] = useState("");
  const [msg_error3, setmsg_error3] = useState("");
  const [msg_error4, setmsg_error4] = useState("");
  const [rent_error, setrent_error] = useState("");
  const [postal_error, setpostal_error] = useState("");
  const [address_error, setaddress_error] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteDialoge, setShowDeleteDialoge] = useState(false);
  const [modal_largee2, setmodal_largee2] = useState(false);
  const [logoIsOpen, setLogoIsOpen] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState("");
  const [updateLogoUrl, setUpdateLogoUrl] = useState(null);

  const [propertyID, setPropertyID] = useState("");
  const [selectPropertyManager, setSelectPropertyManager] = useState(null);
  const [selectPropertyName, setSelectPropertyName] = useState(null);
  const [page, setPage] = useState();
  const [stats, setStats] = useState({
    Total: 0,
    Active: 0,
    Inactive: 0,
  });
  const [startPage, setStartPage] = useState(1);
  const pageTotal = Math.ceil(stats.Total / pageLimit);

  const [suiteType, setSuiteType] = useState(SUITE_TYPES[0].value);
  const [suiteData, setSuiteData] = useState([]);
  const [isSuiteAlphabet, setIsSuiteAlphabet] = useState([]);

  const [parkingType, setParkingType] = useState(PARKING_TYPES[0].value);
  const [parkingData, setParkingData] = useState([]);
  const [isAlphabet, setIsAlphabet] = useState([]);

  const [storageType, setStorageType] = useState(STORAGE_TYPES[0].value);
  const [storageData, setStorageData] = useState([]);
  const [isStorageAlphabet, setIsStorageAlphabet] = useState([]);

  const isSuiteDataValid = () => {
    let _suiteData = suiteData.slice();
    if (_suiteData.length > 0) {
      let validArray = [];
      _suiteData.forEach((item, index) => {
        item.floors.forEach((_item, _index) => {
          if (_item.from && _item.to) {
            if (isSuiteAlphabet[index][_index]) {
              validArray.push(!!_item.alphabet);
            } else {
              validArray.push(true);
            }
          } else {
            validArray.push(false);
          }
        });
      });
      return !validArray.includes(false);
    }
    return true;
  };

  const isPrarkingDataValid = () => {
    let _parkingData = parkingData.slice();
    if (_parkingData.length > 0) {
      let validArray = [];
      _parkingData.map((item, index) => {
        item.floors.map((_item, _index) => {
          if (_item.from && _item.to) {
            if (isAlphabet[index][_index]) {
              if (_item.alphabet) {
                validArray.push(true);
              } else {
                validArray.push(false);
              }
            } else {
              validArray.push(true);
            }
          } else {
            validArray.push(false);
          }
        });
      });
      if (validArray.findIndex((item) => item == false) !== -1) {
        return false;
      }
      return true;
    }
    return true;
  };

  const isStorageDataValid = () => {
    let _parkingData = storageData.slice();
    if (_parkingData.length > 0) {
      let validArray = [];
      _parkingData.map((item, index) => {
        item.floors.map((_item, _index) => {
          if (_item.from && _item.to) {
            if (isStorageAlphabet[index][_index]) {
              if (_item.alphabet) {
                validArray.push(true);
              } else {
                validArray.push(false);
              }
            } else {
              validArray.push(true);
            }
          } else {
            validArray.push(false);
          }
        });
      });
      if (validArray.findIndex((item) => item == false) !== -1) {
        return false;
      }
      return true;
    }
    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedLogo(file);
    const previewURL = URL.createObjectURL(file);
    setPreviewLogoUrl(previewURL);
  };

  const handleUploadLogo = async () => {
    try {
      if (!selectedLogo) {
        return;
      }
      const formData = new FormData();
      formData.append("logoFile", selectedLogo);

      const responce = await axios.post(
        POST_PROPERTY_LOGO + propertyID,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (responce.data.status === 200) {
        toast.success("Logo Upload Successfully.");
        setSelectedLogo(null);
        setPropertyID("");
        setLogoIsOpen(false);
        setUpdateLogoUrl("");
        setPreviewLogoUrl("");
        getProperties();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  function removeBodyCss2() {
    document.body.classList.add("no_padding");
  }

  function tog_largee2() {
    setmodal_largee2(!modal_large2);
    removeBodyCss2();
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (page > startPage + pageLimit - 1 || page < startPage) {
      setStartPage(Math.max(1, page - pageLimit / 2));
    }
  };

  const dataFilter = async () => {
    let temp = await axios.get(FILTER_URL);
    // if(decode2.role != "admin"){
    setCompany(temp?.data?.properties[0]?.company);
    setCategories(temp?.data?.properties[0]?.category);
    setManager(temp?.data?.properties[0]?.manager);
    setlocationn(temp?.data?.properties[0]?.location);
    setPropertyname(temp?.data?.properties[0]?.property_name);
    // }
  };
  const PROPERTIES_URL = `/property/list?page=${currentPage}&limit=10`;
  const decode1 = jwt_decode(window.localStorage.getItem("accessToken"));

  useEffect(() => {
    dataFilter();
    let total_pages = totalcompanies / pageLimit;
    if (totalcompanies % pageLimit == 0) {
      setPages(Array.from(Array(Math.round(total_pages)).keys()));
    } else {
      setPages(Array.from(Array(parseInt(total_pages) + 1).keys()));
    }
  }, [totalcompanies]);

  useEffect(() => {
    if (props.location?.state?.name !== undefined) {
      getProperties(0, { manager: props.location?.state?.name });
      window.history.replaceState(null, "");
    } else {
      getProperties();
    }
    //setPages(Array.from(Array(Math.round(totalcompanies / pageLimit)).keys()))
    let total_pages = totalcompanies / pageLimit;
    if (totalcompanies % pageLimit == 0) {
      setPages(Array.from(Array(Math.round(total_pages)).keys()));
    } else {
      setPages(Array.from(Array(parseInt(total_pages) + 1).keys()));
    }
  }, [currentPage, props.location?.state?.name]);

  // under work
  async function getCompanies() {
    try {
      const response = await axios.get(GET_COMPANIES_URL);
      setCompanyList(response.data.companies);
    } catch (error) {
      // TODO proper error message
      console.log(error);
    }
  }
  async function getLayout(url, data) {
    try {
      const response = await axios.post(url, data);
      //setLayout(response.data.layouts[0].layouts);
      response.data.layouts.forEach((element) => {
        setLayout((current) => [
          ...current,
          { label: element.layoutName, value: element._id },
        ]);
        // setLayout.push({ label: element.name, value: element._id });
      });
    } catch (error) {
      // TODO proper error message
      console.log(error);
    }
  }

  const handleCloseModal = () => {
    tog_large();

    setcheck_title(true);
    setcheck_rent(true);
    setcheck_layout(true);
    setcheck_city(true);
    setcheck_status(true);
    setcheck_postal(true);
    // setcheck_document(true);
    seteditData(false);
    setTitle("");
    setLocation("");
    setStatus("");
    setRent("");
    setPostal("");
    setCategory([]);
    setselectedFiles([]);
    setPropertyID("");
  };

  async function addProperty(event) {
    event.preventDefault();
    // Getting company information from local storage

    if (!title) {
      setcheck_title(false);
      setmsg_error("Property Name is required!");
    } else {
      setcheck_title(true);
    }

    if (title) {
      if (!title.match(/^[a-zA-Z0-9 ]+$/)) {
        setcheck_title(false);
        setmsg_error("Only Letters And Numbers Are Allowed");
      } else {
        setcheck_title(true);
      }
    }

    if (!rent) {
      setcheck_rent(false);
      setrent_error("Rent is required!");
    } else {
      if (!rent.match(/^[0-9 ]+$/)) {
        setcheck_rent(false);
        setrent_error("Only Numbers Are Allowed");
      } else {
        setcheck_rent(true);
      }
    }
    if (!postal) {
      setcheck_postal(false);
      setpostal_error("Postal code is required!");
    } else {
      setcheck_postal(true);
    }

    if (!address) {
      setcheck_address(false);
      setaddress_error("Address is required!");
    } else {
      setcheck_address(true);
    }

    if (!location) {
      setcheck_city(false);
      setmsg_error3("City required!");
    } else {
      setcheck_city(true);
    }

    if (location) {
      if (!location.match(/^[a-zA-Z0-9 ]+$/)) {
        setcheck_city(false);
        setmsg_error3("Only letters and numbers are allowed!");
      } else {
        setcheck_city(true);
      }
    }

    if (category.length <= 0) {
      setcheck_layout(false);
      setmsg_error2("Please select a Layout!");
    } else {
      setcheck_layout(true);
    }

    if (!status) {
      setcheck_status(false);
      setmsg_error4("Please select a Status!");
    } else {
      setcheck_status(true);
    }

    // if (!editData && selectedFiles.length <= 0) {
    //   setcheck_document(false);
    //   setmsg_error1("Select at least One file");
    // } else {
    //   setcheck_document(true);
    // }
    if (
      title &&
      check_title &&
      category.length > 0 &&
      check_layout &&
      location &&
      check_city &&
      status &&
      check_rent &&
      check_status &&
      postal &&
      check_postal &&
      check_address &&
      address &&
      check_landload
    ) {
      const decode = jwt_decode(window.localStorage.getItem("accessToken"));
      if (!editData) {
        try {
          if (!isPrarkingDataValid()) {
            return;
          }
          if (!isStorageDataValid()) {
            return;
          }
          const saveSpinner = document.getElementById("saveSpinner");
          const failedSaveErrorIcon = document.getElementById(
            "failedSaveErrorIcon"
          );
          const nexticon = document.getElementById("nexticon");

          saveSpinner.style.display = "inline-block";
          failedSaveErrorIcon.style.display = "none";
          nexticon.style.display = "none";

          const response = await axios.post(ADD_PROPERTY_URL, {
            title: title,
            companyID: decode.id,
            company: decode.company,
            companyDomain: decode.domain,
            category: category?.map((category) => category.value),
            owner: decode.firstname + " " + decode.lastname,
            rent: rent,
            postal: postal,
            address: address,
            location: location,
            status: status,
            manager: [],
            documents: [],
            parkingData: parkingData,
            storageData: storageData,
            landlord: landload,
          });
          if (response) {
            toast.success(response.data.messsage);
            await uploadDocuments(response.data.id);
            handleCloseModal();
            resetSuiteForm();
            resetParkingForm();
            resetStorageForm();
            getProperties();

            saveSpinner.style.display = "none";
            failedSaveErrorIcon.style.display = "none";
            nexticon.style.display = "inline-block";
          }
        } catch (error) {
          console.log(error); // TODO Proper Error
        }
      } else {
        editProperty();
      }
    }
  }
  const EDIT_PROPERTY_URL = `/property/update/${propertyID}`;

  const editProperty = async () => {
    // EDIT_PROPERTY_URL
    try {
      if (!isSuiteDataValid()) {
        return;
      }
      if (!isPrarkingDataValid()) {
        return;
      }
      if (!isStorageDataValid()) {
        return;
      }
      setLoading(true);

      const res = await axios.post(EDIT_PROPERTY_URL, {
        title: title,
        companyID: decode.id,
        company: decode.company,
        companyDomain: decode.domain,
        category: category.map((category) => category.value),
        owner: decode.firstname + " " + decode.lastname,
        rent_amount: rent,
        postal_code: postal,
        address: address,
        location: location,
        status: status,
        documents: [],
        suiteData: suiteData,
        parkingData: parkingData,
        storageData: storageData,
        landlord: landload,
      });

      if (res) {
        selectedFiles?.length > 0 && (await uploadDocuments(res.data.id));
        toast.success(res.data.message);
        resetParkingForm();
        resetStorageForm();
        handleCloseModal();
        getProperties();

        setLoading(false);
      }
    } catch (e) {
      console.log("error", e.message);
      setLoading(false);
    }
  };

  const optionMulti = [
    { label: "Manager", value: "Manager" },
    { label: "Manager 1", value: "Manager 1" },
    { label: "Manager 2", value: "Manager 2" },
  ];

  const [modal_large, setmodal_large] = useState(false);
  const [modal_large1, setmodal_large1] = useState(false);
  const [modal_large2, setmodal_large2] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large1() {
    setmodal_large1(!modal_large1);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }

  const [col5, setcol5] = useState(false);
  const t_col5 = () => {
    setcol5(!col5);
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (editData?.document?.length > 0) {
    }
    setselectedFiles(files);
    setcheck_document(true);
    setmsg_error1("");
  }
  useState(async () => {
    if (decode.role === "company") {
      await getLayout(GET_LAYOUT_URL, {
        companyID: decode.id,
      });
    }
  });
  /**
   * Formats the size
   */

  const filterDataHandle = (e, name, data) => {
    let newData = { ...filterData };
    if (name == "title") {
      newData[name] = data;
    } else if (name == "company") {
      newData[name] = data;
    } else if (name == "location") {
      newData[name] = data;
    } else if (name == "category") {
      newData[name] = data;
    } else if (name == "manager") {
      newData[name] = data;
    } else if (name == "status") {
      newData[name] = data;
    } else {
      newData[name] = " ";
    }
    setFilterData(newData);
  };
  const onchangetext = (e, name) => {
    let newData = { ...filterData };
    // let From = new Date(startDate).getTime() / 1000
    // let To = new Date(endDate).getTime() / 1000
    if (name === "propertyId") {
      newData[name] = e.target.value;
    } else if (name === "To") {
      newData["dateRange"] = `${startDate}=${e.target.value}`;
    } else {
      newData[name] = "";
    }
    setFilterData(newData);
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async function uploadDocuments(val) {
    const formData = new FormData();
    var i = 0;
    while (i < selectedFiles.length) {
      formData.append(`file`, selectedFiles[i]);
      i++;
    }
    const file = formData.get("file"); // Assuming the form data is already populated with the file

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        id: `${val}`,
      },
    };

    try {
      const response = await axios.post(
        "/property/upload-documents",
        formData,
        config
      );
    } catch (error) { }
  }

  const deleteDocument = (index) => {
    setselectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  const getProperties = async (sorting, custom) => {
    if (filterData !== null) {
      setCurrentPage(1);
    }
    let data = {
      role: decode1.role,
      domain: decode1.domain,
      managerID: decode1.id,
      filter: custom ? custom : filterData,
      managerID: decode1.role === "manager" ? decode1.id : "",
      sort: sorting,
    };
    try {
      dispatch(isLoading(true));
      const response = await axios.post(PROPERTIES_URL, data);
      if (response.data.results.properties) {
        const temp = response.data.results.properties;
        settotalproperties(temp);
        setPage(
          response.data.results.previous
            ? response.data.results.previous.page + 1
            : 1
        );
        settotalcompanies(response.data.totalProperties);
        setActive(response.data.totalActiveProperties);
        setStats((prev) => ({
          ...prev,
          Total: response.data.total.Total || 0,
          Active: response.data.total.Active || 0,
          Inactive: response.data.total.Inactive || 0,
        }));
        dispatch(isLoading(false));
      }
    } catch (error) {
      console.log(error);
      dispatch(isLoading(false));
    }
  };

  const handleSorting = (e) => {
    let value = [1, -1];
    let newData = {};
    if (e.target.value == 1) {
      newData["title"] = value[0];
    } else if (e.target.value == 2) {
      newData["title"] = value[1];
    } else if (e.target.value == 3) {
      newData["createdAt"] = value[1];
    } else if (e.target.value == 4) {
      newData["createdAt"] = value[0];
    } else if (e.target.value == 5) {
      newData["modifiedAt"] = value[1];
    } else if (e.target.value == 6) {
      newData["modifiedAt"] = value[0];
    } else {
      newData[" "] = " ";
    }

    getProperties(newData);
  };
  async function deleteProperty() {
    try {
      const response = await axios.delete(
        `/property/delete?userID=${decode2.id}&propertyID=${propertyID}`
      );
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setPropertyID("");
        getProperties();
        setShowDeleteDialoge(false);
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      // visible error
      console.log(error);
    }
  }

  let number = 0;
  let number1 = page > 1 ? (page - 1) * 10 : 0;

  // Parking Functionality
  const handleOnSelectParkingType = (event) => {
    const { value } = event.target;
    setParkingType(value);
  };

  const handleAddParkingType = () => {
    let _parkingData = parkingData.slice();
    if (
      _parkingData.length <= 2 &&
      _parkingData.findIndex((doc) => doc.floorType == parkingType) == -1
    ) {
      _parkingData.push({
        floorType: parkingType,
        floorCount: FLOOR_LIMIT_FROM,
        floors: [
          {
            floor: "1 Floor",
            from: "",
            to: "",
          },
        ],
      });
      setParkingData(_parkingData);
      let _isAlphabet = isAlphabet.slice();
      _isAlphabet.push([false]);
      setIsAlphabet(_isAlphabet);
    }
  };

  const handleRemoveParkingType = (index) => {
    let _parkingData = parkingData.slice();
    _parkingData.splice(index, 1);
    setParkingData(_parkingData);
    let _isAlphabet = isAlphabet.slice();
    _isAlphabet.splice(index, 1);
    setIsAlphabet(_isAlphabet);
  };

  const handleIncreaseFloor = (index) => {
    let _parkingData = parkingData.slice();
    if (_parkingData.length > 0) {
      let _floorCount = _parkingData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM && _floorCount <= FLOOR_LIMIT_TO);
      {
        _floorCount++;
        const _floorDetail = _parkingData[index].floors.slice();
        _floorDetail.push({
          floor: `${_floorCount} Floor`,
          from: "",
          to: "",
        });
        _parkingData[index].floorCount = _floorCount;
        _parkingData[index].floors = _floorDetail;
        setParkingData(_parkingData);
        let _isAlphabet = isAlphabet.slice();
        _isAlphabet[index].push(false);
        setIsAlphabet(_isAlphabet);
      }
    }
  };

  const handleDecreaseFloor = (index) => {
    let _parkingData = parkingData.slice();
    if (_parkingData.length > 0) {
      let _floorCount = _parkingData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM);
      {
        _floorCount--;
        const _floorDetail = _parkingData[index].floors.slice();
        _floorDetail.pop();
        _parkingData[index].floorCount = _floorCount;
        _parkingData[index].floors = _floorDetail;
        setParkingData(_parkingData);
        let _isAlphabet = isAlphabet.slice();
        _isAlphabet[index].pop();
        setIsAlphabet(_isAlphabet);
      }
    }
  };

  const handleOnChnageOnFloor = (event, index, _index) => {
    const { name, value } = event.target;
    let _parkingData = parkingData.slice();
    if (_parkingData.length > 0) {
      const _floorDetail = _parkingData[index].floors.slice();
      _floorDetail[_index][name] = value;
      _parkingData[index].floors = _floorDetail;
      setParkingData(_parkingData);
    }
  };

  const resetParkingForm = () => {
    setParking(false);
    setParkingData([]);
    setIsAlphabet([]);
  };

  const getParkingDetails = async (id) => {
    try {
      const response = await axios.get(`${GET_PARKING_DETAILS}?id=${id}`);
      if (
        response.data.data &&
        response.data.data.parkingData &&
        response.data.data.parkingData.length
      ) {
        const _result = response.data.data.parkingData;
        setParking(true);
        setParkingData(_result);
        const _isAlphabet = _result.map((item) => {
          return item.floors.map((_item) => {
            if (_item.alphabet != "") {
              return true;
            }
            return false;
          });
        });
        setIsAlphabet(_isAlphabet);
      } else {
        resetParkingForm();
      }
    } catch (error) { }
  };

  // Suite Functionality
  const handleOnSelectSuiteType = (event) => {
    const { value } = event.target;
    setSuiteType(value);
  };

  const handleAddSuiteType = () => {
    let _suiteData = suiteData.slice();
    if (
      _suiteData.length <= 2 &&
      _suiteData.findIndex((doc) => doc.floorType == suiteType) == -1
    ) {
      _suiteData.push({
        floorType: suiteType,
        floorCount: FLOOR_LIMIT_FROM,
        floors: [
          {
            floor: "1 Floor",
            from: "",
            to: "",
          },
        ],
      });
      setSuiteData(_suiteData);
      let _isAlphabet = isSuiteAlphabet.slice();
      _isAlphabet.push([false]);
      setIsSuiteAlphabet(_isAlphabet);
    }
  };

  const handleRemoveSuiteType = (index) => {
    let _suiteData = suiteData.slice();
    _suiteData.splice(index, 1);
    setSuiteData(_suiteData);

    let _isAlphabet = isSuiteAlphabet.slice();
    _isAlphabet.splice(index, 1);
    setIsSuiteAlphabet(_isAlphabet);
  };

  const handleIncreaseSuiteFloor = (index) => {
    let _suiteData = suiteData.slice();
    if (_suiteData.length > 0) {
      let _floorCount = _suiteData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM && _floorCount <= FLOOR_LIMIT_TO) {
        _floorCount++;
        const _floorDetail = _suiteData[index].floors.slice();
        _floorDetail.push({
          floor: `${_floorCount} Floor`,
          from: "",
          to: "",
        });
        _suiteData[index].floorCount = _floorCount;
        _suiteData[index].floors = _floorDetail;
        setSuiteData(_suiteData);

        let _isAlphabet = isSuiteAlphabet.slice();
        _isAlphabet[index].push(false);
        setIsSuiteAlphabet(_isAlphabet);
      }
    }
  };

  const handleDecreaseSuiteFloor = (index) => {
    let _suiteData = suiteData.slice();
    if (_suiteData.length > 0) {
      let _floorCount = _suiteData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM) {
        _floorCount--;
        const _floorDetail = _suiteData[index].floors.slice();
        _floorDetail.pop();
        _suiteData[index].floorCount = _floorCount;
        _suiteData[index].floors = _floorDetail;
        setSuiteData(_suiteData);

        let _isAlphabet = isSuiteAlphabet.slice();
        _isAlphabet[index].pop();
        setIsSuiteAlphabet(_isAlphabet);
      }
    }
  };

  const handleOnSuiteChangeOnFloor = (event, index, _index) => {
    const { name, value } = event.target;
    let _suiteData = suiteData.slice();
    if (_suiteData.length > 0) {
      const _floorDetail = _suiteData[index].floors.slice();
      _floorDetail[_index][name] = value;
      _suiteData[index].floors = _floorDetail;
      setSuiteData(_suiteData);
    }
  };

  const resetSuiteForm = () => {
    setSuiteType(SUITE_TYPES[0].value);
    setSuiteData([]);
    setIsSuiteAlphabet([]);
  };

  const getSuiteDetails = async (id) => {
    try {
      const response = await axios.get(`${GET_SUITE_DETAILS}?id=${id}`);
      if (
        response.data.data &&
        response.data.data.suiteData &&
        response.data.data.suiteData.length
      ) {
        const _result = response.data.data.suiteData;
        setSuiteType(SUITE_TYPES[0].value);
        setSuiteData(_result);
        const _isAlphabet = _result.map((item) => {
          return item.floors.map((_item) => {
            if (_item.alphabet != "") {
              return true;
            }
            return false;
          });
        });
        setIsSuiteAlphabet(_isAlphabet);
      } else {
        resetSuiteForm();
      }
    } catch (error) { }
  };


  // Storage Functionality

  const handleOnSelectStorageType = (event) => {
    const { value } = event.target;
    setStorageType(value);
  };

  const handleAddStorageType = () => {
    let _parkingData = storageData.slice();
    if (
      _parkingData.length <= 2 &&
      _parkingData.findIndex((doc) => doc.floorType == storageType) == -1
    ) {
      _parkingData.push({
        floorType: storageType,
        floorCount: FLOOR_LIMIT_FROM,
        floors: [
          {
            floor: "1 Floor",
            from: "",
            to: "",
          },
        ],
      });
      setStorageData(_parkingData);
      let _isAlphabet = isStorageAlphabet.slice();
      _isAlphabet.push([false]);
      setIsStorageAlphabet(_isAlphabet);
    }
  };

  const handleRemoveStorageType = (index) => {
    let _parkingData = storageData.slice();
    _parkingData.splice(index, 1);
    setStorageData(_parkingData);
    let _isAlphabet = isStorageAlphabet.slice();
    _isAlphabet.splice(index, 1);
    setIsStorageAlphabet(_isAlphabet);
  };

  const handleIncreaseStorageFloor = (index) => {
    let _parkingData = storageData.slice();
    if (_parkingData.length > 0) {
      let _floorCount = _parkingData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM && _floorCount <= FLOOR_LIMIT_TO);
      {
        _floorCount++;
        const _floorDetail = _parkingData[index].floors.slice();
        _floorDetail.push({
          floor: `${_floorCount} Floor`,
          from: "",
          to: "",
        });
        _parkingData[index].floorCount = _floorCount;
        _parkingData[index].floors = _floorDetail;
        setStorageData(_parkingData);
        let _isAlphabet = isStorageAlphabet.slice();
        _isAlphabet[index].push(false);
        setIsStorageAlphabet(_isAlphabet);
      }
    }
  };

  const handleDecreaseStorageFloor = (index) => {
    let _parkingData = storageData.slice();
    if (_parkingData.length > 0) {
      let _floorCount = _parkingData[index].floorCount;
      if (_floorCount >= FLOOR_LIMIT_FROM);
      {
        _floorCount--;
        const _floorDetail = _parkingData[index].floors.slice();
        _floorDetail.pop();
        _parkingData[index].floorCount = _floorCount;
        _parkingData[index].floors = _floorDetail;
        setStorageData(_parkingData);
        let _isAlphabet = isStorageAlphabet.slice();
        _isAlphabet[index].pop();
        setIsStorageAlphabet(_isAlphabet);
      }
    }
  };

  const handleOnStorageChangeOnFloor = (event, index, _index) => {
    const { name, value } = event.target;
    let _parkingData = storageData.slice();
    if (_parkingData.length > 0) {
      const _floorDetail = _parkingData[index].floors.slice();
      _floorDetail[_index][name] = value;
      _parkingData[index].floors = _floorDetail;
      setStorageData(_parkingData);
    }
  };

  const resetStorageForm = () => {
    setStorage(false);
    setStorageData([]);
    setIsStorageAlphabet([]);
  };

  const getStorageDetails = async (id) => {
    try {
      const response = await axios.get(`${GET_STORAGE_DETAILS}?id=${id}`);
      if (
        response.data.data &&
        response.data.data.storageData &&
        response.data.data.storageData.length
      ) {
        const _result = response.data.data.storageData;
        setStorage(true);
        setStorageData(_result);
        const _isAlphabet = _result.map((item) => {
          return item.floors.map((_item) => {
            if (_item.alphabet != "") {
              return true;
            }
            return false;
          });
        });
        setIsStorageAlphabet(_isAlphabet);
      } else {
        resetStorageForm();
      }
    } catch (error) { }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Rentdigicare | Property</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Property" />
          <Row>
            <Col xl={12}>
              <Card>
                {
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col-md-6 d-flex">
                        <div className="mb mx-2">
                          <h5 className="card-title">
                            Total
                            <span className="text-muted fw-normal ms-1">
                              ({stats?.Total})
                            </span>
                          </h5>
                        </div>
                        <div className="mb mx-2">
                          <h5 className="card-title">
                            Active
                            <span className="text-muted fw-normal ms-1">
                              ({stats?.Active})
                            </span>
                          </h5>
                        </div>
                        <div className="mb mx-2">
                          <h5 className="card-title">
                            Inactive
                            <span className="text-muted fw-normal ms-1">
                              ({stats?.Inactive})
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex flex-wrap align-items-center justify-content-end">
                        <div className="mb">
                          <button
                            onClick={t_col5}
                            className="btn btn-primary mo-mb-2 mr-10"
                            type="button"
                            style={{ cursor: "pointer" }}
                          >
                            Filters
                          </button>
                          {!(
                            decode.role === "manager" || decode.role === "admin"
                          ) ? (
                            <>
                              {/* <button
                          onClick={t_col5}
                          className="btn btn-primary mo-mb-2 mr-10"
                          type="button"
                          style={{ cursor: "pointer" }}
                        >
                          Filters
                        </button>   */}

                              <button
                                type="button"
                                onClick={async () => {
                                  tog_large();
                                  await getCompanies();
                                  resetParkingForm();
                                  resetStorageForm();
                                  setLandload("");
                                }}
                                className="btn btn-light "
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                              >
                                <i className="bx bx-plus me-1"></i> Add Property
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Collapse className="mt-4" isOpen={col5}>
                          <Card>
                            <CardBody>
                              <div className="filter-sec">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    getProperties();
                                  }}
                                >
                                  <div class="row align-items-center">
                                    {decode2.role === "admin" ? (
                                      <div className="col-md-4">
                                        <div className="mb-3">
                                          <Label className="form-Label">
                                            Company Name
                                          </Label>
                                          <select
                                            className="form-select"
                                            onChange={(e) =>
                                              filterDataHandle(
                                                e,
                                                "company",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select One</option>
                                            {company &&
                                              company.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.id}>
                                                      {item.name}
                                                    </option>
                                                  </>
                                                );
                                              })}
                                          </select>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label className="form-Label">
                                          Property Name
                                        </Label>
                                        <Select
                                          value={selectPropertyName}
                                          options={propertyname?.map((item) => {
                                            return {
                                              value: item.title,
                                              label: item.title,
                                            };
                                          })}
                                          placeholder="Search By Name"
                                          onChange={(e) => {
                                            setSelectPropertyName(e);
                                            filterDataHandle(
                                              e,
                                              "title",
                                              e.value
                                            );
                                          }}
                                        />
                                        {/* <select
                                          className="form-select"
                                          onChange={(e) =>
                                            filterDataHandle(
                                              e,
                                              "title",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select One</option>
                                          {propertyname &&
                                            propertyname.map((item) => {
                                              return (
                                                <>
                                                  <option value={item.title}>
                                                    {item.title}
                                                  </option>
                                                </>
                                              );
                                            })}
                                        </select> */}
                                      </div>
                                    </div>
                                    {decode2.role == "manager" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <div className="col-md-4">
                                          <div className="mb-3">
                                            <Label className="form-Label">
                                              Property Manager
                                            </Label>
                                            <Select
                                              value={selectPropertyManager}
                                              options={
                                                manager?.map((item) => {
                                                  return {
                                                    value: item._id,
                                                    label:
                                                      item.firstname +
                                                      item.lastname,
                                                  };
                                                }) || []
                                              }
                                              placeholder="Search By Name"
                                              onChange={(e) => {
                                                setSelectPropertyManager(e);
                                                filterDataHandle(
                                                  e,
                                                  "manager",
                                                  e.value
                                                );
                                              }}
                                            />
                                            {/* <select
                                              className="form-select"
                                              onChange={(e) =>
                                                filterDataHandle(
                                                  e,
                                                  "manager",
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select One
                                              </option>
                                              {manager &&
                                                manager.map((item) => {
                                                  return (
                                                    <>
                                                      <option value={item._id}>
                                                        {item.firstname +
                                                          item.lastname}
                                                      </option>
                                                    </>
                                                  );
                                                })}
                                            </select> */}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label className="form-Label">
                                          City
                                        </Label>
                                        <select
                                          className="form-select"
                                          onChange={(e) =>
                                            filterDataHandle(
                                              e,
                                              "location",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select One</option>
                                          {locationn &&
                                            locationn.map((item) => {
                                              return (
                                                <>
                                                  <option value={item.location}>
                                                    {item.location}
                                                  </option>
                                                </>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label className="form-Label">
                                          Status
                                        </Label>
                                        <select
                                          className="form-select"
                                          onChange={(e) =>
                                            filterDataHandle(
                                              e,
                                              "status",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select One</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">
                                            Inactive
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label className="form-Label">
                                          Layout Type
                                        </Label>
                                        <select
                                          className="form-select"
                                          onChange={(e) =>
                                            filterDataHandle(
                                              e,
                                              "category",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">Select One</option>

                                          {categories &&
                                            categories.map((item) => {
                                              return (
                                                <>
                                                  <option value={item._id}>
                                                    {item.layoutName}
                                                  </option>
                                                </>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label
                                          className="form-label"
                                          htmlFor="formrow-phone-input"
                                        >
                                          Property ID
                                        </Label>
                                        <Input
                                          type="text"
                                          placeholder="Enter a property ID"
                                          className="form-control"
                                          onChange={(e) =>
                                            onchangetext(e, "propertyId")
                                          }
                                          id="formrow-phone-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="example-date-input"
                                          className="form-Label"
                                        >
                                          From
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="date"
                                          onChange={(e) => {
                                            setstartDate(e.target.value);
                                          }}
                                          //   defaultValue={neew}
                                          id="example-date-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="example-date-input"
                                          className="form-Label"
                                        >
                                          To
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="date"
                                          onChange={(e) => {
                                            onchangetext(e, "To");
                                          }}
                                          //  defaultValue={FromFilter}
                                          id="example-date-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 searchClear-btns justify-content-end d-flex">
                                      <div className="srch-btn">
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                        >
                                          Search
                                        </button>
                                      </div>
                                      <div className="srch-btn">
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={() =>
                                            window.location.reload()
                                          }
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                      </div>
                    </div>
                  </CardHeader>
                }

                <CardBody>
                  <div className="table-responsive">
                    <div>
                      <select
                        onChange={(e) => handleSorting(e)}
                        style={{
                          borderRadius: "4px",
                          marginBottom: "10px",
                          borderColor: "#d1d1d5",
                        }}
                      >
                        <option>Sorting </option>
                        <option value={1}>A-Z</option>
                        <option value={2}>Z-A</option>
                        <option value={3}>Created Latest</option>
                        <option value={4}>Created Oldest</option>
                        <option value={5}>Modified Latest</option>
                        <option value={6}>Modified Oldest</option>
                      </select>
                    </div>
                    <Table className="table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Property</th>
                          {/* <th scope="col">Property Name</th> */}
                          {decode2.role !== "company" && (
                            <th scope="col">Company</th>
                          )}
                          <th scope="col">Layout</th>
                          {decode2.role !== "company" && (
                            <th scope="col">Owner</th>
                          )}
                          <th scope="col">Manager</th>
                          <th scope="col">Landlord</th>
                          <th scope="col">City</th>
                          <th scope="col">Rent</th>
                          <th scope="col">Docs</th>
                          <th scope="col">Logo</th>
                          <th scope="col">Status</th>
                          <th scope="col">Created/Modified </th>
                          {/* <th scope="col">Modified Date</th> */}
                          {["company"].includes(decode.role) ? (
                            <th scope="col">Action</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {totalproperties &&
                          totalproperties.map((item, index) => {
                            number++;
                            return (
                              <>
                                <tr key={item._id}>
                                  <td>{number + number1}</td>
                                  {/* <td>{item.primaryID} </td> */}
                                  <td>
                                    <a
                                      href={`/rent_list/${item._id}`}
                                      onClick={() => {
                                        tog_largee2();
                                      }}
                                      data-toggle="modal"
                                      data-target=".bs-example-modal-lg"
                                    >
                                      <strong className="text-muted">
                                        {item.title}
                                      </strong>
                                      <br />
                                      <small>{item.primaryID}</small>
                                    </a>
                                  </td>
                                  {decode2.role !== "company" && (
                                    <td>{item.company}</td>
                                  )}
                                  <td>
                                    {item.layouts
                                      .map((cate) => cate.layoutName)
                                      .join(", ")}
                                  </td>
                                  {decode2.role !== "company" && (
                                    <td>{item.owner}</td>
                                  )}

                                  <td>
                                    {item.managersList.map((data) => {
                                      return `${data.firstname + " " + data.lastname
                                        }, `;
                                    })}
                                  </td>
                                  <td>{item.landlord}</td>
                                  <td>{item.location}</td>
                                  <td>
                                    {item.rent_amount
                                      ? `$${item?.rent_amount}`
                                      : "--"}
                                  </td>
                                  <td className="text-center">
                                    {item?.document.length > 0 &&
                                      item?.document[0] == "1" ? (
                                      <div>
                                        <a
                                          href={`${SERVER_URL}/property/download-document?ID=${item._id}`}
                                          download={`${item.title ? item.title : "property"
                                            }.zip`}
                                        >
                                          <button
                                            type="button"
                                            className="btn @btn-soft-light btn-md w-xs waves-effect btn-label waves-light doc-download-btn "
                                          >
                                            {" "}
                                            <i className="bx bx-download label-icon"></i>
                                          </button>
                                        </a>
                                      </div>
                                    ) : item?.document.length > 0 &&
                                      item?.document[0] !== "1" ? (
                                      <button
                                        onClick={async () => {
                                          item.document.map((file) => {
                                            window.location.href = file;
                                          });
                                        }}
                                        type="button"
                                        className="btn btn-soft-light btn-sm w-xs waves-effect "
                                      >
                                        <i className="bx bx-download label-icon"></i>
                                      </button>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td>
                                    {item.logoUrl ? (
                                      <img
                                        src={
                                          "data:image/jpeg;base64," +
                                          `${item.logoUrl}`
                                        }
                                        alt=""
                                        width={100}
                                      />
                                    ) : (
                                      // <a
                                      //   href={`${SERVER_URL}/property/logo/${item._id}`}
                                      //   target="_blank"
                                      // >
                                      // <button
                                      //   type="button"
                                      //   className="btn @btn-soft-light btn-md w-xs waves-effect btn-label waves-light doc-download-btn "
                                      // >
                                      //     {" "}
                                      //     <i className="bx bx-download label-icon"></i>
                                      //   </button>
                                      // </a>
                                      "NA"
                                    )}
                                  </td>
                                  <td>{item.status}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                    /
                                    <br />
                                    {moment(item.modifiedAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  {/* <td>

                                  </td> */}
                                  {["company"].includes(decode.role) ? (
                                    <td>
                                      <div className="d-flex gap-3">
                                        <i
                                          className="mdi mdi-delete font-size-18 text-danger"
                                          id="deletetooltip"
                                          onClick={async (e) => {
                                            e.preventDefault();
                                            setPropertyID(item._id);
                                            setShowDeleteDialoge(
                                              !showDeleteDialoge
                                            );
                                          }}
                                        ></i>
                                        <Modal
                                          isOpen={showDeleteDialoge}
                                          toggle={() => {
                                            setPropertyID("");
                                            setShowDeleteDialoge(
                                              !showDeleteDialoge
                                            );
                                          }}
                                        >
                                          <ModalHeader
                                            toggle={() => {
                                              setPropertyID("");
                                              setShowDeleteDialoge(
                                                !showDeleteDialoge
                                              );
                                            }}
                                          >
                                            Confirm
                                          </ModalHeader>
                                          <ModalBody>
                                            Are you sure you want to delete
                                            this?
                                          </ModalBody>
                                          <ModalFooter>
                                            <Button
                                              color="secondary"
                                              onClick={() => {
                                                setPropertyID("");
                                                setShowDeleteDialoge(
                                                  !showDeleteDialoge
                                                );
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              color="danger"
                                              onClick={async () =>
                                                await deleteProperty()
                                              }
                                            >
                                              Delete
                                            </Button>
                                          </ModalFooter>
                                        </Modal>
                                        <i
                                          className="mdi mdi-border-color font-size-18"
                                          // id="deletetooltip"
                                          style={{ color: "black" }}
                                          onClick={async () => {
                                            seteditData(true);
                                            setPropertyID(item?._id);
                                            setTitle(item.title);
                                            setLocation(item.location);
                                            setStatus(item.status);
                                            setRent(item.rent_amount);
                                            setAddress(item?.address);
                                            setPostal(item?.postal_code);
                                            setLandload(item?.landlord);
                                            console.log(item?.layouts)
                                            const prop = item?.layouts?.map(
                                              (p) => {
                                                return {
                                                  label: p.layoutName,
                                                  value: p._id,
                                                };
                                              }
                                            );
                                            setCategory(prop);

                                            setmodal_large(!modal_large);

                                            removeBodyCss();
                                            getSuiteDetails(item?._id);
                                            getParkingDetails(item?._id);
                                            getStorageDetails(item?._id);
                                            // await getCompanies();
                                          }}
                                        ></i>
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            setUpdateLogoUrl(item?.logoUrl);
                                            setPropertyID(item?._id);
                                            setLogoIsOpen(true);
                                          }}
                                        >
                                          Upload Logo
                                        </Button>
                                      </div>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              </>
                            );
                          })}
                        {/* <PropertyList totalc={settotalcompanies} pageno={currentPage} filter ={filterData} callApi = {totalproperties} /> */}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="proress-style mt-3">
                    <Col xl={3}></Col>
                    <Col xl={9}>
                      <div className="pagination-bar">
                        <Pagination>
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                              first
                              onClick={() => handlePageChange(1)}
                            />
                          </PaginationItem>
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                              previous
                              onClick={() => handlePageChange(currentPage - 1)}
                            />
                          </PaginationItem>
                          {Array.from(
                            { length: pageLimit },
                            (_, i) => i + startPage
                          ).map((page) => {
                            if (page > pageTotal) return null;
                            return (
                              <PaginationItem
                                key={page}
                                active={currentPage === page}
                              >
                                <PaginationLink
                                  onClick={() => handlePageChange(page)}
                                >
                                  {page}
                                </PaginationLink>
                              </PaginationItem>
                            );
                          })}
                          <PaginationItem disabled={currentPage === pageTotal}>
                            <PaginationLink
                              next
                              onClick={() => handlePageChange(currentPage + 1)}
                            />
                          </PaginationItem>
                          <PaginationItem disabled={currentPage === pageTotal}>
                            <PaginationLink
                              last
                              onClick={() => handlePageChange(pageTotal)}
                            />
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modal_large && (
        <Modal
          size="lg"
          isOpen={modal_large}
          toggle={() => {
            handleCloseModal();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {editData ? "Edit" : "Add"} Property
            </h5>
            <button
              onClick={() => {
                handleCloseModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {editData ? <EditProperty
              propertyId={propertyID}
              onSuccess={() => {
                handleCloseModal();
                getProperties();
              }}
            /> :
              <Form onSubmit={addProperty}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="formrow-title-input">
                        Property Name *
                      </Label>
                      <Input
                        value={title}
                        placeholder="Enter a Property Name"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_title(false);
                            setmsg_error("Property Name is required!");
                          } else {
                            setcheck_title(true);
                          }

                          if (!value.match(/^[a-zA-Z0-9 ]+$/)) {
                            setcheck_title(false);
                            setmsg_error("Only letters and numbers are allowed!");
                          } else {
                            setcheck_title(true);
                          }
                        }}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-name-input"
                      />
                      {check_title ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{msg_error}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="formrow-title-input">
                        Landlord *
                      </Label>
                      <Input
                        value={landload}
                        placeholder="Enter a Landlord"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_landload(false);
                            setmsg_error_landload("Landlord is required!");
                          } else {
                            setcheck_landload(true);
                          }
                        }}
                        onChange={(e) => {
                          setLandload(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-name-input"
                      />
                      {check_landload ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>
                          {msg_error_landload}
                        </Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-category-input"
                      >
                        Layouts *
                      </Label>
                      <Select
                        isMulti
                        value={category}
                        onBlur={() => {
                          if (category.length === 0) {
                            setcheck_layout(false);
                            setmsg_error2("Please select a Layout!");
                          } else {
                            setcheck_layout(true);
                          }
                        }}
                        onChange={(selectedOption) => {
                          setCategory(selectedOption); // Directly store the selected options
                        }}
                        options={layout}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      {check_layout ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{msg_error2}</Label>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                            <div className="mb-3">
                                  <Label className="form-label" htmlFor="formrow-category-input">Category Name</Label>
                                  <Input type="text" className="form-control" id="formrow-category-input" />
                            </div>
                          </div> */}

                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-location-input"
                      >
                        City *
                      </Label>
                      <Input
                        value={location}
                        placeholder="Enter a city name"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_city(false);
                            setmsg_error3("City is required!");
                          } else {
                            setcheck_city(true);
                          }

                          if (!value.match(/^[a-zA-Z0-9 ]+$/)) {
                            setcheck_city(false);
                            setmsg_error3(
                              "Only letters and numbers are allowed!"
                            );
                          } else {
                            setcheck_city(true);
                          }
                        }}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-location-input"
                      />
                      {check_city ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{msg_error3}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label className="form-Label">Status *</Label>
                      <select
                        value={status}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_status(false);
                            setmsg_error4("Please select a Status!");
                          } else {
                            setcheck_status(true);
                          }
                        }}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">In-active </option>
                      </select>
                      {check_status ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{msg_error4}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="formrow-rent-input">
                        Rent ($) *
                      </Label>
                      <Input
                        value={rent}
                        placeholder="Enter a rent"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_rent(false);
                            setrent_error("Rent is required!");
                          } else {
                            setcheck_rent(true);
                          }

                          if (!value.match(/^[0-9 ]+$/)) {
                            setcheck_rent(false);
                            setrent_error("Only numbers are allowed!");
                          } else {
                            setcheck_rent(true);
                          }
                        }}
                        onChange={(e) => {
                          setRent(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-rent-input"
                      />
                      {check_rent ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{rent_error}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="formrow-rent-input">
                        Postal code *
                      </Label>
                      <Input
                        value={postal}
                        placeholder="Enter a rent"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_postal(false);
                            setpostal_error("Postal code is required!");
                          } else {
                            setcheck_postal(true);
                          }
                        }}
                        onChange={(e) => {
                          setPostal(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-rent-input"
                      />
                      {check_postal ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{postal_error}</Label>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-address-input"
                        className="form-label"
                      >
                        Address *
                      </label>
                      <textarea
                        id="basicpill-address-input"
                        className="form-control"
                        rows="3"
                        value={address}
                        placeholder="Enter you address"
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (!value) {
                            setcheck_address(false);
                            setaddress_error("Address is required!");
                          } else {
                            setcheck_address(true);
                          }
                        }}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></textarea>

                      {check_address ? (
                        ""
                      ) : (
                        <Label style={{ color: "red" }}>{address_error}</Label>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <button
                        disabled={suite}
                        type="button"
                        onClick={async () => {
                          setSuite(true);
                        }}
                        className="btn btn-light "
                        data-toggle="modal"
                        data-target=".bs-example-modal-lg"
                      >
                        <i className="bx bx-plus me-1"></i> Add Suite Layout
                      </button>
                    </div>
                  </div>
                  {suite && (
                    <>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Label className="form-Label">Suite Type</Label>
                          <InputGroup>
                            <select
                              value={suiteType}
                              onChange={handleOnSelectSuiteType}
                              className="form-select"
                            >
                              {SUITE_TYPES.map((item, index) => (
                                <option
                                  key={index}
                                  disabled={false}
                                  value={item.value}
                                >
                                  {item.value}
                                </option>
                              ))}
                            </select>
                            <Button color="info" onClick={handleAddSuiteType}>
                              Add
                            </Button>
                          </InputGroup>
                        </Col>
                      </Row>
                      {suiteData.length > 0 &&
                        isSuiteAlphabet.length > 0 &&
                        suiteData.map((item, index) => (
                          <Card key={index}>
                            <CardHeader>
                              <Row>
                                <Col md={4}>
                                  <CardTitle>
                                    <Label className="form-Label">
                                      {" "}
                                      Suite Type :
                                    </Label>{" "}
                                    {item.floorType}
                                  </CardTitle>
                                </Col>
                                <Col md={6}>
                                  <Row>
                                    <Col md={4}>
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-rent-input"
                                      >
                                        Suite Floor :
                                      </Label>
                                    </Col>
                                    <Col md={5}>
                                      <InputGroup>
                                        <Button
                                          color="info"
                                          disabled={
                                            item.floorCount <= FLOOR_LIMIT_FROM
                                              ? true
                                              : false
                                          }
                                          onClick={(event) => {
                                            handleDecreaseSuiteFloor(index);
                                          }}
                                        >
                                          -
                                        </Button>
                                        <Input
                                          readOnly
                                          value={item.floorCount}
                                          placeholder="Enter Parking Floor"
                                          type="text"
                                          className="form-control"
                                          id="formrow-rent-input"
                                        />
                                        <Button
                                          color="info"
                                          disabled={
                                            item.floorCount >= FLOOR_LIMIT_TO
                                              ? true
                                              : false
                                          }
                                          onClick={(event) => {
                                            handleIncreaseSuiteFloor(index);
                                          }}
                                        >
                                          +
                                        </Button>
                                      </InputGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={2} className="text-end">
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      handleRemoveSuiteType(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              {item &&
                                isSuiteAlphabet[index].length > 0 &&
                                item.floors.map((_item, _index) => (
                                  <div
                                    className="col-md-12"
                                    style={{
                                      padding: "10px",
                                      backgroundColor: "#00000017",
                                      borderRadius: "5px",
                                      marginBottom: "30px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div key={_index}>
                                      <div className="d-flex floorNum">
                                        <h6>{_item.floor}</h6>
                                        <FormGroup className="alphabet_style">
                                          <Label className="me-3">
                                            Is Alphabet
                                          </Label>
                                          <Switch
                                            checked={
                                              isSuiteAlphabet[index][_index]
                                            }
                                            onChange={(value) => {
                                              if (isSuiteAlphabet.length > 0) {
                                                let _isAlphabet =
                                                  isSuiteAlphabet.slice();
                                                _isAlphabet[index][_index] =
                                                  value;
                                                setIsSuiteAlphabet(_isAlphabet);
                                              }
                                            }}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={15}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={15}
                                            width={35}
                                            className="react-switch"
                                          />
                                        </FormGroup>

                                        <span>=</span>
                                        {isSuiteAlphabet.length > 0 &&
                                          isSuiteAlphabet[index][_index] && (
                                            <Input
                                              name="alphabet"
                                              placeholder="Select Aphabat"
                                              type="select"
                                              className="arrow-select"
                                              invalid={
                                                _item.alphabet ? false : true
                                              }
                                              valid={
                                                _item.alphabet ? true : false
                                              }
                                              value={_item.alphabet}
                                              onChange={(e) => {
                                                handleOnSuiteChangeOnFloor(
                                                  e,
                                                  index,
                                                  _index
                                                );
                                              }}
                                            >
                                              <option value={""}>Select</option>
                                              {ALPHABET.map((item, index) => (
                                                <option key={index} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </Input>
                                          )}

                                        <Input
                                          name="from"
                                          value={_item.from}
                                          placeholder="From"
                                          onChange={(e) => {
                                            handleOnSuiteChangeOnFloor(
                                              e,
                                              index,
                                              _index
                                            );
                                          }}
                                          type="number"
                                          className="form-control floorFrom cmn_styleselct"
                                          id="formrow-rent-input"
                                          invalid={_item.from ? false : true}
                                          valid={_item.from ? true : false}
                                        />
                                        <Input
                                          name="to"
                                          value={_item.to}
                                          placeholder="To"
                                          onChange={(e) => {
                                            handleOnSuiteChangeOnFloor(
                                              e,
                                              index,
                                              _index
                                            );
                                          }}
                                          type="number"
                                          className="form-control floorFrom cmn_styleselct"
                                          id="formrow-rent-input"
                                          invalid={_item.to ? false : true}
                                          valid={_item.to ? true : false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </CardBody>
                          </Card>
                        ))}
                    </>
                  )}

                  <>
                    {/* 
<div className="col-md-12">
    <div className="mb-3">
        <button
            disabled={parking}
            type="button"
            onClick={async () => {
                setParking(true);
            }}
            className="btn btn-light"
            data-toggle="modal"
            data-target=".bs-example-modal-lg"
        >
            <i className="bx bx-plus me-1"></i> Add Parking
        </button>
    </div>
</div>
{parking && (
    <>
        <Row className="mb-3">
            <Col md={6}>
                <Label className="form-Label"> Parking Type</Label>
                <InputGroup>
                    <select
                        value={parkingType}
                        onChange={handleOnSelectParkingType}
                        className="form-select"
                    >
                        {PARKING_TYPES.map((item, index) => (
                            <option key={index} disabled={false} value={item.value}>
                                {item.value}
                            </option>
                        ))}
                    </select>
                    <Button color="info" onClick={handleAddParkingType}>
                        Add
                    </Button>
                </InputGroup>
            </Col>
        </Row>
        {parkingData.length > 0 &&
            isAlphabet.length > 0 &&
            parkingData.map((item, index) => (
                <Card key={index}>
                    <CardHeader>
                        <Row>
                            <Col md={4}>
                                <CardTitle>
                                    <Label className="form-Label"> Parking Type :</Label>{" "}
                                    {item.floorType}
                                </CardTitle>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col md={4}>
                                        <Label className="form-label" htmlFor="formrow-rent-input">
                                            Parking Floor :
                                        </Label>
                                    </Col>
                                    <Col md={5}>
                                        <InputGroup>
                                            <Button
                                                color="info"
                                                disabled={item.floorCount <= FLOOR_LIMIT_FROM}
                                                onClick={(event) => {
                                                    handleDecreaseFloor(index);
                                                }}
                                            >
                                                -
                                            </Button>
                                            <Input
                                                readOnly
                                                value={item.floorCount}
                                                placeholder="Enter Parking Floor"
                                                type="text"
                                                className="form-control"
                                                id="formrow-rent-input"
                                            />
                                            <Button
                                                color="info"
                                                disabled={item.floorCount >= FLOOR_LIMIT_TO}
                                                onClick={(event) => {
                                                    handleIncreaseFloor(index);
                                                }}
                                            >
                                                +
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2} className="text-end">
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        handleRemoveParkingType(index);
                                    }}
                                >
                                    Remove
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {item &&
                            isAlphabet[index].length > 0 &&
                            item.floors.map((_item, _index) => (
                                <div
                                    className="col-md-12"
                                    style={{
                                        padding: "10px",
                                        backgroundColor: "#00000017",
                                        borderRadius: "5px",
                                        marginBottom: "30px",
                                        marginTop: "15px",
                                    }}
                                >
                                    <div key={_index}>
                                        <div className="d-flex floorNum">
                                            <h6>{_item.floor}</h6>
                                            <FormGroup className="alphabet_style">
                                                <Label className="me-3">Is Alphabet</Label>
                                                <Switch
                                                    checked={isAlphabet[index][_index]}
                                                    onChange={(value) => {
                                                        if (isAlphabet.length > 0) {
                                                            let _isAlphabet = isAlphabet.slice();
                                                            _isAlphabet[index][_index] = value;
                                                            setIsAlphabet(_isAlphabet);
                                                        }
                                                    }}
                                                    onColor="#86d3ff"
                                                    onHandleColor="#2693e6"
                                                    handleDiameter={15}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={15}
                                                    width={35}
                                                    className="react-switch"
                                                />
                                            </FormGroup>

                                            <span>=</span>
                                            {isAlphabet.length > 0 &&
                                                isAlphabet[index][_index] && (
                                                    <Input
                                                        name="alphabet"
                                                        placeholder="Select Alphabet"
                                                        type="select"
                                                        invalid={!_item.alphabet}
                                                        valid={!!_item.alphabet}
                                                        value={_item.alphabet}
                                                        onChange={(e) => {
                                                            handleOnChnageOnFloor(e, index, _index);
                                                        }}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {ALPHABET.map((item, index) => (
                                                            <option key={index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                )}

                                            <Input
                                                name="from"
                                                value={_item.from}
                                                placeholder="From"
                                                onChange={(e) => {
                                                    handleOnChnageOnFloor(e, index, _index);
                                                }}
                                                type="number"
                                                className="form-control floorFrom cmn_styleselct"
                                                id="formrow-rent-input"
                                                invalid={!_item.from}
                                                valid={!!_item.from}
                                            />
                                            <Input
                                                name="to"
                                                value={_item.to}
                                                placeholder="To"
                                                onChange={(e) => {
                                                    handleOnChnageOnFloor(e, index, _index);
                                                }}
                                                type="number"
                                                className="form-control floorFrom cmn_styleselct"
                                                id="formrow-rent-input"
                                                invalid={!_item.to}
                                                valid={!!_item.to}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </CardBody>
                </Card>
            ))}
    </>
)} 
*/}

                    {/* <div className="col-md-12">
  <div className="mb-3">
    <button
      disabled={storage}
      type="button"
      onClick={async () => {
        setStorage(true);
      }}
      className="btn btn-light "
      data-toggle="modal"
      data-target=".bs-example-modal-lg"
    >
      <i className="bx bx-plus me-1"></i> Add Storage
    </button>
  </div>
</div>
{storage && (
  <>
    <Row className="mb-3">
      <Col md={6}>
        <Label className="form-Label"> Storage Type</Label>
        <InputGroup>
          <select
            value={storageType}
            onChange={handleOnSelectStorageType}
            className="form-select"
          >
            {STORAGE_TYPES.map((item, index) => (
              <option key={index} disabled={false} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
          <Button color="info" onClick={handleAddStorageType}>
            Add
          </Button>
        </InputGroup>
      </Col>
    </Row>
    {storageData.length > 0 &&
      isStorageAlphabet.length > 0 &&
      storageData.map((item, index) => (
        <Card key={index}>
          <CardHeader>
            <Row>
              <Col md={4}>
                <CardTitle>
                  <Label className="form-Label"> Storage Type :</Label>{" "}
                  {item.floorType}
                </CardTitle>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <Label className="form-label" htmlFor="formrow-rent-input">
                      Storage Floor :
                    </Label>
                  </Col>
                  <Col md={5}>
                    <InputGroup>
                      <Button
                        color="info"
                        disabled={
                          item.floorCount <= FLOOR_LIMIT_FROM ? true : false
                        }
                        onClick={(event) => {
                          handleDecreaseStorageFloor(index);
                        }}
                      >
                        -
                      </Button>
                      <Input
                        readOnly
                        value={item.floorCount}
                        placeholder="Enter Parking Floor"
                        type="text"
                        className="form-control"
                        id="formrow-rent-input"
                      />
                      <Button
                        color="info"
                        disabled={
                          item.floorCount >= FLOOR_LIMIT_TO ? true : false
                        }
                        onClick={(event) => {
                          handleIncreaseStorageFloor(index);
                        }}
                      >
                        +
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="text-end">
                <Button
                  color="danger"
                  onClick={() => {
                    handleRemoveStorageType(index);
                  }}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {item &&
              isStorageAlphabet[index].length > 0 &&
              item.floors.map((_item, _index) => (
                <div
                  className="col-md-12"
                  style={{
                    padding: "10px",
                    backgroundColor: "#00000017",
                    borderRadius: "5px",
                    marginBottom: "30px",
                    marginTop: "15px",
                  }}
                >
                  <div key={_index}>
                    <div className="d-flex floorNum">
                      <h6>{_item.floor}</h6>
                      <FormGroup className="alphabet_style">
                        <Label className="me-3"> Is Alphabet </Label>
                        <Switch
                          checked={isStorageAlphabet[index][_index]}
                          onChange={(value) => {
                            if (isStorageAlphabet.length > 0) {
                              let _isAlphabet = isStorageAlphabet.slice();
                              _isAlphabet[index][_index] = value;
                              setIsStorageAlphabet(_isAlphabet);
                            }
                          }}
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={15}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={15}
                          width={35}
                          className="react-switch"
                        />
                      </FormGroup>

                      <span>=</span>
                      {isStorageAlphabet.length > 0 &&
                        isStorageAlphabet[index][_index] && (
                          <Input
                            name="alphabet"
                            placeholder="Select Aphabat"
                            type="select"
                            invalid={_item.alphabet ? false : true}
                            valid={_item.alphabet ? true : false}
                            value={_item.alphabet}
                            onChange={(e) => {
                              handleOnStorageChangeOnFloor(e, index, _index);
                            }}
                          >
                            <option value={""}>Select</option>
                            {ALPHABET.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        )}

                      <Input
                        name="from"
                        value={_item.from}
                        placeholder="From"
                        onChange={(e) => {
                          handleOnStorageChangeOnFloor(e, index, _index);
                        }}
                        type="number"
                        className="form-control floorFrom cmn_styleselct"
                        id="formrow-rent-input"
                        invalid={_item.from ? false : true}
                        valid={_item.from ? true : false}
                      />
                      <Input
                        name="to"
                        value={_item.to}
                        placeholder="To"
                        onChange={(e) => {
                          handleOnStorageChangeOnFloor(e, index, _index);
                        }}
                        type="number"
                        className="form-control floorFrom cmn_styleselct"
                        id="formrow-rent-input"
                        invalid={_item.to ? false : true}
                        valid={_item.to ? true : false}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </CardBody>
        </Card>
      ))}
  </>
)} */}

                  </>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-firstname-input"
                      >
                        Documents *
                      </Label>
                      <Dropzone
                        onDrop={async (acceptedFiles) => {
                          // working
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="trash-btn">
                                    <button
                                      type="button"
                                      className="btn btn-soft-danger waves-effect waves-light"
                                      href="javascript:void(0)"
                                      onClick={() => deleteDocument(i)}
                                    >
                                      <i className="bx bx-trash-alt"></i>
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                      {/* {check_document ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>{msg_error1}</Label>
                    )} */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-end">
                      <button
                        type="submit"
                        disabled={[
                          check_title,
                          check_rent,
                          check_layout,
                          check_city,
                          check_status,
                          check_postal,
                        ].includes(false)}
                        className="btn btn-success save-user"
                      >
                        Save
                        <i id="nexticon" className="bx bx-chevron-right ms-1"></i>
                        <div
                          id="saveSpinner"
                          style={{
                            display: "none",
                            height: "15px",
                            width: "15px",
                            marginLeft: "5px",
                          }}
                          className="spinner-border"
                          role="status"
                        ></div>
                        <i
                          id="failedSaveErrorIcon"
                          style={{
                            display: "none",
                            marginLeft: "5px",
                          }}
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>}
          </div>
        </Modal>
      )}
      {logoIsOpen && (
        <Modal
          isOpen={logoIsOpen}
          toggle={() => {
            setLogoIsOpen(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setLogoIsOpen(false);
            }}
          >
            Upload Logo
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <img
                  src={
                    previewLogoUrl
                      ? previewLogoUrl
                      : updateLogoUrl
                        ? "data:image/jpeg;base64," + updateLogoUrl
                        : UploadLogo
                  }
                  alt="Logo"
                  style={{ maxWidth: "200px" }}
                ></img>
              </Col>
              <hr />
              <Col md={12}>
                <Input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={selectedLogo ? false : true}
              color="primary"
              onClick={handleUploadLogo}
            >
              Upload
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setLogoIsOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default AddProperty;
