import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, Row, Col, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import { AddTenantForm } from "./TenantForm/AddTenantForm";
import { getPropertyList } from "./TenantForm/Helpers/api";

const PropertySelector = ({
  isFilterOpen,
  setIsFilterOpen,
  action,
  userData,
  filterData,
  setFilterData
}) => {
  const [isAddTenantFormOpen, setIsAddTenantFormOpen] = useState(false)

  useEffect(() => {
    if (userData?.id) {
      getPropertyList(userData).then((filteredData) => {
        if (filteredData) {
          setFilterData((prev) => ({ ...prev, ...filteredData }));
        }
      });
    }
  }, [userData?.id]);

  const formatProperty = (item) => ({
    id: item._id,
    value: item.title,
    label: `${item.title}, ${item.location}`,
    building: item.building || [],
  });
  const formatProperties = () => (filterData.propertyList || []).map(formatProperty);
  const formatBuildings = () => filterData?.building_list?.map((b) => ({ value: b.building_name, label: b.building_name })) || [];

  const handlePropertySelectionChange = (property) => {
    setFilterData((prev) => ({
      ...prev,
      propertyId: property?.id || "",
      property_name: property?.value || "",
      building_list: property?.building || [],
      building_name: ""
    }));
  };

  const handleBuildingSelectionChange = (building) => {
    setFilterData((prev) => ({
      ...prev,
      building_name: building?.value || ""
    }));
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <Row>
            <Col md={5}>
              <Label>Property Name</Label>
              <Select
                value={filterData.propertyId ? { value: filterData.property_name, label: filterData.property_name } : null}
                options={formatProperties()}
                placeholder="Search By Name"
                onChange={handlePropertySelectionChange}
                className="property-select"
              />
            </Col>
            {filterData.building_list?.length > 0 && (
              <Col md={4}>
                <FormGroup>
                  <Label>Building Name</Label>
                  <Select
                    value={filterData.building_name ? { value: filterData.building_name, label: filterData.building_name } : null}
                    options={formatBuildings()}
                    placeholder="Search By Building Name"
                    onChange={handleBuildingSelectionChange}
                    className="building-select"
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={3} className="d-flex align-items-end">
              <Button color={
                !filterData.propertyId ||
                  (filterData.building_list.length > 0 && !filterData.building_name)
                  ? "secondary"
                  : "primary"
              } onClick={() => setIsFilterOpen(!isFilterOpen)}
                disabled={
                  !filterData.propertyId ||
                  (filterData.building_list.length > 0 && !filterData.building_name)
                }
              >
                {isFilterOpen ? "Collapse" : "Filters"}
              </Button>
              <Button
                color={
                  !filterData.propertyId ||
                    (filterData.building_list.length > 0 && !filterData.building_name)
                    ? "secondary"
                    : "primary"
                }
                className="ms-2"
                onClick={action}
                disabled={
                  !filterData.propertyId ||
                  (filterData.building_list.length > 0 && !filterData.building_name)
                }
              >
                Import/Export
              </Button>
              <Button
                color={
                  !filterData.propertyId ||
                    (filterData.building_list.length > 0 && !filterData.building_name)
                    ? "secondary"
                    : "primary"
                }
                className="ms-2"
                onClick={() => setIsAddTenantFormOpen(true)}
                disabled={
                  !filterData.propertyId ||
                  (filterData.building_list.length > 0 && !filterData.building_name)
                }
              >
                +
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <AddTenantForm show={isAddTenantFormOpen} handleClose={() => setIsAddTenantFormOpen(false)} />
    </Card>
  );
};

export { PropertySelector };
