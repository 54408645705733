const residentApplicationInitialValues = {
    main: {
        city: '',
        property: '',
        propertyID: '',
        date: new Date().toISOString().split('T')[0],
        layout: '',
        layoutID: '',
        source: '',
    },
    applicants: [{
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        currentAddress: '',
        howLong: '',
        currentLandlord: '',
        currentLandlordPhone: '',
        previousAddressInformation: {
            previousAddress: '',
            howLongAtPreviousAddress: '',
            previousLandlordName: '',
            previousLandlordPhone: '',
        },
        currentEmployer: '',
        currentEmployerFor: '',
        occupation: '',
        annualIncome: '',
        manager: '',
        currentEmployerPhone: '',
        documents: [],
    }],
    showPets: true,
    petsInformation: [{
        pets: '',
        breed: '',
        weight: '',
        age: '',
    }],
    residePersons: [{
        firstname: '',
        lastname: '',
        relation: '',
        age: '',
    }],
    emergencyContacts: [
        {
            firstname: '',
            lastname: '',
            relation: '',
            phone: '',
        },
        {
            firstname: '',
            lastname: '',
            relation: '',
            phone: '',
        },
    ],
    signatures: [],
    agreement:'',
    companyDomain: '',
};

export { residentApplicationInitialValues }