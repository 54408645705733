import { getIn } from "formik";

const chunkArray = (arr, size) => {
    const chunked = [];
    for (let i = 0; i < arr.length; i += size) {
        chunked.push(arr.slice(i, i + size));
    }
    return chunked;
};

const getCompanyDomain = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("name");
};
const getRequiredFields = (schema) => {
    const requiredFields = new Set();

    const traverseSchema = (schema, path = "") => {
        if (schema.fields) {
            Object.keys(schema.fields).forEach((key) => {
                const fieldPath = path ? `${path}.${key}` : key;
                const field = schema.fields[key];

                if (field.type === "object") {
                    traverseSchema(field, fieldPath);
                } else if (field.type === "array" && field.innerType) {
                    const arrayPath = fieldPath + "[]";
                    if (field.innerType.type === "object" || field.innerType.type === "mixed") {
                        traverseSchema(field.innerType, arrayPath);
                    } else {
                        traverseSchema(field.innerType, arrayPath);
                    }
                }

                const isRequired = (
                    // Check regular tests array
                    (Array.isArray(field.tests) &&
                        field.tests.some((test) =>
                            ["required", "min", "optionality"].includes(test.OPTIONS?.name)
                        )) ||
                    // Check exclusive tests
                    field.exclusiveTests?.required ||
                    // Check internal tests for date and boolean fields
                    ((field.type === "date" || field.type === "boolean") &&
                        field.internalTests &&
                        (field.internalTests.optionality ||
                         field.internalTests.nullable))
                );

                if (isRequired) {
                    const cleanPath = fieldPath.replace(/\.+/g, '.');
                    requiredFields.add(cleanPath);
                }
            });
        }
    };

    traverseSchema(schema);
    return requiredFields;
};

const countFilledRequiredFields = (values, requiredFields) => {
    let filled = 0;
    let total = requiredFields.size;

    const isValueFilled = (value, fieldPath) => {
        // Handle array fields
        if (fieldPath.includes("[]")) {
            // If it's an array field, check if array exists and has items
            const arrayValue = Array.isArray(value) ? value : [];
            if (arrayValue.length === 0) return false;

            // For nested array fields (e.g., "applicants[].firstname")
            const [arrayPath, fieldName] = fieldPath.split("[].");
            if (fieldName) {
                // Check if all array items have the required field filled
                return arrayValue.every(item => {
                    const itemValue = getIn(item, fieldName);
                    return isValueFilled(itemValue, fieldName);
                });
            }
            return true;
        }

        // Handle boolean fields explicitly
        if (typeof value === "boolean") {
            return value === true;
        }

        // Handle other types
        if (Array.isArray(value)) {
            return value.length > 0;
        }

        if (typeof value === "object" && value !== null) {
            return Object.keys(value).length > 0;
        }

        if (typeof value === "number") {
            return true;
        }

        return value !== "" && value !== null && value !== undefined;
    };

    requiredFields.forEach((field) => {
        const value = getIn(values, field);
        if (isValueFilled(value, field)) {
            filled++;
        }
    });

    return { filled, total };
};

export { chunkArray, getCompanyDomain, getRequiredFields, countFilledRequiredFields };
